import { TransactionEntity } from 'Models/Entities';
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import {
	discardAlterations,
	resetReservationTimer,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	clearEventBookingTransactionIdFromStorage,
	getEventBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { alertModal } from 'Views/Components/Modal/ModalUtils';
import classnames from 'classnames';
import alertToast from 'Util/ToastifyUtils';
import { fetchTransactionById } from 'Util/_HumanWritten/FetchTransactionById';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import {
	onReceiveResetReservationEvent,
	removeResetReservationListener,
} from 'Events/HumanWritten/ResetReservationEvent';
import {
	clearAllEventBookingWizardDataFromStorage,
	clearAllFerryBookingWizardDataFromStorage,
} from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { clearStorageTransactionPrice } from 'Util/_HumanWritten/CartPriceSessionStorage';
import { store } from 'Models/Store';
import moment from 'moment';
import { getOldFerryBookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	getOldEventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { EventBookingTimer } from 'Views/Components/_HumanWritten/EventsBookingWizard/Components/EventBookingTimer';

export interface EventBookingTimerWrapProps {
	pageWithSidebar: boolean;
	onTimerExpiry: () => void;
	isIpad: boolean;
	ticketsPageUrl: string;
	forEventBooking: boolean;
}

function getSecondsUntilExpiry(expirationTime: Date | null): number | null {
	if (!isNotNullOrUndefined(expirationTime)) {
		return null;
	}

	const millisecondsToExpiry = expirationTime.getTime() - Date.now();

	if (millisecondsToExpiry > 0) {
		return Math.round(millisecondsToExpiry / 1000);
	}

	return 0;
}

export function EventBookingTimerWrap({
	pageWithSidebar,
	onTimerExpiry,
	isIpad,
	ticketsPageUrl,
	forEventBooking,
}: EventBookingTimerWrapProps) {
	const history = useHistory();
	const [transaction, setTransaction] = useState<TransactionEntity | null>(null);
	const [secondsUntilExpiry, setSecondsUntilExpiry] = useState<number | null>(null);
	const [timerExpiryHandled, setTimerExpiryHandled] = useState(false);
	const [clientsideExpiryTime, setClientsideExpiryTime] = useState<Date | null>(null);
	const [resetting, setResetting] = useState(false);

	const transactionId = forEventBooking
		? getEventBookingTransactionIdFromStorage()
		: getEventBookingTransactionIdFromStorage();

	useEffect(() => {
		if (transactionId !== null && transaction === null) {
			fetchTransactionById(transactionId).then(fetchedTransaction => {
				const theTransaction = fetchedTransaction.data[0];
				setTransaction(theTransaction);
				// Set the date of expiry by using the clientside time and adding the seconds until
				// expiry, which is fetched from the serverside. This will make sure that if a user has
				// the wrong time on their computer, that the timer will still give them the 15mins
				// that the booking has on it until it expires.
				// If we don't do it this way and the user has the wrong time for the timezone they are in
				// then they will receive more or less time on the frontend than what the server actually
				// has given the user. Resulting in the timer expiring earlier/later than the server expects it to.
				const newDate = new Date();
				const expirationTime = moment(newDate)
					.add(theTransaction.secondsUntilExpiry, 'seconds')
					.toDate();
				setClientsideExpiryTime(expirationTime);
				setSecondsUntilExpiry(getSecondsUntilExpiry(expirationTime));
			}).catch(_ => {
				setSecondsUntilExpiry(null);
			});
		}
	}, [transactionId]);

	// We only want to set up the interval one time.  Otherwise we will have multiple intervals reducing the time
	// which will cause the time to decrease more quickly. Use effect with no dependencies will run when the component
	// renders for the first time and never again
	useEffect(() => {
		/*
			Whenever the timer is reset, an event will be sent out. This snippet here will listen for that event and
			will run the code to reset the timer in the view whenever it is found
		 */
		const listenerFunc = () => {
			// There were two options here. Either we hard code this so that when the timer is reset
			// the time is reset to 15 minutes, or we do the same thing we did before and fetch the
			// entity to set the seconds until expiry now that the timer has been reset. I decided
			// do do it this way because it ensures that the clientside doesn't need to know how
			// long the timer is. If I hard coded the timer to 900 (60 * 15), then that would need
			// to be changed if the timer is changed in future. With the way this has been
			// implemented, it doesn't need to be changed again if we change the length of the
			// booking timer
			const idToFetch = forEventBooking
				? getEventBookingTransactionIdFromStorage()
				: getEventBookingTransactionIdFromStorage();
			if (idToFetch !== null && idToFetch !== '') {
				fetchTransactionById(idToFetch).then(fetchedTransaction => {
					// Set the date of expiry by using the clientside time and adding the seconds until
					// expiry, which is fetched from the serverside
					const clientsideExpiryTimeTime = moment(new Date())
						.add(fetchedTransaction.data[0].secondsUntilExpiry, 'seconds')
						.toDate();
					setClientsideExpiryTime(clientsideExpiryTimeTime);
					setTransaction(fetchedTransaction.data[0]);
					setSecondsUntilExpiry(getSecondsUntilExpiry(clientsideExpiryTimeTime));
				}).catch(_ => {
					setSecondsUntilExpiry(null);
				});
			}
		};
		onReceiveResetReservationEvent(listenerFunc);

		// This is not strictly required, but it makes things better during dev. Whenever we do a hot reload the
		// interval will run again, which will cause more than one interval to be reducing the remaining time. This
		// statement clears the interval when the effect dies (which will happen when the component unrenders), so we
		// don't get more than one interval at any given time
		return () => {
			// clearInterval(intervalId);
			removeResetReservationListener(listenerFunc);
		};
	}, []);

	// This useEffect is dependant on `clientsideExpiryTime` being set first and not being null
	useEffect(() => {
		const intervalId = setInterval(() => {
			if (clientsideExpiryTime === null) {
				return;
			}

			if (secondsUntilExpiry === 0) {
				clearInterval(intervalId);
			}

			setSecondsUntilExpiry(prev => {
				if (prev === null) {
					return null;
				}

				return getSecondsUntilExpiry(clientsideExpiryTime);
			});
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, [clientsideExpiryTime]);

	useEffect(() => {
		if (secondsUntilExpiry === 0 && !timerExpiryHandled) {
			let isAlteration = false;
			let bookingId = '';

			if (!forEventBooking) {
				const oldWizardData = getOldFerryBookingWizardData();
				isAlteration = isNotNullOrUndefined(oldWizardData) && oldWizardData.wizardMode === 'ALTERATION';

				bookingId = oldWizardData?.bookingToEdit ?? '';
				if (isAlteration) {
					store.setCartPriceDifference(0);
					store.setCartPriceWithoutSurcharge(0);
					store.setCartPriceWithSurcharge(0);
					store.clearBookingPrices();
					clearAllFerryBookingWizardDataFromStorage();
				} else {
					clearEventBookingTransactionIdFromStorage();
				}
			} else {
				const oldWizardData = getOldEventBookingWizardData();

				bookingId = oldWizardData?.bookingToEditId ?? '';
				if (isAlteration) {
					store.setCartPriceDifference(0);
					store.setCartPriceWithoutSurcharge(0);
					store.setCartPriceWithSurcharge(0);
					store.clearBookingPrices();
					clearAllEventBookingWizardDataFromStorage();
				} else {
					clearEventBookingTransactionIdFromStorage();
				}
			}

			clearStorageTransactionPrice();
			onTimerExpiry();
			setTimerExpiryHandled(true);

			alertModal(
				'Session has expired',
				'You have run out of time and you will need to start a new session',
				{
					cancelText: `Return to ${isAlteration ? 'booking' : 'ticket selection'}`,
					shouldCloseOnOverlayClick: false,
					shouldCloseOnEsc: false,
				},
			).then(() => {
				// If we are on the tickets page, navigating there will do nothing. Therefore, we need to refresh the
				// page in that scenario
				if (!isAlteration) {
					if (history.location.pathname === ticketsPageUrl) {
						history.go(0);
					} else {
						history.push(ticketsPageUrl);
					}
				} else {
					discardAlterations({ bookingIds: [bookingId], systemExpired: true }).then(() => {
						history.push(`/bookings/${bookingId}`);
					}).catch(() => {
						history.push(`/bookings/${bookingId}`);
					});
				}
			});
		}
	}, [secondsUntilExpiry, ticketsPageUrl, onTimerExpiry, history]);

	if (secondsUntilExpiry === null) {
		return null;
	}

	const expiringSoon = secondsUntilExpiry <= 300;
	const classNames = ['booking-timer__wrap'];

	classNames.push(isIpad ? 'booking-timer__mobile-wrap' : 'booking-timer__web-wrap');
	classNames.push(pageWithSidebar ? 'booking-timer__with-sidebar' : 'booking-timer__without-sidebar');

	if (expiringSoon) {
		classNames.push('booking-timer__wrap-expiring-soon');
	}

	return (
		<div className={classnames(classNames)}>
			<EventBookingTimer
				pageWithSidebar={pageWithSidebar}
				isMobile={isIpad}
				secondsUntilExpiry={secondsUntilExpiry}
				expiringSoon={expiringSoon}
				onResetTimer={event => {
					event.stopPropagation();
					event.preventDefault();
					if (transactionId !== null) {
						setResetting(true);
						resetReservationTimer(transactionId).catch(() => {
							alertToast('Could not extend booking timer', 'error');
						}).finally(() => setResetting(false));
					}
				}}
				resetting={resetting}
			/>
		</div>
	);
}

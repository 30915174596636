import * as React from 'react';

import useStore from 'Hooks/useStore';

interface SecureRenderProps {
	children: React.ReactNode;
	groups?: string[];
}

/**
 * Same logic as SecuredPage.
 */
export default function SecuredRender({ children, groups }: SecureRenderProps) {
	const context = useStore();

	if (groups) {
		if (!groups.length) {
			return <></>;
		}

		// eslint-disable-next-line react/destructuring-assignment
		if (groups.some(r => context.userGroups.map(ug => ug.name).includes(r))) {
			return <>{children}</>;
		}

		return <></>;
	}

	return <>{children}</>;
}

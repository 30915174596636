import { create } from 'zustand';
import jsQR from 'jsqr';

import { CheckInBookingOverviewDto } from '../../FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { readLicensePlate } from '../Services/licensePlateService';
import { ScannerActionEvent, ScannerActionMap } from '../Types/scannerAction';
import { ScannerMode, ScannerModeMap } from '../Types/scannerMode';
import { checkInBookingOverviewDtoToScannerResultData } from '../Utils/checkInBookingOverviewDtoToScannerResultState';
import { convertCanvasToBase64 } from '../Utils/convertCanvasToBase64';
import { convertCanvasToDataUrl } from '../Utils/convertCanvasToDataUrl';
import { searchCargoId } from '../Utils/searchCargoId';

interface UseScannerProps {
	bookings: CheckInBookingOverviewDto[];
	mode: ScannerMode;
	image: string | undefined;
	attempts: number;
	detectedText: string[];
	dispatch(event: ScannerActionEvent): void;
	// Testing purposes (only noticeable by super users)
	// - used for collecting sample images for scanning
	devMode: boolean;
}

export const useScanner = create<UseScannerProps>((set, get) => ({
	mode: ScannerModeMap.QrCode,
	image: undefined,
	bookings: [],
	devMode: false,
	attempts: 0,
	detectedText: [],
	dispatch(event) {
		switch (event.action) {
			case ScannerActionMap.StartScanner: {
				const { bookings } = event;
				set({ bookings });
				break;
			}
			case ScannerActionMap.ToggleMode: {
				set(prevState => ({
					mode: prevState.mode === ScannerModeMap.QrCode ? ScannerModeMap.Rego : ScannerModeMap.QrCode,
					imageBase64: undefined,
					attempts: 0,
				}));
				break;
			}
			case ScannerActionMap.ScanQrCode: {
				const { canvas, onSuccess, onError } = event;
				const ctx = canvas.getContext('2d');
				if (!ctx) {
					throw new Error('Missing canvas');
				}

				const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
				const code = jsQR(imageData.data, imageData.width, imageData.height);

				if (code) {
					onSuccess?.(code.data);
				} else {
					onError?.();
				}
				break;
			}
			case ScannerActionMap.ScanCargoId: {
				const {
					canvas,
					onSuccess,
					onError,
					abortSignal,
				} = event;
				const image = convertCanvasToDataUrl(canvas);
				const imageBase64 = convertCanvasToBase64(canvas);

				if (get().devMode) {
					const link = document.createElement('a');
					link.href = image;
					link.download = 'rego-scan.png';
					link.click();
					return;
				}

				set(({ attempts }) => ({ image, attempts: attempts + 1 }));

				// Use below for testing purposes
				// mockPromiseWithDelay(['ABC123'], 3000, abortSignal)

				readLicensePlate(imageBase64, abortSignal)
					.then(detectedText => {
						set({ detectedText });
						const formattedBookings = get().bookings.map(checkInBookingOverviewDtoToScannerResultData);
						const data = searchCargoId(detectedText, formattedBookings);
						if (data) {
							onSuccess?.({ data });
							set({ attempts: 0 });
						}
					})
					.catch(() => {
						onError?.();
					})
					.finally(() => {
						set({ image: undefined });
					});
				break;
			}
			case ScannerActionMap.ResetImage: {
				set({ image: undefined });
				break;
			}
			case ScannerActionMap.CleanUp: {
				set({ image: undefined, detectedText: [], attempts: 0 });
				break;
			}
			case ScannerActionMap.ToggleDevMode: {
				set(prevState => ({ devMode: !prevState.devMode }));
				break;
			}
			default:
				throw new Error('Unhandled scanner event');
		}
	},
}));

import { getMinimumMeasurementTypeForFerryTrip } from '../../MeasurementUtils';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';
import { FerryTripEntity } from 'Models/Entities';
import { TicketsTabTrip } from '../../../../Services/Api/_HumanWritten/BookingWizardDataService';
import { getLengthTypePriceFromFerryTrip, getWeightTypePriceFromFerryTrip } from '../../VehicleTrailerTicketTypeUtils';
import passengerTypeStore from '../../../../Models/PassengerTypeStore';
import { whiteLabelStore } from '../../../../Models/WhiteLabelStore';

/**
 * This function will calculate the base ticket price for a new booking.
 * @param priceCalculationProps: The price calculation props needed for calculating the base price.
 * @param trip: The ferry trip to find the base ticket price from.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * Note: Base ticket price is the total passengers cost (minus one adult if vehicle booking)
 * plus the minimum vehicle length type price.
 */
export const calculateBasePriceNewBooking = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity | TicketsTabTrip,
): number => {
	if (trip === undefined) {
		return 0.0;
	}
	const vehicle = priceCalculationProps.tabSelected === 'vehicle';
	const minimumLength = getMinimumMeasurementTypeForFerryTrip(trip);
	const minimumWeight = getMinimumMeasurementTypeForFerryTrip(trip, 'WEIGHT');

	let total = 0;
	let lengthPrice = 0;
	let weightPrice = 0;

	if (vehicle) {
		lengthPrice = getLengthTypePriceFromFerryTrip(trip as FerryTripEntity, minimumLength?.id ?? '');
		weightPrice = getWeightTypePriceFromFerryTrip(trip as FerryTripEntity, minimumWeight?.id);
	}
	total += lengthPrice + weightPrice;

	// Calculate passenger prices based on active passenger types
	for (const p of passengerTypeStore.passengerTypes) {
		// PriceCalculationProps has the same price property names as BookingWizardData
		const propertyName = passengerTypeStore.getWizardDataProperty(p.passengerTypeKey);

		const unitPrice = passengerTypeStore.getDynamicPassengerPrice(trip, p.passengerTypeKey);
		let count = priceCalculationProps[propertyName] as number | undefined ?? 0;
		if (p.passengerTypeKey === 'A' && vehicle) {
			count -= whiteLabelStore.minAdultsForVehicle;
		}

		const cost = unitPrice * count;
		total += cost;
	}

	return total;
};

import * as React from 'react';
import { BookingEntity } from 'Models/Entities';
import Collection from 'Views/Components/Collection/Collection';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import Pagination from 'Views/Components/Pagination/Pagination';
import If from 'Views/Components/If/If';
import { CheckInMoveButton } from '../CheckInButtons/CheckInMoveButton';
import {
	BOOKINGS_PER_PAGE,
	CheckInSearchResult,
	getCheckInSearchTableColumns,
} from './CheckInSearchTableUtils';
import { checkInMove } from 'Services/Api/_HumanWritten/CheckInService';
import alertToast from 'Util/ToastifyUtils';
import { CheckInSearchEmpty } from './CheckInSearchEmpty';
import { CheckInSearchStart } from './CheckInSearchStart';
import { CheckInMoveState } from '../CheckInMove/CheckInMoveContent';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { store } from 'Models/Store';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import useCheckInStore from 'Hooks/useCheckInStore';
import { showCheckInMoveConfirmationModal } from 'Views/Components/_HumanWritten/Modal/CheckInMoveConfirmationModalContents';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';

export interface CheckInSearchTableProps {
	/**
	 * state is an observable store (using useLocalStore).
	 *
	 * We pass the whole state, instead of just the input value, so that CheckInMove doesn't have to be an observer. If
	 * it were an observer, it would have to unnecessarily render everytime the input value changes. We only want
	 * CheckInSearchTable component to re-render when we have fetched the bookings based on the latest input value.
	 */
	state: CheckInMoveState;
	/**
	 * We have fetch results function as prop for ease of testing.
	 */
	fetch: (value: string, pageNo: number) => Promise<CheckInSearchResult>;
}

function CheckInSearchTable({
	state,
	fetch,
}: CheckInSearchTableProps) {
	const passengerTypes = usePassengerTypes();
	const checkInStore = useCheckInStore();
	const routes = useCheckInRoutes();
	const [bookings, setBookings] = React.useState<BookingEntity[] | undefined>();

	const onPageChange = React.useCallback((pageNo: number) => {
		runInAction(() => {
			state.pageNo = pageNo;
		});
	}, []);

	/**
	 * This useEffect is only triggered when search term is updated.
	 */
	React.useEffect(() => {
		if (state.searchTerm === undefined) {
			return;
		}

		if (state.pageNo !== 0) {
			// When there is a new search term, we want to reset the page number.
			// This will trigger the 2nd useEffect.
			onPageChange(0);
			return;
		}

		// This solves the problem where the component unmounts before the result has been processed
		let subscribed = true;

		const fetchData = async () => {
			runInAction(() => {
				state.isLoading = true;
			});
			const result = await fetch(state.searchTerm || '', state.pageNo);
			if (subscribed) {
				setBookings(result.bookingEntitys);
				runInAction(() => {
					state.totalRecords = result.countBookingEntitys.number;
					state.isLoading = false;
				});
			}
		};

		fetchData();

		return () => {
			subscribed = false;
		};
	}, [state.searchTerm]);

	/**
	 * This useEffect is only triggered when page number is updated.
	 */
	React.useEffect(() => {
		if (state.searchTerm === undefined) {
			return;
		}
		// This solves the problem where the component unmounts before the result has been processed
		let subscribed = true;

		const fetchData = async () => {
			runInAction(() => {
				state.isLoading = true;
			});
			const result = await fetch(state.searchTerm || '', state.pageNo);
			if (subscribed) {
				setBookings(result.bookingEntitys);
				runInAction(() => {
					state.totalRecords = result.countBookingEntitys.number;
					state.isLoading = false;
				});
			}
		};

		fetchData();

		return () => {
			subscribed = false;
		};
	}, [state.pageNo]);

	const headers: ICollectionHeaderProps<BookingEntity>[] = React.useMemo(() => {
		return getCheckInSearchTableColumns();
	}, [passengerTypes.type]);

	const customMoveBtn = React.useCallback((booking: BookingEntity) => {
		const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.stopPropagation();
			showCheckInMoveConfirmationModal({
				onConfirm: async () => {
					try {
						await checkInMove(booking.id, checkInStore.ferryTripId);
						await checkInStore.checkInBooking(booking.id, true);
						store.routerHistory.replace(routes.base);
						store.modal.hide();
					} catch (error) {
						console.error(error);
						alertToast(
							'Something went wrong',
							'error',
							undefined,
							{ autoClose: 2000 },
						);
					}
				},
			});
		};

		return (
			<CheckInMoveButton
				id={booking.id}
				className="check-in__btn--grey"
				onClick={onClick}
			/>
		);
	}, []);

	/**
	 * Important to wrap in `useMemo` otheriwse the `Collection` component will re-render everytime the search term
	 * is changed.
	 */
	const actions = React.useMemo(() => {
		return [
			{
				label: 'check-in',
				customButton: customMoveBtn,
				// Action is handled in the custom button
				action: () => {},
			},
		];
	}, []);

	if (state.isLoading) {
		return <LottieSpinner />;
	}

	if (bookings === undefined) {
		return <CheckInSearchStart />;
	}

	if (bookings.length === 0) {
		return <CheckInSearchEmpty />;
	}

	return (
		<>
			<Collection<BookingEntity>
				className="check-in__table"
				collection={bookings}
				headers={headers}
				actions={actions}
				hidePagination
			/>
			{/*
				Pagination component is separate from Collection as updating page number causes the whole component
				to re-render before the booking results are set.
			*/}
			<If condition={state.totalRecords > BOOKINGS_PER_PAGE}>
				<section className="collection-component pagination-only">
					<section className="collection__load">
						<Pagination
							pageNo={state.pageNo}
							totalRecords={state.totalRecords}
							onPageChange={onPageChange}
							perPage={BOOKINGS_PER_PAGE}
							showGoToPageBox={false}
						/>
					</section>
				</section>
			</If>
		</>
	);
}

export default observer(CheckInSearchTable);

import React from 'react';

import { useScanner } from '../Stores/useScanner';

/**
 * Displays detected text from Scanner when no matches found.
 */
export default function RetryMessage() {
	const attempts = useScanner(x => x.attempts);
	const detectedText = useScanner(x => x.detectedText);

	if (attempts > 0) {
		return (
			<div>
				{detectedText.length === 0 && <div>No rego plate found</div>}
				{detectedText.length > 0 && (
					<>
						<div>No matches found from:</div>
						{detectedText.map(text => (
							<div key={text}>{text}</div>
						))}
					</>
				)}
			</div>
		);
	}

	return <></>;
}

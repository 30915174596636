import { RefObject, useEffect, useRef } from 'react';

import { captureWholeImage } from '../Utils/captureWholeImage';
import { getUserMedia } from '../Utils/getUserMedia';

interface UseCameraProps {
	onCaptureSuccess(canvasImage: HTMLCanvasElement): Promise<void> | void;
	onCaptureError?(error: unknown): void;
	capturer?(videoRef: RefObject<HTMLVideoElement>): HTMLCanvasElement;
	onSetupError?(error: unknown): void;
}

/**
 * Handles capturing of images from the video ref.
 */
export const useCamera = ({
	onCaptureSuccess,
	onCaptureError,
	capturer = captureWholeImage,
	onSetupError,
}: UseCameraProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		// Start camera
		let stream: MediaStream;
		getUserMedia()
			.then(res => {
				stream = res;
				if (videoRef.current) {
					videoRef.current.srcObject = res;
				}
			})
			.catch(err => {
				console.error('Error accessing media devices.', err);
				onSetupError?.(err);
			});

		// Clean up: stop streaming when component unmounts
		return () => {
			if (stream) {
				stream.getTracks().forEach(track => track.stop());
			}
		};
	}, []);

	const capture = async () => {
		if (videoRef.current) {
			try {
				await onCaptureSuccess(capturer(videoRef));
			} catch (err) {
				await onCaptureError?.(err);
			}
		}
	};

	return { videoRef, capture };
};

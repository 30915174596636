import { BookingEntity, FerryTripEntity } from '../../../../Models/Entities';
import { isNotNullOrUndefined, stringNotEmpty } from '../../../TypeGuards';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';
import {
	calculateOneWayTrailerLengthPriceAlterBooking,
} from '../AlterationPriceCalculations/Alteration_CalculateOneWayTrailerLengthPrice';
import {
	calculateOneWayTrailerLengthPriceNewBooking,
} from '../CreationPriceCalculations/Creation_CalculateOneWayTrailerLengthPrice';

/**
 * This function will return the selected towOn length
 * type's price from the user selected ferry trip.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param priceCalculationProps: The props needed for price calculations.
 * @param trip: The new ferry trip to find trailer price difference from.
 * @param bookingToEdit?: The booking that is being edited (if in alteration mode).
 * @param optionId: The length type option from the dropdown menu on the vehicle tab
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 */
export const getOneWayTrailerLengthPriceDifference = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity,
	bookingToEdit: BookingEntity | null = null,
	optionId?: string,
	forEftpos = false,
): number => {
	// If wizard mode is ALTERATION
	if (isNotNullOrUndefined(bookingToEdit)
		&& isNotNullOrUndefined(bookingToEdit.id)
		&& isNotNullOrUndefined(priceCalculationProps.towOnTypeId)) {
		return calculateOneWayTrailerLengthPriceAlterBooking(
			priceCalculationProps,
			trip,
			(departureTrip ? bookingToEdit : bookingToEdit.returnBooking) ?? bookingToEdit,
			optionId,
			forEftpos,
			departureTrip,
		);
	}
	// If wizard mode is CREATE
	if (isNotNullOrUndefined(priceCalculationProps.towOnTypeId)
		&& (stringNotEmpty(priceCalculationProps.trailerLengthId) || stringNotEmpty(optionId))) {
		return calculateOneWayTrailerLengthPriceNewBooking(
			priceCalculationProps.towOnTypeId,
			trip,
			priceCalculationProps.trailerLengthId,
			optionId,
		);
	}
	return 0;
};

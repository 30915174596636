import { useEffect, useRef } from 'react';

/**
 * Instantiate an AbortController with auto clean up when component unmounts.
 */
export const useAbortController = () => {
	const abortControllerRef = useRef<AbortController>();

	useEffect(() => {
		return () => {
			abortControllerRef.current?.abort();
		};
	}, []);

	return abortControllerRef;
};

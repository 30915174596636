import React from 'react';

import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { useScannerLocation } from '../../Hooks/useScannerLocation';
import { ScannerResultData } from '../../Types/scannerResultState';
import Details from './Details';

type AlreadyCheckedInProps = Pick<
	ScannerResultData,
	| 'driver'
	| 'cargoId'
	| 'firstTimer'
	| 'tag'
	| 'passengers'
	| 'cargoLengthLabel'
	| 'cargoLengthValue'
	| 'towOnLengthLabel'
	| 'towOnLengthValue'
	| 'addOns'
>;

export default function AlreadyCheckedIn({
	driver,
	cargoId,
	firstTimer,
	tag,
	passengers,
	cargoLengthLabel,
	cargoLengthValue,
	towOnLengthLabel,
	towOnLengthValue,
	addOns,
}: AlreadyCheckedInProps) {
	const { goToScanner } = useScannerLocation();

	return (
		<div className="scanner-result page">
			<button className="close-btn--black" title="Back to scanner" onClick={goToScanner}>
				<Icon name="cross" />
			</button>
			<div className="body">
				<h1>ERROR</h1>
				<h2 className="title">Already checked in</h2>
				<Details
					driver={driver}
					cargoId={cargoId}
					firstTimer={firstTimer}
					tag={tag}
					passengers={passengers}
					cargoLengthLabel={cargoLengthLabel}
					cargoLengthValue={cargoLengthValue}
					towOnLengthLabel={towOnLengthLabel}
					towOnLengthValue={towOnLengthValue}
					addOns={addOns}
				/>
			</div>
			<div className="footer">
				<Button
					className="back-btn"
					onClick={goToScanner}
					display={Display.Solid}
					colors={Colors.Black}
					sizes={Sizes.Medium}
				>
					Back
				</Button>
			</div>
		</div>
	);
}

import * as React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import BookingFormGroupHeader from '../BookingFormGroupHeader';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { BookingFormEditSectionProps } from './BookingFormEdit';
import useCheckInStore from 'Hooks/useCheckInStore';

function BookingFormEditTrailer({
	mode,
	groupHeaders,
	errors,
	options,
	setOptions,
	resetContinue,
	oneInputPerRow = false,
}: BookingFormEditSectionProps) {
	const checkInStore = useCheckInStore();
	const { formState } = checkInStore;

	if (!!!whiteLabelStore.config.trailersEnabled) {
		return null;
	}

	const trailerOptions = checkInStore.ferryTrip
		.getFilteredMeasurements('LENGTH')
		?.sort((a, b) => (a.value < b.value ? -1 : 1))
		?.map(option => {
			return {
				display: option.label,
				value: option.id,
			};
		});

	return (
		<BookingFormGroupHeader
			title="Trailer"
			config={{
				model: groupHeaders.trailers,
				modelProperty: 'show',
				disabled: !groupHeaders.vehicles.show,
				onAfterChange: value => {
					resetContinue();
					if (value) {
						runInAction(() => {
							if (!formState.towOnTypeId) {
								formState.towOnTypeId = options.allTowOnTypes[0].value;
							}
							if (!formState.selectedTrailerLengthId) {
								formState.selectedTrailerLengthId = checkInStore.ferryTrip.getMinimumMeasurement()?.id;
							}
						});
					}
				},
			}}
		>
			<Combobox
				model={formState}
				modelProperty="towOnTypeId"
				label="Type"
				placeholder="Select trailer type"
				options={options.allTowOnTypes}
				searchable={false}
				onAfterChange={() => resetContinue()}
			/>
			<Combobox
				model={formState}
				modelProperty="selectedTrailerLengthId"
				label="Length"
				placeholder="Select length type"
				options={trailerOptions}
				searchable={false}
				onAfterChange={() => resetContinue()}
			/>
		</BookingFormGroupHeader>
	);
}

export default observer(BookingFormEditTrailer);

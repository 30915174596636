import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';
import { BookingEntity, FerryTripEntity } from '../../../../Models/Entities';
import { TicketsTabTrip } from '../../../../Services/Api/_HumanWritten/BookingWizardDataService';
import { isNotNullOrUndefined, stringNotEmpty } from '../../../TypeGuards';
import { getVehicleBasePriceDifference, getWeightPriceDifference } from '../../VehicleTrailerTicketTypeUtils';
import { getMinimumMeasurementFromStore, getMinimumMeasurementTypeForFerryTrip } from '../../MeasurementUtils';
import { getPassengersTotalPriceAlteration } from '../../PassengerTicketTypeUtils';
import {
	filterReservedAndBookedAlterations,
	getMostRecentBookedAlteration,
	getMostRecentBookedAlterationWithFerryTrip,
	sortAlterationsByDateCreated,
} from '../../AlterationSortingUtils';

/**
 * This function will calculate the base price of an alteration.
 * This will only include the price difference between the old alteration and the new alteration changes.
 * If the user changes the ticket, this will also include the changeFee (if applicable).
 * Changes include passengers or ticket change.
 * @param priceCalculationProps: The price calculation props needed for the calculation.
 * @param trip: The newest ferry trip that the user has selected (may be the same as the original booking).
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param bookingToEdit: The booking that is being altered.
 * @param afterPayment: If the calculation is being done after payment has been taken
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 * @param afterAlterationFeeAndPriceIncrease: If it is true, then it will not include any price increases
 * that have occured from changing to a more expensive ferry ticket (will also not include alteration fees).
 * */
export const calculateBasePriceAlterBooking = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity | TicketsTabTrip,
	bookingToEdit: BookingEntity,
	afterPayment: boolean,
	forEftpos = false,
	afterAlterationFeeAndPriceIncrease: boolean = true,
): number => {
	let baseVehiclePrice = 0;
	let baseTrailerPrice = 0;

	const bookingToUse = (departureTrip ? bookingToEdit : bookingToEdit?.returnBooking) ?? bookingToEdit;

	const vehicleBooking = priceCalculationProps.tabSelected === 'vehicle';
	const hasTrailer = priceCalculationProps.towOnTypeId !== 'NO_TRAILER'
		&& isNotNullOrUndefined(priceCalculationProps.towOnTypeId) && priceCalculationProps.towOnTypeId !== '';

	const sortedAlterations = filterReservedAndBookedAlterations(
		sortAlterationsByDateCreated(bookingToUse.alterations),
	);
	const mostRecentBookedAlteration = getMostRecentBookedAlteration(sortedAlterations);
	let previousBookedAlteration = getMostRecentBookedAlteration(
		sortedAlterations);

	let ticketHasBeenChanged = trip?.id !== previousBookedAlteration?.ferryTripId;

	let oldVehiclePricePaid = previousBookedAlteration?.getCargoPrice();
	let oldVehicleLengthId = previousBookedAlteration?.getCargo()?.selectedLengthId;
	let oldVehicleWeightId = previousBookedAlteration?.getCargo()?.selectedWeightId;

	// Set the changeFee for the trip direction being calculated
	let changeFee = 0;
	if (sortedAlterations.some(x => x.status !== 'RESERVED') && afterPayment) {
		const oldAlteration = getMostRecentBookedAlteration(
			sortedAlterations,
			mostRecentBookedAlteration.id);
		if (isNotNullOrUndefined(oldAlteration)) {
			previousBookedAlteration = oldAlteration;
		}

		ticketHasBeenChanged = mostRecentBookedAlteration.ferryTripId !== previousBookedAlteration?.ferryTripId;

		oldVehiclePricePaid = previousBookedAlteration?.getCargoPrice();
		oldVehicleLengthId = previousBookedAlteration?.getCargo()?.selectedLengthId;
		oldVehicleWeightId = previousBookedAlteration?.getCargo()?.selectedWeightId;

		// IF there is a cargo associated to the booking.
		// Calculate cargo price between old cargo length price and new cargo length price
		if (vehicleBooking && ticketHasBeenChanged && isNotNullOrUndefined(oldVehicleLengthId)
			&& isNotNullOrUndefined(oldVehicleWeightId)) {
			baseVehiclePrice = getVehicleBasePriceDifference(
				trip,
				oldVehicleLengthId,
				oldVehiclePricePaid,
				oldVehicleWeightId,
				afterAlterationFeeAndPriceIncrease,
			);

			const oldTrailerLengthId = previousBookedAlteration?.getTowOn()?.selectedLengthId;
			const oldTrailerWeightId = getMinimumMeasurementFromStore('WEIGHT')?.id;

			if (hasTrailer && isNotNullOrUndefined(oldTrailerLengthId)) {
				const oldTrailerPricePaid = previousBookedAlteration?.getTowOnPrice();
				baseTrailerPrice = getVehicleBasePriceDifference(
					trip,
					oldTrailerLengthId,
					oldTrailerPricePaid,
					oldTrailerWeightId,
					afterAlterationFeeAndPriceIncrease,
				);
			}
		} else if (vehicleBooking && !ticketHasBeenChanged
			&& stringNotEmpty(priceCalculationProps.vehicleWeightId)
			&& oldVehicleWeightId !== priceCalculationProps.vehicleWeightId) {
			baseVehiclePrice = getWeightPriceDifference(
				trip,
				oldVehicleWeightId ?? '',
				getMinimumMeasurementTypeForFerryTrip(trip, 'WEIGHT').id,
			);
		}

		changeFee = mostRecentBookedAlteration.fees.find(x => x.feeType === 'ALTERATION')?.amount ?? 0;
	} else if (isNotNullOrUndefined(priceCalculationProps)) {
		// IF there is a cargo associated to the booking.
		// Calculate cargo price between old cargo length price and new cargo length price
		if (vehicleBooking && ticketHasBeenChanged && isNotNullOrUndefined(oldVehicleLengthId)
			&& isNotNullOrUndefined(oldVehicleWeightId)) {
			baseVehiclePrice = getVehicleBasePriceDifference(
				trip,
				oldVehicleLengthId,
				oldVehiclePricePaid,
				oldVehicleWeightId,
				afterAlterationFeeAndPriceIncrease,
			);

			const oldTrailerLengthId = previousBookedAlteration?.getTowOn()?.selectedLengthId;
			const oldTrailerWeightId = getMinimumMeasurementFromStore('WEIGHT')?.id;

			if (hasTrailer && isNotNullOrUndefined(oldTrailerLengthId)) {
				const oldTrailerPricePaid = previousBookedAlteration?.getTowOnPrice();
				baseTrailerPrice = getVehicleBasePriceDifference(
					trip,
					oldTrailerLengthId,
					oldTrailerPricePaid,
					oldTrailerWeightId,
					afterAlterationFeeAndPriceIncrease,
				);
			}
		} else if (vehicleBooking && !ticketHasBeenChanged
			&& stringNotEmpty(priceCalculationProps.vehicleWeightId)
			&& oldVehicleWeightId !== priceCalculationProps.vehicleWeightId) {
			// baseVehiclePrice = getWeightPriceDifference(trip, oldVehicleWeightId, priceCalculationProps.vehicleWeightId);
		}
		if (!priceCalculationProps.alterationFeesRemoved) {
			changeFee = (departureTrip || priceCalculationProps.tripType === 'one way'
				? priceCalculationProps?.departingChangeFee
				: priceCalculationProps?.returningChangeFee
			) ?? 0;
		}
	}

	// Calculate passenger prices based on ticket type price differences or increases/decreases to tickets
	const totalPassengerPriceChange = getPassengersTotalPriceAlteration(
		priceCalculationProps,
		trip,
		bookingToUse ?? bookingToEdit,
		afterPayment,
		forEftpos,
		afterAlterationFeeAndPriceIncrease,
	);

	// Calculate the total base cost for the ferry ticket from user selections
	let calculatedBasePriceAlteration = (
		baseVehiclePrice
		+ baseTrailerPrice
		+ totalPassengerPriceChange);

	// Add FEES to total base cost if the ferry ticket has been changed
	if (ticketHasBeenChanged) {
		calculatedBasePriceAlteration += changeFee;
	}
	if (calculatedBasePriceAlteration < 0) {
		if (afterPayment) {
			const nonRefundableAmount = filterReservedAndBookedAlterations(
				sortAlterationsByDateCreated(bookingToUse.alterations),
			)[0]
				.managerBookingDiscountUsages
				.reduce((accumulator, current) => current.usedAmount + accumulator, 0) ?? 0;
			return Math.min(calculatedBasePriceAlteration + nonRefundableAmount, 0);
		}
		return calculatedBasePriceAlteration
			+ (bookingToUse?.bookingSummaries?.recentSummary?.nonRefundableAmount ?? 0);
	}
	return calculatedBasePriceAlteration;
};

import { BookingEntity, FerryTripEntity } from 'Models/Entities';
import {
	calculateTotalOneWayTicketPriceAlterBooking,
} from 'Util/_HumanWritten/PriceCalculations/AlterationPriceCalculations/Alteration_CalculateTotalOneWayTicketPrice';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import {
	AdditionalOption,
	BookingWizardCartFields,
	getOldFerryBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';

/**
 * This function calculates the cart subtotal (before surcharge fees and gift certificates are applied).
 * @param booking: The booking information from the wizard data.
 * An alteration will only ever have one booking in the cart at a time.
 * @param bookingToEdit: The booking that is being altered.
 * @param useRecentSummary
 * @param afterPayment: If the calculation is being done after payment has been taken
 * @param tripSummaryLocation: The location that the calculations are happening (e.g. cart or booking success page)
 * @param forEftpos: This will determine whether or not the prices can go into the negative values */
export const calculateCartSubtotalAlterBooking = (
	booking: BookingWizardCartFields,
	bookingToEdit: BookingEntity,
	useRecentSummary: boolean,
	afterPayment: boolean,
	forEftpos = false,
	tripSummaryLocation: tripSummaryLocationType,
) => {
	let runningTotal = 0;
	const departingSummaries = bookingToEdit.bookingSummaries;
	const returningSummaries = bookingToEdit.returnBooking?.bookingSummaries;

	const { wizardData } = booking;

	const departingAddOns = ((tripSummaryLocation === 'check-in'
			&& !!departingSummaries?.previousSummary) || afterPayment
		? departingSummaries?.previousSummary
		: departingSummaries?.recentSummary)?.additionalBookingOptions;
	const oldDepartingAddOns = departingAddOns
		?.map(item => {
			return {
				optionId: item.option?.id ?? '',
				amount: item.quantity ?? 0,
				optionPrice: item.option?.staticPrice ?? 0,
				optionName: item.option?.name ?? '',
				gstExempt: item.option?.excludeGST,
			};
		}).filter(x => x.amount !== 0) as AdditionalOption[] ?? [];

	// If there is a departure ticket associated to the booking
	if (wizardData.departureTicketId !== '') {
		runningTotal += calculateTotalOneWayTicketPriceAlterBooking(
			true,
			booking.wizardData,
			booking.selectedTrips.departingTrip as FerryTripEntity,
			bookingToEdit,
			oldDepartingAddOns,
			afterPayment,
			forEftpos,
		);
	}

	const oldWizardData = getOldFerryBookingWizardData();

	// TWO SCENARIOS:
	// FIRST: If the user is in the booking wizard still and has old booking wizard data
	// IF there is oldWizardData
	// AND departureTrip === undefined (meaning it is a two-way trip alteration)
	// AND returningTicketId is not an empty string
	// SECOND: If the user is out of the booking wizard (booking success page)
	// and doesn't have access to old booking wizard data
	// ELSE IF there is NO oldWizardData
	// AND the bookingToEdit is not desynced (meaning the user hasn't edited just one-way of a booking)
	// const isAssociatedReturnTicket = isNullOrUndefined(oldWizardData)
	// 	? bookingToEdit.desynced
	// 	: (
	// 		isNotNullOrUndefined(oldWizardData)
	// 		&& oldWizardData.departureTrip === undefined
	// 		&& oldWizardData.returningTicketId !== ''
	// 	);
	const isAssociatedReturnTicket = (
		isNotNullOrUndefined(oldWizardData)
		&& oldWizardData.departureTrip === undefined
		&& oldWizardData.returningTicketId !== ''
	) || (
		isNullOrUndefined(oldWizardData)
		&& !bookingToEdit.desynced
	);

	// If there is a returning ticket associated to the booking
	if (isAssociatedReturnTicket && isNotNullOrUndefined(returningSummaries)) {
		const returningAddOns = ((tripSummaryLocation === 'check-in'
				&& !!returningSummaries.previousSummary) || afterPayment
			? returningSummaries.previousSummary
			: returningSummaries.recentSummary)?.additionalBookingOptions;
		const oldReturningAddOns = returningAddOns
			?.map(item => {
				return {
					optionId: item.option?.id ?? '',
					amount: item.quantity ?? 0,
					optionPrice: item.option?.staticPrice ?? 0,
					optionName: item.option?.name ?? '',
					gstExempt: item.option?.excludeGST,
				};
			}).filter(x => x.amount !== 0) as AdditionalOption[] ?? [];

		runningTotal += calculateTotalOneWayTicketPriceAlterBooking(
			false,
			booking.wizardData,
			booking.selectedTrips.returningTrip as FerryTripEntity,
			bookingToEdit,
			oldReturningAddOns,
			afterPayment,
			forEftpos,
		);
	}

	return runningTotal;
};

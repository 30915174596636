/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { createContext } from 'react';
import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { IGlobalModal } from 'Views/Components/Modal/GlobalModal';
import { ApolloClient } from '@apollo/client';
import type { ClientsideConfiguration, BuildVersion } from 'Global';
// % protected region % [Add any extra store imports here] on begin
import { IUserEntityAttributes } from 'Models/Entities/UserEntity';
import { measurementType } from './Enums';
import { FH_VERSION } from '../Constants';
// % protected region % [Add any extra store imports here] end

// % protected region % [Change the group return result as needed] off begin
export interface IGroupResult {
	name: string;
	hasBackendAccess: boolean;
}
// % protected region % [Change the group return result as needed] end

// % protected region % [Change The user return result as needed] on begin
interface IUserResultBase {
	type: 'user-data';
	id: string;
	userName: string;
	email: string;
	groups: IGroupResult[];
}

export interface IUserResult extends IUserResultBase {
	user?: IUserEntityAttributes;
}

export type measurementDetails = {
	id: string,
	label: string,
	value: number,
	measurementType: measurementType,
};
// % protected region % [Change The user return result as needed] end

export interface IStore {
	/**
	 * The current location in the application
	 */
	appLocation: 'frontend' | 'admin';

	/**
	 * The router history object for React Router
	 */
	routerHistory: History;

	/**
	 * The client for Apollo
	 */
	apolloClient: ApolloClient<Record<string, unknown>>;

	/**
	 * The global modal that is stored in the app and can be called imperatively
	 */
	modal: IGlobalModal;

	/**
	 * This signifies weather we are logged in or not
	 * Only ever set this value to true if there is a value set in this.token
	 */
	readonly loggedIn: boolean;

	/**
	 * The user Id of the logged-in user
	 */
	readonly userId: string | undefined;

	/**
	 * The user name of the logged in user
	 */
	readonly userName: string | undefined;

	/**
	 * The email of the current logged in user
	 */
	readonly email: string | undefined;

	/**
	 * The groups that the logged in user are a part of
	 */
	readonly userGroups: IGroupResult[];

	/**
	 * Does this user have access to the backend admin views
	 */
	readonly hasBackendAccess: boolean;

	/**
	* The configuration sent from the serverside
	*/
	readonly configuration: ClientsideConfiguration;

	/**
	* The build version information of the application
	*/
	readonly buildVersion: BuildVersion;

	/**
	 * Is the frontend in edit mode
	 */
	frontendEditMode: boolean;

	/**
	 * Sets the current logged in user in the store
	 * @param userResult
	 */
	setLoggedInUser(userResult: IUserResult): void;

	/**
	 * Clears the logged in user data from the store
	 */
	clearLoggedInUser(): void;

	// % protected region % [Add any extra store interface methods or properties here] on begin
	readonly userInitials: string | undefined;
	tempEmail: string;
	userData?: IUserEntityAttributes;
	readonly postcode: string | null;
	readonly phone: string | null;
	readonly lastName: string | null;
	readonly firstName: string | null;
	isSuper: boolean;
	isAdmin: boolean;
	isManager: boolean;
	isStaff: boolean;
	isInvoicedUser: boolean;
	isFixedPriceUser: boolean;

	/** This is a breakdown of the cart price. */
	bookingIdToPrice: Map<string, number>;
	updateBookingPrice(bookingId: string, amount: number): void;
	removeBookingPrice(bookingId: string): void;
	clearBookingPrices(): void;

	/** This is the price of the changes made before reaching the cart page. */
	cartPriceDifference: number;
	setCartPriceDifference(amount: number, bookingId?: string): void;

	/** This is the subtotal price of the cart. */
	cartPriceWithoutSurcharge: number;
	setCartPriceWithoutSurcharge(amount: number): void;

	/** This is the total price of the cart. */
	cartPriceWithSurcharge: number;
	setCartPriceWithSurcharge(amount: number): void;

	cartPrice: number;

	// The fields are stored in the server as percentages, so they will need to be divided by 100 to arrive at the
	// correct multipliers
	// For example. The server stores the GST value as a percentage, so the server value is 10. The value we want for
	// the multiplier is a factor to multiply by, so the value here needs to be that percentage divided by 100
	// (10/100 = 0.1). This same logic applies for the credit card surcharge multiplier as well
	gstMultiplier: number;
	ccSurchargeMultiplier: number;
	measurements: measurementDetails[];

	spinnerJSON: unknown;
	setSpinnerJSON(json: unknown): void;

	redirectedFromWaitlistEmail: boolean;
	setRedirectedFromWaitlistEmail(wasRedirectedFromEmail: boolean): void;

	hasServerVersionMismatch: boolean;
	mismatchedVersionCounter: number;
	versionPopUpTimestamp: Date | null;
	// % protected region % [Add any extra store interface methods or properties here] end
}

/**
 * A global singleton store that contains a global state of data
 */
export class Store implements IStore {
	@observable
	user?: IUserResult;

	@observable
	appLocation: 'frontend' | 'admin' = 'frontend';

	@observable
	clientsideConfiguration: ClientsideConfiguration;

	@observable
	_buildVersion: BuildVersion;

	routerHistory: History;

	apolloClient: ApolloClient<Record<string, unknown>>;

	modal: IGlobalModal;

	@computed
	public get loggedIn() {
		// % protected region % [Customise the loggedIn getter here] off begin
		return this.user !== undefined;
		// % protected region % [Customise the loggedIn getter here] end
	}

	@computed
	public get userId(): string | undefined {
		// % protected region % [Customise the userId getter here] off begin
		return this.user ? this.user.id : undefined;
		// % protected region % [Customise the userId getter here] end
	}

	@computed
	public get userName(): string | undefined {
		// % protected region % [Customise the user name getter here] off begin
		return this.user?.userName;
		// % protected region % [Customise the user name getter here] end
	}

	@computed
	public get email(): string | undefined {
		// % protected region % [Customise the email getter here] off begin
		return this.user ? this.user.email : undefined;
		// % protected region % [Customise the email getter here] end
	}

	@computed
	public get userGroups(): IGroupResult[] {
		// % protected region % [Customise the userGroups getter here] off begin
		if (this.user) {
			return [...this.user.groups];
		}
		return [];
		// % protected region % [Customise the userGroups getter here] end
	}

	@computed
	public get hasBackendAccess() {
		// % protected region % [Customise the hasBackendAccess getter here] off begin
		if (this.user) {
			return this.user.groups.some(ug => ug.hasBackendAccess);
		}
		return false;
		// % protected region % [Customise the hasBackendAccess getter here] end
	}

	@computed
	public get configuration(): ClientsideConfiguration {
		return this.clientsideConfiguration;
	}

	@computed
	public get buildVersion(): BuildVersion {
		return this._buildVersion;
	}

	@observable
	public frontendEditMode = false;

	@action
	public setLoggedInUser(userResult: IUserResult) {
		// % protected region % [Customise the setLoggedInUser here] off begin
		this.user = userResult;
		// % protected region % [Customise the setLoggedInUser here] end
	}

	@action
	public clearLoggedInUser() {
		// % protected region % [Customise the clearLoggedInUser here] off begin
		this.user = undefined;
		// % protected region % [Customise the clearLoggedInUser here] end
	}

	@action
	public setClientsideDataConfiguration(config: ClientsideConfiguration) {
		// % protected region % [Customise the setClientsideDataConfiguration here] off begin
		this.clientsideConfiguration = config;
		// % protected region % [Customise the setClientsideDataConfiguration here] end
	}

	@action
	public setBuildVersion(build: BuildVersion) {
		// % protected region % [Customise the setBuildVersion here] off begin
		this._buildVersion = build;
		// % protected region % [Customise the setBuildVersion here] end
	}

	constructor() {
		// % protected region % [Customise the constructor here] on begin
		this.configureUser();
		this.configureClientsideData();
		this.configureBuildVersion();
		this.configureMultipliers();
		this.configureMeasurements();
		// % protected region % [Customise the constructor here] end
	}

	configureUser() {
		// % protected region % [Customise the configureUser here] off begin
		if (window.loginData && !this.user) {
			this.setLoggedInUser(window.loginData);
		}
		// % protected region % [Customise the configureUser here] end
	}

	configureClientsideData() {
		// % protected region % [Customise the configureClientsideData here] off begin
		this.setClientsideDataConfiguration(window.clientsideDataConfiguration);
		// % protected region % [Customise the configureClientsideData here] end
	}

	configureBuildVersion() {
		// % protected region % [Customise the configureBuildVersion here] on begin
		this.setBuildVersion({
			version: FH_VERSION,
			buildRef: '',
		} as BuildVersion);
		// % protected region % [Customise the configureBuildVersion here] end
	}

	// % protected region % [Add any extra store methods or properties here] on begin
	configureMultipliers() {
		try {
			if (window.gstMultiplier) {
				this.setGstMultiplier(window.gstMultiplier);
			}
		} catch (e) { /* Suppress error parsing cookie */ }

		try {
			if (window.ccSurchargeMultiplier) {
				this.setCcSurchargeMultiplier(window.ccSurchargeMultiplier);
			}
		} catch (e) { /* Suppress error parsing cookie */ }
	}

	configureMeasurements() {
		try {
			if (window.measurements) {
				this.measurements = window.measurements;
			}
		} catch (e) { /* Suppress error parsing cookie */ }
	}

	@observable
	hasServerVersionMismatch: boolean = false;

	@observable
	mismatchedVersionCounter: number = 0;

	@observable
	versionPopUpTimestamp: Date | null = null;

	@computed
	public get userInitials(): string | undefined {
		if (!this.user) {
			return undefined;
		}

		if (this.userData) {
			return (`${this.userData.firstName[0]}${this.userData.lastName[0]}`).toUpperCase();
		}

		if (this.userName) {
			return this.userName.substring(0, 2).toUpperCase();
		}

		return 'ME'; // Default when we don't know what else to do!
	}

	@computed
	public get firstName(): string | null {
		return this.userData?.firstName ?? null;
	}

	@computed
	public get lastName(): string | null {
		return this.userData?.lastName ?? null;
	}

	@computed
	public get postcode(): string | null {
		return this.userData?.postcode ?? null;
	}

	@computed
	public get phone(): string | null {
		return this.userData?.phone ?? null;
	}

	tempEmail: string;

	@action
	public setTempEmail(tempEmail: string) {
		this.tempEmail = tempEmail;
	}

	@action
	public getTempEmail() {
		return this.tempEmail;
	}

	@computed
	get userData(): IUserEntityAttributes | undefined {
		return this.user?.user;
	}

	@computed
	get isInvoicedUser(): boolean {
		return store.userGroups.some(ug => ug.name === 'InvoicedUser');
	}

	@computed
	get isFixedPriceUser(): boolean {
		return store.userGroups.some(ug => ug.name === 'FixedPriceUser');
	}

	@computed
	get isStaff(): boolean {
		return store.userGroups.some(ug => (
			ug.name === 'Staff'
			|| ug.name === 'Manager'
			|| ug.name === 'Admin'
			|| ug.name === 'Super Administrators'));
	}

	@computed
	get isManager(): boolean {
		return store.userGroups.some(ug => (
			ug.name === 'Manager'
			|| ug.name === 'Admin'
			|| ug.name === 'Super Administrators'));
	}

	@computed
	get isAdmin(): boolean {
		return store.userGroups.some(ug => (
			ug.name === 'Admin'
			|| ug.name === 'Super Administrators'));
	}

	@computed
	get isSuper(): boolean {
		return store.userGroups.some(ug => (ug.name === 'Super Administrators'));
	}

	/**
	 * This data structure is important because we don't know the price change from the total cart price alone.
	 * We need the price of the individual booking that is being edited as part of the calculation.
	 */
	@observable
	bookingIdToPrice: Map<string, number> = new Map();

	@action
	public updateBookingPrice(bookingId: string, amount: number) {
		this.bookingIdToPrice.set(bookingId, amount);
	}

	@action
	public removeBookingPrice(bookingId: string) {
		if (!this.bookingIdToPrice.has(bookingId)) {
			return;
		}
		// When removing a booking from cart, the booking price also needs to be removed from the cart price
		const bookingPrice = store.bookingIdToPrice.get(bookingId) ?? 0;
		store.setCartPriceWithSurcharge(store.cartPriceWithSurcharge - bookingPrice);
		store.setCartPriceWithoutSurcharge(store.cartPriceWithoutSurcharge - bookingPrice);
		store.bookingIdToPrice.delete(bookingId);
	}

	@action
	public clearBookingPrices() {
		this.bookingIdToPrice.clear();
	}

	@observable
	cartPriceDifference: number = 0;

	@observable
	cartPriceWithoutSurcharge: number = 0;

	@observable
	cartPriceWithSurcharge: number = 0;

	@observable
	redirectedFromWaitlistEmail: boolean = false;

	@action
	public setCartPriceDifference(amount: number, bookingId?: string) {
		if (bookingId && this.bookingIdToPrice.has(bookingId)) {
			//
			// We subtract the price of the existing booking because it is already part of the total cart price.
			// The cartPriceDifference is the newest changes before arrive to the cart page.
			//
			this.cartPriceDifference = amount - (this.bookingIdToPrice.get(bookingId) ?? 0);
			return;
		}

		this.cartPriceDifference = amount;
	}

	@action
	public setCartPriceWithoutSurcharge(amount: number) {
		this.cartPriceWithoutSurcharge = amount;
	}

	@action
	public setCartPriceWithSurcharge(amount: number) {
		this.cartPriceWithSurcharge = amount;
	}

	@action
	public setRedirectedFromWaitlistEmail(wasRedirected: boolean) {
		this.redirectedFromWaitlistEmail = wasRedirected;
	}

	get cartPrice(): number {
		// Show total price with gst and cc for these paths
		const paths = [
			'/booking-wizard/cart',
			'/booking-wizard/t-and-cs',
			'/booking-wizard/payment',
		];

		if (this.routerHistory && paths.includes(this.routerHistory.location.pathname)) {
			// Total price includes all bookings in the cart + cc surcharge
			return this.cartPriceWithSurcharge;
		}

		// When user is not in the page from the list, they are typically editing/adding a booking
		// currentBookingAmount is the difference between the total price vs the cost of changes made
		// previousBookingsSubtotalAmount is the total price of all bookings in the cart minus cc surcharge
		return this.cartPriceDifference + this.cartPriceWithoutSurcharge;
	}

	@observable
	gstMultiplier: number = 0;

	@action
	public setGstMultiplier(newMultiplier: number) {
		this.gstMultiplier = newMultiplier;
	}

	@observable
	ccSurchargeMultiplier: number = 0;

	@action
	public setCcSurchargeMultiplier(newMultiplier: number) {
		this.ccSurchargeMultiplier = newMultiplier;
	}

	@observable
	spinnerJSON: unknown = '';

	@action
	public setSpinnerJSON(spinnerJSON: unknown) {
		this.spinnerJSON = spinnerJSON;
	}

	public measurements: measurementDetails[];
	// % protected region % [Add any extra store methods or properties here] end
}

export const store: IStore = new Store();
export const StoreContext = createContext<IStore>(store);

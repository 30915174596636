/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import Card from '../../Components/Card/Card';
import CardGroup from '../../Components/Card/CardGroup';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
import { clearFerryTripCache } from 'Services/Api/_HumanWritten/FerryTripEntityService';
import * as reactRouterDom from 'react-router-dom';
import alertToast from 'Util/ToastifyUtils';
import If from '../../Components/If/If';

interface AdminPageState {
	loading: boolean;
}
// % protected region % [Add any extra imports here] end

@observer
export default class AdminPage extends React.Component<reactRouterDom.RouteComponentProps, AdminPageState> {
	constructor(props: reactRouterDom.RouteComponentProps) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	setLoadingTrue = () => {
		this.setState({ loading: true });
	}

	setLoadingFalse = () => {
		this.setState({ loading: false });
	}

	render() {
		// % protected region % [Override contents here] on begin
		const { loading } = this.state;

		return (
			<PageWrapper {...this.props}>
				<div className="dashboard">
					<div className="dashboard-heading">
						<h2>Admin Dashboard</h2>
					</div>
					<div className="dashboard-bot-version">
						<p>Version: <i>{store.buildVersion.version}</i></p>
						{/* <p>Build ref: <i>{store.buildVersion.buildRef}</i></p> */}
						{/* <p>Bot version: <i>3.3.0.0</i></p> */}
					</div>
					<div className="dashboard-cards">
						<If condition={store.isSuper}>
							<CardGroup>
								<Card
									href="/admin/graphiql"
									rounded
									iconOnly
									icon="icon-globe"
								>
									<h4>View GraphiQL</h4>
									<p>View the GraphiQL developer tool to write your api requests.</p>
								</Card>
								<Card
									href="/api/swagger"
									icon="icon-git-merge"
									iconOnly
									rounded
								>
									<h4>View OpenAPI</h4>
									<p>View the app&apos;s swagger docs and the structure of the API.</p>
								</Card>
							</CardGroup>
						</If>
						<CardGroup>
							<If condition={store.isSuper}>
								<Card
									href="https://gitlab.codebots.dev"
									icon="icon-api"
									iconOnly
									rounded
								>
									<h4>View Git</h4>
									<p>Access your source code by cloning from your git repository.</p>
								</Card>
							</If>
							<Card
								icon="icon-clean"
								iconOnly
								rounded
								className="clear-cache-card"
								makeButton
								disabled={loading}
								onClick={async () => {
									this.setLoadingTrue();
									const res = await clearFerryTripCache();
									if (res.status === 200) {
										alertToast('Successfully cleared cached ferry trips.', 'success');
									} else {
										alertToast('Could not clear cached ferry trips.', 'error');
									}
									this.setLoadingFalse();
								}}
							>
								<h4>{loading ? 'Clearing cache...' : 'Clear cache'}</h4>
								<p>Clear all cached ferry trips from redis. For use when cached ferry trips are not appearing correctly.</p>
							</Card>
						</CardGroup>
					</div>
				</div>
			</PageWrapper>
		);
		// % protected region % [Override contents here] end
	}
}

import React from 'react';
import { GiftCertificateUsageEntity } from 'Models/Entities';
import { observer } from 'mobx-react';
import { CartCalculationLineItem } from './CartCalculationLineItem';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';

export interface GiftUsageLinesProps {
	giftCertificateUsages: GiftCertificateUsageEntity[];
	onClearGiftCertificate: ((code: string) => void);
}

function GiftUsageLines({ giftCertificateUsages, onClearGiftCertificate }: GiftUsageLinesProps) {
	const giftUsageToDisplay = giftCertificateUsages.filter(x => x.usedValue > 0);
	return (
		<>
			{giftUsageToDisplay.map(usage => (
				<CartCalculationLineItem
					keyToUse={usage?.id}
					lineItemDisplayName={`Code (${usage?.giftCertificate?.code ?? ''}):`}
					lineItemPrice={formatPriceDisplay(usage.usedValue * -1, true)}
					className={`gift-certificate ${usage?.giftCertificate?.code}`}
					hasRemovalButton={isNotNullOrUndefined(onClearGiftCertificate)}
					removalOnClick={() => {
						if (isNotNullOrUndefined(onClearGiftCertificate)) {
							return onClearGiftCertificate(usage?.giftCertificate?.code);
						}
						return () => {};
					}}
				/>
			))}
		</>
	);
}

export default observer(GiftUsageLines);

import useCheckInStore from 'Hooks/useCheckInStore';
import { action, observable } from 'mobx';
import { useLocalStore } from 'mobx-react';
import * as React from 'react';
import { isNotNullOrUndefined, stringNotEmpty as stringIsNotEmpty } from 'Util/TypeGuards';
import {
	CheckInBookingOverviewDto,
} from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';

interface IGroupHeaderState {
	show: boolean;
	disabled?: boolean;
}

export class GroupHeaderState implements IGroupHeaderState {
	constructor(state: IGroupHeaderState) {
		this.show = state.show;
		this.disabled = state.disabled;
	}

	@observable
	show: boolean;

	@observable
	disabled?: boolean;

	@action
	setShow(value: boolean) {
		this.show = value;
	}
}

export interface IBookingFormGroupHeaders {
	passengers: GroupHeaderState;
	vehicles: GroupHeaderState;
	trailers: GroupHeaderState;
	addOns: GroupHeaderState;
}

export function useGroupHeaders(booking: CheckInBookingOverviewDto) {
	const checkInStore = useCheckInStore();
	const initialGroupHeaders = React.useMemo<IBookingFormGroupHeaders>(() => {
		const disableVehicles = isNotNullOrUndefined(booking.bookedSummary?.cargoInfo);
		const showVehicles = disableVehicles || stringIsNotEmpty(checkInStore.formState?.cargoMake);

		const disableTrailers = isNotNullOrUndefined(booking.bookedSummary?.towOnInfo);
		const showTrailers = disableTrailers || stringIsNotEmpty(checkInStore.formState?.towOnTypeId);

		const showAddOns = booking
			.bookedSummary
			.additionalBookingOptions
			.some(x => x.quantity > 0);

		return {
			passengers: new GroupHeaderState({ show: true }),
			//
			// Disable vehicle section checkbox when booking initially has a vehicle
			// Cannot remove vehicle from vehicle booking
			//
			vehicles: new GroupHeaderState({ show: showVehicles, disabled: disableVehicles }),
			//
			// Trailers can be added both Add/Edit flows
			//
			trailers: new GroupHeaderState({ show: showTrailers }),
			addOns: new GroupHeaderState({ show: showAddOns }),
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		booking,
		booking.bookedSummary?.cargoInfo,
		booking.bookedSummary?.towOnInfo,
		booking.bookedSummary?.additionalBookingOptions,
	]);

	const groupHeaders = useLocalStore<IBookingFormGroupHeaders>(() => {
		// Important to do a deep copy, otherwise both groupHeader states will use the same object
		return {
			passengers: new GroupHeaderState({ ...initialGroupHeaders.passengers }),
			vehicles: new GroupHeaderState({ ...initialGroupHeaders.vehicles }),
			trailers: new GroupHeaderState({ ...initialGroupHeaders.trailers }),
			addOns: new GroupHeaderState({ ...initialGroupHeaders.addOns }),
		};
	});

	return [initialGroupHeaders, groupHeaders];
}

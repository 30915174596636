import React from 'react';
import { observer } from 'mobx-react';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { EventBookingWizardData } from '../../EventsBookingWizardData';
import {
	EventBookingPricesDto,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';
import { CartCalculationLineItem } from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/Cart/CartLineItems/CartCalculationLineItem';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import If from 'Views/Components/If/If';
import GiftUsageLines from './CartLineItems/GiftUsageLines';
import CreditCardFeeLine from './CartLineItems/CreditCardFeeLine';
import GstLine from './CartLineItems/GstLine';
import AddedFeeLines from '../../../FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/AddedFeeLines';
import { isNotNullOrUndefined } from 'Util/TypeGuards';

export interface EventBookingSummaryTotalProps {
	prices: EventBookingPricesDto | null;
	afterPayment: boolean;
	cartView: boolean;
	wizardData?: EventBookingWizardData;
	transactionId?: string;
	tripSummaryLocation: tripSummaryLocationType;
	onClearGiftCertificate: (code: string) => void;
	onClearFee?: (feeId: string) => void;
}

function EventBookingSummaryTotal({
	prices,
	afterPayment,
	cartView,
	wizardData,
	transactionId,
	tripSummaryLocation,
	onClearGiftCertificate,
	onClearFee,
}: EventBookingSummaryTotalProps) {
	const isCartView = cartView || afterPayment;

	return (
		<div className="total-booking-price">
			<CartCalculationLineItem
				keyToUse="event-subtotal-line-item-key"
				lineItemDisplayName={(cartView || afterPayment) ? 'Subtotal (inc. GST):' : 'TOTAL:'}
				lineItemPrice={formatPriceDisplay(
					prices?.subtotalPrice ?? 0,
					false,
				)}
				className={`${
					cartView || afterPayment ? 'subtotal-line-item' : 'total-line-item'
				}`}
			/>
			<If condition={isCartView}>
				<If condition={isNotNullOrUndefined(prices)}>
					<AddedFeeLines
						fees={prices?.serviceFees ?? []}
						onRemoveFee={onClearFee}
					/>
				</If>
				<GiftUsageLines
					giftCertificateUsages={prices?.giftCertificateUsages ?? []}
					onClearGiftCertificate={(code: string) => onClearGiftCertificate(code)}
				/>
				<If condition={tripSummaryLocation === 'booking-success' || tripSummaryLocation === 'check-in'}>
					<CreditCardFeeLine
						prices={prices}
					/>
				</If>
				<GstLine
					prices={prices}
				/>
				<CartCalculationLineItem
					keyToUse="event-total-line-item-key"
					lineItemDisplayName="TOTAL (inc. GST):"
					lineItemPrice={
						formatPriceDisplay(
							tripSummaryLocation === 'booking-success'
								|| tripSummaryLocation === 'check-in'
								? (prices?.totalPrice ?? 0) + (prices?.surchargePrice ?? 0)
								: prices?.totalPrice ?? 0,
							false,
						)
					}
					className="cart-total-line-item total-cart-line"
				/>
				{/* <If condition={store.isStaff && (tripSummaryLocation === 'cart' || tripSummaryLocation === 'check-in')}> */}
				{/* 	<Button */}
				{/* 		className="override-total-amount-button" */}
				{/* 		onClick={() => { */}
				{/* 			RenderAddServiceFeeModal( */}
				{/* 				subtotalBookingCost, */}
				{/* 				tripSummaryLocation, */}
				{/* 				wizardData?.wizardMode ?? 'CREATE', */}
				{/* 				transactionFees, */}
				{/* 				transactionId, */}
				{/* 			).then(async () => { */}
				{/* 				fetchTransactionFees(transactionId ?? ''); */}
				{/* 			}); */}
				{/* 		}} */}
				{/* 	> */}
				{/* 		Add service fee */}
				{/* 	</Button> */}
				{/* </If> */}
			</If>
		</div>
	);
}

export default observer(EventBookingSummaryTotal);

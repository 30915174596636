import { stringNotEmpty } from 'Util/TypeGuards';

/**
 * True when:
 *  - value is 3 characters long exactly
 *  - and each character is a number
 *
 * False otherwise.
 */
export function isCvcNumber(value: string): boolean {
	const regex = new RegExp(/^([0-9]{3})$/);
	return stringNotEmpty(value) && regex.test(value);
}

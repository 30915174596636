import { calculateTotalOneWayTicketPriceNewBooking } from './Creation_CalculateTotalOneWayTicketPrice';
import { BookingEntity, FerryTripEntity } from 'Models/Entities';
import { BookingWizardCartFields } from '../../../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * This function will calculate the total of all the new bookings.
 * @param bookingList: The list of bookings included in the calculation.
 * @param bookingToShow: The booking that will be used to show the trip cards when there is no wizard data.
 */
export const calculateCartSubtotalNewBooking = (
	bookingList: BookingWizardCartFields[],
	bookingToShow: BookingEntity | null = null,
) => {
	let runningTotal = 0;
	bookingList.forEach(booking => {
		const { wizardData } = booking;
		// If there is a departure ticket associated to the booking
		if (wizardData.departureTicketId !== '') {
			runningTotal += calculateTotalOneWayTicketPriceNewBooking(
				true,
				booking.wizardData,
				booking.selectedTrips.departingTrip as FerryTripEntity,
				bookingToShow,
			);
		}

		// If there is a returning ticket associated to the booking
		if (wizardData.tripType === 'return' && wizardData.returningTicketId !== '') {
			runningTotal += calculateTotalOneWayTicketPriceNewBooking(
				false,
				booking.wizardData,
				booking.selectedTrips.returningTrip as FerryTripEntity,
				bookingToShow,
			);
		}
	});

	return runningTotal;
};

import React from 'react';

import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { CameraFrameTopOffset } from '../Constants';
import { FrameDimensions } from '../Types/frameDimensions';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';

interface ScannerLoadingProps {
	image: string | undefined;
	marker: FrameDimensions;
	onResetClick(): void;
}

/**
 * Overlay image with loading spinner.
 */
export default function ScannerLoading({ image, marker, onResetClick }: ScannerLoadingProps) {
	if (image) {
		return (
			<div className="container">
				<img
					className="camera-frame"
					src={image}
					alt="captured image"
					style={{
						height: `${marker.height}rem`,
						width: `${marker.width}rem`,
						top: `${CameraFrameTopOffset}rem`,
					}}
				/>
				<LottieSpinner />
				<button className="close-btn" onClick={onResetClick}>
					<Icon name="cross" />
				</button>
			</div>
		);
	}

	return <></>;
}

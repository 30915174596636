import { BookingEntity, FerryTripEntity } from 'Models/Entities';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { PriceCalculationProps } from 'Util/_HumanWritten/PriceCalculations/Helpers/PriceCalculationProps';
import {
	calculateTotalOneWayTicketPriceNewBooking,
} from 'Util/_HumanWritten/PriceCalculations/CreationPriceCalculations/Creation_CalculateTotalOneWayTicketPrice';
import {
	calculateTotalOneWayTicketPriceAlterBooking,
} from 'Util/_HumanWritten/PriceCalculations/AlterationPriceCalculations/Alteration_CalculateTotalOneWayTicketPrice';
import { AdditionalOption } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * This function will calculate the total one way price for a booking.
 * This will include base ticket price, additional vehicle length,
 * additioanl trailer length and additional booking options.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param priceCalculationProps: The price calculation props needed for calculating the base price.
 * @param trip: The ferry trip to find the total one way ticket price from.
 * @param oldAdditionalOptions
 * @param afterPayment: If the calculation is being done after payment has been taken
 * @param bookingToEdit: The booking that will be used to show the trip cards when there is no wizard data.
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 * @param afterAlterationFeeAndPriceIncrease
 */
export const getTotalOneWayTicketPrice = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity,
	oldAdditionalOptions: AdditionalOption[] | null,
	afterPayment: boolean,
	bookingToEdit: BookingEntity | null = null,
	forEftpos = false,
	afterAlterationFeeAndPriceIncrease: boolean = true,
) => {
	/* Create mode */
	if (isNullOrUndefined(bookingToEdit) || isNullOrUndefined(bookingToEdit.id)) {
		return calculateTotalOneWayTicketPriceNewBooking(departureTrip, priceCalculationProps, trip);
	}

	/* Alteration mode */
	return calculateTotalOneWayTicketPriceAlterBooking(
		departureTrip,
		priceCalculationProps,
		trip,
		bookingToEdit,
		oldAdditionalOptions ?? [],
		afterPayment,
		forEftpos,
		afterAlterationFeeAndPriceIncrease,
	);
};

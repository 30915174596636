import { FerryTripEntity } from 'Models/Entities';
import { getFerryTripExpandString } from './FetchTripsUtils';
import { FetchSelectedFerryTrips } from '../../../Services/Api/_HumanWritten/FerryTripEntityService';

export async function FetchGivenFerryTrips(
	trips: string[],
	bookingToEditId: string | null = null,
	userId: string | null = null,
): Promise<FerryTripEntity[]> {
	try {
		const results = await FetchSelectedFerryTrips(trips, userId, bookingToEditId);
		return results.data.map((x: FerryTripEntity) => new FerryTripEntity(x));
	} catch (e) {
		console.error(e);
		return [];
	}
}

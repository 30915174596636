import React from 'react';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { FilteredTripCard } from './FilteredTripCard';
import { BookingEntity } from 'Models/Entities';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';

interface FilteredTripCardsProps {
	list: TicketsTabTrip[];
	wizardData: BookingWizardData;
	saveChanges: (newData: BookingWizardData) => void;
	departure: boolean;
	departureTripTime: Date | null;
	isStaff: boolean;
	locationInApp: tripSummaryLocationType;
	bookingToEdit: BookingEntity | null;
	refreshTrips?: () => void;
}

export const FilteredTripCards = (
	{
		list,
		wizardData,
		departure,
		saveChanges,
		departureTripTime,
		isStaff,
		bookingToEdit = null,
		locationInApp,
		refreshTrips,
	}: FilteredTripCardsProps,
) => {
	if (list.length > 0) {
		return (
			<>
				{
					list.filter(x => x.startDate).map((trip, index) => {
						return (
							<FilteredTripCard
								className="filtered-trip-card-tile"
								departure={departure}
								ticketsTabTrip={trip}
								index={index}
								key={trip.id}
								wizardData={wizardData}
								saveChanges={saveChanges}
								departureTripTime={departureTripTime}
								isStaff={isStaff}
								locationInApp={locationInApp}
								bookingToEdit={wizardData.wizardMode === 'ALTERATION' ? bookingToEdit : null}
								refreshTrips={refreshTrips}
							/>
						);
					})
				}
			</>
		);
	}
	return <div className="no-trips-available">No trips available</div>;
};

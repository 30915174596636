import React from 'react';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { CargoEntity } from 'Models/Entities';
import LatestVehiclesList from 'Views/Components/_HumanWritten/LatestVehiclesList/LatestVehiclesList';

export interface PreviousVehicleProps {
	previousVehicleList: CargoEntity[];
	wizardData: BookingWizardData;
	previousVehicle: { previous: boolean };
	saveChanges: (newData: BookingWizardData) => void;
	refresh: () => void;
	isCustomer: boolean;
	userId: string;
}

export function PreviousVehicle({
	userId,
	previousVehicleList,
	previousVehicle,
	wizardData,
	saveChanges,
	refresh,
	isCustomer,
}: PreviousVehicleProps) {
	const prefillVehicleInfo = (cargoInfo?: CargoEntity | null) => {
		if (cargoInfo !== undefined && cargoInfo !== null) {
			const newData = { ...wizardData };

			newData.cargoMake = cargoInfo.cargoType.cargoMake;
			newData.cargoModel = cargoInfo.cargoType.cargoModel;
			newData.vehicleLengthId = cargoInfo.selectedLengthId;
			newData.vehicleWeightId = cargoInfo.selectedWeightId;
			newData.cargoTypeId = cargoInfo.cargoType.id;
			newData.cargoIdentification = cargoInfo.cargoIdentification;
			newData.hiredVehicle = false;
			previousVehicle.previous = true;

			saveChanges(newData);
		}
	};

	return (
		<LatestVehiclesList
			userId={userId}
			onSelect={prefillVehicleInfo}
			refresh={refresh}
			previousVehicleList={previousVehicleList}
		/>
	);
}

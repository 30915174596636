import { getMinimumMeasurementFromStore } from '../../MeasurementUtils';
import { FerryTripEntity } from 'Models/Entities';
import { stringNotEmpty } from '../../../TypeGuards';
import { getWeightTypePriceFromFerryTrip } from '../../VehicleTrailerTicketTypeUtils';

/**
 * This function will return the weight price for a cargoType.
 * @param selectedWeightId: The vehicle type selected weightId.
 * @param trip: The ferry trip to find vehicle price difference from.
 */
export const calculateOneWayVehicleWeightPriceNewBooking = (
	trip: FerryTripEntity,
	selectedWeightId?: string,
): number => {
	const minWeight = getMinimumMeasurementFromStore('WEIGHT')?.id;
	if (stringNotEmpty(selectedWeightId)) {
		return getWeightTypePriceFromFerryTrip(
			trip,
			selectedWeightId,
		) - getWeightTypePriceFromFerryTrip(trip, minWeight);
	}

	return 0;
};

import { RefObject } from 'react';

/**
 * Draw an image on a canvas that is an exact replica of the video.
 */
export const captureWholeImage = (videoRef: RefObject<HTMLVideoElement>) => {
	if (!videoRef.current) {
		throw new Error('VideoRef does not exist');
	}

	// Capture the whole image from the video
	const canvas = document.createElement('canvas');
	canvas.width = videoRef.current.videoWidth;
	canvas.height = videoRef.current.videoHeight;
	const ctx = canvas.getContext('2d');
	ctx?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

	return canvas;
};

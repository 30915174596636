import {
	calculateCartSubtotalAlterBooking,
} from 'Util/_HumanWritten/PriceCalculations/AlterationPriceCalculations/Alteration_CalculateCartSubtotal';
import { BookingEntity } from 'Models/Entities';
import { isNullOrUndefined } from 'Util/TypeGuards';
import {
	calculateCartSubtotalNewBooking,
} from 'Util/_HumanWritten/PriceCalculations/CreationPriceCalculations/Creation_CalculateCartSubtotal';
import { BookingWizardCartFields } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';

/**
 * This function will calculate the total of all the new bookings.
 * @param bookings
 * @param afterPayment
 * @param tripSummaryLocation
 * @param useRecentSummary
 * @param bookingToEdit
 * @param forEftpos
 */
export const getCartSubtotal = (
	bookings: BookingWizardCartFields[],
	afterPayment: boolean,
	tripSummaryLocation: tripSummaryLocationType,
	useRecentSummary?: boolean,
	bookingToEdit: BookingEntity | null = null,
	forEftpos = false,
) => {
	if (bookings.length > 0) {
		/* Create mode */
		if (isNullOrUndefined(bookingToEdit) || isNullOrUndefined(bookingToEdit?.id)) {
			return calculateCartSubtotalNewBooking(bookings, bookingToEdit);
		}
		/* Alteration mode */
		return calculateCartSubtotalAlterBooking(
			bookings[0],
			bookingToEdit,
			useRecentSummary ?? true,
			afterPayment,
			forEftpos,
			tripSummaryLocation,
		);
	}
	return 0;
};

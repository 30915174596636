import React from 'react';

import { useScanner } from '../Stores/useScanner';
import { ScannerActionMap } from '../Types/scannerAction';
import { useDevAnalyse } from '../Hooks/useDevAnalyse';
import { TextDetectionDto } from '../Services/licensePlateService';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

/**
 * Download images on capture, and bulk analyse images for text detection.
 */
export default function DevModeButton() {
	const dispatch = useScanner(x => x.dispatch);
	const devMode = useScanner(x => x.devMode);
	const { handleMultiFileUpload } = useDevAnalyse();

	const [results, setResults] = React.useState<{ image: string; detectedText: TextDetectionDto[] }[]>([]);

	const onCloseClick = () => setResults([]);
	const onLoad = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const res = await handleMultiFileUpload(e);
		console.log(res);
		if (res) {
			setResults(res);
		}
	};

	if (results.length) {
		return (
			<div className="scanner-result page">
				<button className="close-btn--black" title="Back to scanner" onClick={onCloseClick}>
					<Icon name="cross" />
				</button>

				<div className="body">
					<div className="title">Results</div>
					<table>
						<tr>
							<th>image</th>
							<th>info</th>
						</tr>
						{results.map((result, index) => {
							return (
								<tr key={JSON.stringify(result)}>
									<td>
										<img src={result.image} alt={`Captured ${index}`} style={{ height: '5rem' }} />
									</td>
									<td>
										{result.detectedText.map(dto => (
											<>
												<div>
													{dto.detectedText} {dto.confidence}
												</div>
											</>
										))}
									</td>
								</tr>
							);
						})}
					</table>
				</div>
			</div>
		);
	}

	return (
		<>
			<button className="test-btn" onClick={() => dispatch({ action: ScannerActionMap.ToggleDevMode })}>
				Download mode: {devMode ? 'on' : 'off'}
			</button>
			<input className="test-btn-2" type="file" accept="image/*" multiple onChange={onLoad} />
		</>
	);
}

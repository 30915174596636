import { store } from 'Models/Store';
import { readLicensePlateWithConfidence } from '../Services/licensePlateService';

export const useDevAnalyse = () => {
	const handleMultiFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		// Only super can use this feature
		if (!store.isSuper) {
			return null;
		}

		if (event.target.files) {
			const files = Array.from(event.target.files);
			const promises = files.map(file => {
				return new Promise<string>((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						const dataUrl = reader.result?.toString() ?? '';
						resolve(dataUrl);
					};
					reader.onerror = error => reject(error);
				});
			});

			return Promise.all(promises).then(dataUrls => {
				console.log(dataUrls);
				const images: string[] = [];

				const readLicenses = dataUrls.map(dataUrl => {
					images.push(dataUrl);
					const base64 = dataUrl.split(',')[1];
					return readLicensePlateWithConfidence(base64);
				});

				return Promise.all(readLicenses).then(results => {
					return results.map((x, i) => ({
						image: images[i],
						detectedText: x,
					}));
				});
			});
		}
		return null;
	};

	return { handleMultiFileUpload };
};

import * as React from 'react';
import { useState } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import If from 'Views/Components/If/If';
import { store } from 'Models/Store';
import { alertModal, confirmModal } from 'Views/Components/Modal/ModalUtils';
import { BookingEntity } from 'Models/Entities';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { CustomBookingStatus } from 'Models/Entities/BookingEntity';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { isBeforeNow } from 'Util/_HumanWritten/GeneralHelpers/DateTimeHelpers';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import {
	CheckBookingCanBeModified,
	getBookingCancellationRules,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	markBookingAsInvoiced,
	undoBookingInvoiced,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { invoicedConfirmModal } from './Modals/InvoicedConfirmationModal';
import { ProcessResults } from './Helpers/EditCancelPopUp';
import { RenderCancelBookingModal } from './Modals/CancelBookingModal';

export interface BookingActionButtonsProps {
	booking: BookingEntity;
	canEdit: boolean;
	customStatus?: CustomBookingStatus;
	refresh: () => void;
	isEventBooking: boolean;
}

function BookingActionButtons({
	booking,
	canEdit,
	customStatus,
	refresh,
	isEventBooking,
}: BookingActionButtonsProps) {
	const [loading, setLoading] = useState(false);

	const canCancel = canEdit && canCancelBooking(booking);

	const changeInvoiceStatus = (setToInvoiced: boolean) => {
		invoicedConfirmModal(setToInvoiced).then(async () => {
			if (setToInvoiced) {
				markBookingAsInvoiced(booking.id)
					.then(action(x => {
						booking.isInvoiced = setToInvoiced;
						refresh();
					}));
			} else {
				undoBookingInvoiced(booking.id)
					.then(action(x => {
						booking.isInvoiced = setToInvoiced;
						refresh();
					}));
			}
		}).catch(() => {
			// do nothing because we are just closing the modal
		});
	};

	if (loading) {
		return <LottieSpinner />;
	}

	return (
		<ButtonGroup className="booking-actions__button-group" alignment={Alignment.VERTICAL}>
			<If condition={store.isAdmin || store.isManager}>
				{customStatus === 'AWAITING' && (
					<Button
						className="download-tax-invoice-button icon-file-text"
						type="button"
						sizes={Sizes.Medium}
						colors={Colors.Secondary}
						display={Display.Text}
						onClick={() => changeInvoiceStatus(true)}
					>
						Mark as invoiced
					</Button>
				)}
				{customStatus === 'INVOICED' && (
					<Button
						className="download-tax-invoice-button icon-file-text"
						type="button"
						sizes={Sizes.Medium}
						colors={Colors.Secondary}
						display={Display.Text}
						onClick={() => changeInvoiceStatus(false)}
					>
						Mark as not invoiced
					</Button>
				)}
			</If>
			{canCancel && (
				<Button
					className="cancel-booking-button icon-delete"
					type="button"
					sizes={Sizes.Medium}
					colors={Colors.Secondary}
					display={Display.Text}
					onClick={() => onCancel(booking, setLoading, isEventBooking, refresh, customStatus)}
				>
					Cancel this booking
				</Button>
			)}
		</ButtonGroup>
	);
}

export default observer(BookingActionButtons);

export const onCancel = async (
	booking: BookingEntity,
	setLoading: (loading: boolean) => void,
	isEventBooking: boolean,
	refresh: () => void, customStatus?: CustomBookingStatus,
) => {
	if (!isEventBooking) {
		const returnBookingInfo = booking.returnBooking ?? booking.returnBookingFor;

		const res = await CheckBookingCanBeModified(booking.id);
		const canContinueWithCancel = await ProcessResults(res, true);
		if (!canContinueWithCancel) {
			return;
		}

		let alterReturn = false; // it is better to assume a trip doesn't have a return ticket

		if (isNotNullOrUndefined(returnBookingInfo) && canCancelBooking(returnBookingInfo, booking) && !res.includes('desynced')) {
			confirmModal(
				'We noticed you have a returning trip...',
				<div>
					<h5>Do you want to cancel that booking too?</h5>
				</div>,
				{
					confirmText: 'Cancel both',
					cancelText: 'No, just this',
				},
			).then(() => {
				alterReturn = true;
			}).catch(() => {}).finally(async () => {
				const cancellationInfo = await getBookingCancellationRules(booking.id, alterReturn);

				setLoading(true);
				await RenderCancelBookingModal(
					booking.id,
					booking.user.firstName,
					booking.user.lastName,
					alterReturn,
					cancellationInfo.bookingCutoff ?? null,
					Number(cancellationInfo.recommendedRefundAmount.toFixed(2)),
					Number(cancellationInfo.maximumRefund.toFixed(2)),
					refresh,
				);
				setLoading(false);
			});
		} else {
			const cancellationInfo = await getBookingCancellationRules(booking.id, alterReturn);

			setLoading(true);
			await RenderCancelBookingModal(
				booking.id,
				booking.user.firstName,
				booking.user.lastName,
				alterReturn,
				cancellationInfo.bookingCutoff ?? null,
				Number(cancellationInfo.recommendedRefundAmount.toFixed(2)),
				Number(cancellationInfo.maximumRefund.toFixed(2)),
				refresh,
			);
			setLoading(false);
		}
	} else if (store.isStaff && isEventBooking) {
		setLoading(true);
		await RenderCancelBookingModal(
			booking.id,
			booking.user.firstName,
			booking.user.lastName,
			false,
			null,
			booking.bookingStatus === 'BOOKED' ? (booking?.bookedSummary?.totalCost ?? 0) : 0,
			booking.bookingStatus === 'BOOKED' ? (booking?.bookedSummary?.totalCost ?? 0) : 0,
			refresh,
		);
		setLoading(false);
	} else {
		alertModal(
			'Cancel event booking?',
			<div>
				<h5>Please contact staff to cancel this booking.</h5>
			</div>,
			{
				cancelText: 'Okay',
			},
		).finally(() => {
			if (!!(window as any).Intercom) {
				(window as any).Intercom('show');
			}
		});
	}
};

export const canCancelBooking = (bookingToCancel: BookingEntity, mainBooking?: BookingEntity) => {
	if (!bookingToCancel.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return;
	}

	return isNotNullOrUndefined(bookingToCancel)
		&& (!isBeforeNow(bookingToCancel.bookedSummary?.ferryTrip.departureDateTime) || store.isStaff)
		&& bookingToCancel.bookingStatus === 'BOOKED'
		&& (!bookingToCancel.checkedIn || isNullOrUndefined(bookingToCancel.checkedIn))
		&& (!mainBooking?.checkedIn || isNullOrUndefined(mainBooking?.checkedIn));
};

import axios from 'axios';
import { SERVER_URL } from '../../../Constants';

export interface BookingPriceOverrideDto {
	transactionId: string,
	discountedAmount: number,
	departingPercentage?: number,
	returningPercentage?: number,
}

// Add/update a staff discount on a transaction
export async function addManagerDiscount(bookingInfo: BookingPriceOverrideDto) {
	return axios.post(
		`${SERVER_URL}/api/entity/ManagerBookingDiscountEntity/add-manager-discount`,
		bookingInfo,
	).then(({ data }) => data);
}

// Remove a staff discount from a transaction
export async function removeManagerDiscount(bookingInfo: BookingPriceOverrideDto) {
	return axios.post(
		`${SERVER_URL}/api/entity/ManagerBookingDiscountEntity/remove-manager-discount`,
		bookingInfo,
	).then(({ data }) => data);
}

// Get total manager discount on transaction
export async function getTotalManagerDiscountFromTransaction(transactionId: string) {
	try {
		const url = `${SERVER_URL}/api/entity/ManagerBookingDiscountEntity/total-manager-discount/${transactionId}`;
		const response = await axios.get<number>(url);
		return response.data;
	} catch (e) {
		console.error(e);
		return 0;
	}
}

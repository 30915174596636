import React, { MouseEvent } from 'react';

import { ScannerMode, ScannerModeMap } from '../Types/scannerMode';

interface ScannerToggleButtonProps {
    mode: ScannerMode;
	onToggle(e?: MouseEvent<HTMLButtonElement>): void;
}

export default function ScannerToggleButton({ mode, onToggle }: ScannerToggleButtonProps) {
	return (
		<div className="switch-btn-anchor">
			<button className="switch-btn" onClick={onToggle}>
				Switch to {mode === ScannerModeMap.Rego ? 'TICKET' : 'REGO PLATE'}
			</button>
		</div>
	);
}

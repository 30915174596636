import { FerryTripEntity } from 'Models/Entities';
import { getLengthTypePriceFromFerryTrip } from '../../VehicleTrailerTicketTypeUtils';
import { getMinimumMeasurementTypeForFerryTrip } from '../../MeasurementUtils';

/**
 * This function will calculate the price difference between the base vehicle
 * length (up to 5.2m) and the user selected vehicle length on a ferry trip.
 * @param selectedVehicleLengthId: The user selected vehicle length Id.
 * @param trip: The ferry trip to find vehicle price difference from.
 * @param optionId?: The id of the drop-down menu option on vehicle tab.
 */
export const calculateOneWayVehicleLengthPriceNewBooking = (
	selectedVehicleLengthId: string,
	trip: FerryTripEntity,
	selectedWeightId?: string,
	optionId?: string,
): number => {
	const lengthToCalculateId = optionId === '' || optionId === undefined ? selectedVehicleLengthId : optionId;
	return getLengthTypePriceFromFerryTrip(trip, lengthToCalculateId)
		- getLengthTypePriceFromFerryTrip(trip, getMinimumMeasurementTypeForFerryTrip(trip)?.id);
};

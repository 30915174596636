import { AdditionalOption } from '../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * Gets the price of a single additional booking option. If run during a creation then oldOption will be null as it
 * does not exist yet. oldOption will also be null during an alteration if the user is adding an option which has not
 * previously been added to this booking
 *
 * The price of an additional option is defined as the change in the amount of that option which is being purchased,
 * multiplied by the price of the option. As a result, this number can be negative if an option has been removed
 *
 * @param option
 * @param oldOption
 */
export function getPriceOfOption(
	option: AdditionalOption,
	oldOption: AdditionalOption | null,
	forEftpos: boolean = false,
): number {
	let optionAmount = option.amount;

	if (oldOption !== null) {
		optionAmount -= oldOption.amount;
	}

	const price = optionAmount * option.optionPrice;

	if (price < 0 && forEftpos) {
		return 0;
	}

	return price;
}

/**
 * Gets the total price of additional options for a booking. For each option in the options array, if there exists an
 * equivalent option in the oldOptions, then the difference in their amounts is used for comparison. Otherwise we can
 * just use the amount in the option from the options array.
 *
 * This logic works for both creation and updates. For creation an empty array can be passed into the oldOptions
 * function and this will ensure that we use the amounts specified in the options array
 *
 * @param options
 * @param oldOptions
 */
export function getTotalPriceOfAdditionalOptions(
	options: AdditionalOption[],
	oldOptions: AdditionalOption[],
	forEftpos: boolean = false,
): number {
	let price = 0.0;

	options.forEach(option => {
		// oldOptions is an empty array if we are running in create mode. This allows us to use the same code for the
		// creation and alteration processes as creation will be attempting a find on an empty array, which will
		// produce null and then pass null to getPriceOfOption, which will tell that function that there is no old
		// option to compare against
		const associatedOldOption = oldOptions.find(x => x.optionId === option.optionId) ?? null;

		price += getPriceOfOption(option, associatedOldOption, forEftpos);
	});

	return price;
}

import React from 'react';

import { Button } from 'Views/Components/Button/Button';
import { useScannerLocation } from '../Hooks/useScannerLocation';
import { useScanner } from '../Stores/useScanner';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

/**
 * Only show search button when scanning attempt has been made.
 */
export default function SearchButton() {
	const { goToCheckIn } = useScannerLocation();
	const { attempts } = useScanner();

	const onSearch = () => goToCheckIn(true);

	if (attempts > 0) {
		return (
			<Button className="btn--text btn--primary search-btn" onClick={onSearch}>
				<Icon name="search" classname="check-in-list__search-icon" />
			</Button>
		);
	}

	return <></>;
}

import * as React from 'react';
import { store } from 'Models/Store';
import { NumberTextField } from '../../../../NumberTextBox/NumberTextBox';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from 'Views/Components/Button/Button';
import If from '../../../../If/If';
import alertToast from 'Util/ToastifyUtils';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import { addManagerDiscount } from 'Services/Api/_HumanWritten/ManagerBookingDiscountService';

export interface ManagerModalProps {
	totalBookingCost: number;
	totalAlterationFees: number;
	priceOverrideModal: {
		finalBookingCost: number,
		inputErrors: string,
	};
}

export const ManagerPriceOverrideModalContent = observer(({
	totalBookingCost,
	totalAlterationFees,
	priceOverrideModal,
}: ManagerModalProps) => {
	const totalWithoutAlterationFee = Number(totalBookingCost.toFixed(2)) - Number(totalAlterationFees.toFixed(2));
	return (
		<div className="manager-override-booking-modal-content">
			<h5>The total of this trip is <span><strong> ${totalBookingCost.toFixed(2)}</strong>. </span>
				Which includes a ${totalAlterationFees.toFixed(2)} alteration fee. New total without the fee is
				${totalWithoutAlterationFee.toFixed(2)}.
			</h5>
			<p className="input-label">New total</p>
			<div className="text-input-field">
				<Button
					className="dollar-icon"
					icon={{ icon: 'money', iconPos: 'icon-right' }}
				/>
				<NumberTextField
					className="price-override-textfield"
					id="price-override-textfield"
					model={priceOverrideModal}
					modelProperty="finalBookingCost"
					placeholder={totalWithoutAlterationFee.toFixed(2)}
					onAfterChange={action(() => { priceOverrideModal.inputErrors = ''; })}
				/>
			</div>
			<If condition={priceOverrideModal.inputErrors !== ''}>
				<p className="error-message">{priceOverrideModal.inputErrors}</p>
			</If>
		</div>
	);
});

export const RenderOverrideTotalModal = async (
	transactionId: string,
	totalBookingCost: number,
	alterationFees: number,
) => {
	const { isManager } = store;
	const totalWithoutAlterationFee = Number(totalBookingCost.toFixed(2)) - Number(alterationFees.toFixed(2));

	const priceOverrideModal = observable({
		finalBookingCost: Number(totalWithoutAlterationFee.toFixed(2)),
		inputErrors: '',
	});

	const modalTitle = 'Apply manager discount';
	const cancelButtonText = 'Close';
	const confirmButtonText = 'Update amount';

	const ObservableManagerModalContent = observer(() => {
		return (
			<ManagerPriceOverrideModalContent
				totalBookingCost={totalBookingCost}
				totalAlterationFees={alterationFees}
				priceOverrideModal={priceOverrideModal}
			/>
		);
	});
	const modalContent = <ObservableManagerModalContent />;

	const confirmed = await modalWithCustomInput(
		modalTitle,
		modalContent,
		{
			cancelText: cancelButtonText,
			confirmText: confirmButtonText,
			actionClassName: `price-override-modal ${isManager ? 'staff' : ''}`,
			resolveOnCancel: true,
			onConfirm: action(() => {
				if (priceOverrideModal.finalBookingCost === 0
					|| priceOverrideModal.finalBookingCost === Number(totalWithoutAlterationFee.toFixed(2))) {
					priceOverrideModal.inputErrors = '';
					return true;
				}

				priceOverrideModal.inputErrors = `Please enter $0 or $${totalWithoutAlterationFee.toFixed(2)}`;
				return false;
			}),
		},
	);

	if (!confirmed) {
		return;
	}

	try {
		const total = Math.abs(priceOverrideModal.finalBookingCost - totalBookingCost);
		await addManagerDiscount({
			transactionId: transactionId,
			discountedAmount: total,
		});

		return total;
	} catch (e) {
		alertToast('Unable to update booking cost', 'error');
		return null;
	}
};

import { BookingEntity, FerryTripEntity } from '../../../../Models/Entities';
import { isNotNullOrUndefined } from '../../../TypeGuards';
import {
	calculateOneWayVehicleWeightPriceAlterBooking,
} from '../AlterationPriceCalculations/Alteration_CalculationOneWayVehicleWeightPrice';
import {
	calculateOneWayVehicleWeightPriceNewBooking,
} from '../CreationPriceCalculations/Creation_CalculateOneWayVehicleWeightPrice';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';

/**
 * This function will return the selected vehicle weight
 * type's price from the user selected ferry trip.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param priceCalculationProps: The props needed for price calculations.
 * @param trip: The new ferry trip to find trailer price difference from.
 * @param bookingToEdit?: The booking that is being edited (if in alteration mode).
 * @param optionId: The length type option from the dropdown menu on the vehicle tab
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 */
export const getOneWayVehicleWeightPriceDifference = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity,
	bookingToEdit: BookingEntity | null = null,
): number => {
	// If wizard mode is ALTERATION
	if (isNotNullOrUndefined(bookingToEdit)
		&& isNotNullOrUndefined(bookingToEdit.id)
		&& isNotNullOrUndefined(priceCalculationProps.vehicleWeightId)) {
		return calculateOneWayVehicleWeightPriceAlterBooking(priceCalculationProps, trip, bookingToEdit);
	}
	// If wizard mode is CREATE
	if (isNotNullOrUndefined(priceCalculationProps.vehicleWeightId)) {
		return calculateOneWayVehicleWeightPriceNewBooking(trip, priceCalculationProps.vehicleWeightId);
	}
	return 0;
};

import { action, observable } from 'mobx';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import {
	FeeEntity,
	GiftCertificateUsageEntity,
	UserEntity,
} from 'Models/Entities';
import * as Enums from 'Models/Enums';
import {
	BookingCreationAdditionalOption,
	FerryBookingCreationDto,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';

export interface IBookingFormState extends FerryBookingCreationDto {
	email: string;
	cargoMake: string;
	cargoModel: string;
	user: UserEntity;
	giftCertificateUsages: GiftCertificateUsageEntity[];
	transactionFees: FeeEntity[];
}

export class BookingFormState implements IBookingFormState {
	@observable
	email: string;

	@observable
	cargoMake: string;

	@observable
	cargoModel: string;

	@observable
	user: UserEntity = new UserEntity();

	@observable
	giftCertificateUsages: GiftCertificateUsageEntity[] = [];

	@observable
	transactionFees: FeeEntity[] = [];

	@observable
	bookingToEdit?: CheckInBookingOverviewDto;

	@observable
	bookingId?: string;

	@observable
	userId: string;

	@observable
	tripId: string;

	@observable
	departingOptions: BookingCreationAdditionalOption[] = [];

	@observable
	returnTripId: string;

	@observable
	returningOptions: BookingCreationAdditionalOption[] = [];

	@observable
	adultPassengers: number;

	@observable
	childPassengers: number;

	@observable
	infantPassengers: number;

	@observable
	passengerCountD: number;

	@observable
	passengerCountE: number;

	@observable
	passengerCountF: number;

	@observable
	passengerCountG: number;

	@observable
	passengerCountH: number;

	@observable
	driverFirstName?: string;

	@observable
	driverLastName?: string;

	@observable
	driverPhone?: string;

	@observable
	licencePlate?: string;

	@observable
	hiredVehicle?: boolean;

	@observable
	cargoTypeId?: string;

	@observable
	selectedVehicleLengthId?: string;

	@observable
	selectedVehicleWeightId?: string;

	@observable
	selectedTrailerLengthId?: string;

	@observable
	towOnTypeId?: string;

	@observable
	existingTransactionId?: string;

	@observable
	bypassSpaceCheck: boolean;

	@observable
	note: string;

	@observable
	location: Enums.locationType;

	constructor(attributes?: Partial<IBookingFormState>) {
		this.assignAttributes(attributes);
	}

	@action
	private assignAttributes(attributes?: Partial<IBookingFormState>) {
		if (attributes === undefined) {
			return;
		}
		if (isNotNullOrUndefined(attributes.bookingId)) {
			this.bookingId = attributes.bookingId;
		}
		if (isNotNullOrUndefined(attributes.userId)) {
			this.userId = attributes.userId;
		}
		if (isNotNullOrUndefined(attributes.tripId)) {
			this.tripId = attributes.tripId;
		}
		if (isNotNullOrUndefined(attributes.departingOptions) && Array.isArray(attributes.departingOptions)) {
			this.departingOptions = attributes.departingOptions;
		}
		if (isNotNullOrUndefined(attributes.returnTripId)) {
			this.returnTripId = attributes.returnTripId;
		}
		if (isNotNullOrUndefined(attributes.returningOptions) && Array.isArray(attributes.returningOptions)) {
			this.returningOptions = attributes.returningOptions;
		}
		if (isNotNullOrUndefined(attributes.adultPassengers)) {
			this.adultPassengers = attributes.adultPassengers;
		}
		if (isNotNullOrUndefined(attributes.childPassengers)) {
			this.childPassengers = attributes.childPassengers;
		}
		if (isNotNullOrUndefined(attributes.infantPassengers)) {
			this.infantPassengers = attributes.infantPassengers;
		}
		if (isNotNullOrUndefined(attributes.passengerCountD)) {
			this.passengerCountD = attributes.passengerCountD;
		}
		if (isNotNullOrUndefined(attributes.passengerCountE)) {
			this.passengerCountE = attributes.passengerCountE;
		}
		if (isNotNullOrUndefined(attributes.passengerCountF)) {
			this.passengerCountF = attributes.passengerCountF;
		}
		if (isNotNullOrUndefined(attributes.passengerCountG)) {
			this.passengerCountG = attributes.passengerCountG;
		}
		if (isNotNullOrUndefined(attributes.passengerCountH)) {
			this.passengerCountH = attributes.passengerCountH;
		}
		if (isNotNullOrUndefined(attributes.driverFirstName)) {
			this.driverFirstName = attributes.driverFirstName;
		}
		if (isNotNullOrUndefined(attributes.driverLastName)) {
			this.driverLastName = attributes.driverLastName;
		}
		if (isNotNullOrUndefined(attributes.driverPhone)) {
			this.driverPhone = attributes.driverPhone;
		}
		if (isNotNullOrUndefined(attributes.licencePlate)) {
			this.licencePlate = attributes.licencePlate;
		}
		if (isNotNullOrUndefined(attributes.hiredVehicle)) {
			this.hiredVehicle = attributes.hiredVehicle;
		}
		if (isNotNullOrUndefined(attributes.cargoTypeId)) {
			this.cargoTypeId = attributes.cargoTypeId;
		}
		if (isNotNullOrUndefined(attributes.selectedVehicleLengthId)) {
			this.selectedVehicleLengthId = attributes.selectedVehicleLengthId;
		}
		if (isNotNullOrUndefined(attributes.selectedVehicleWeightId)) {
			this.selectedVehicleWeightId = attributes.selectedVehicleWeightId;
		}
		if (isNotNullOrUndefined(attributes.selectedTrailerLengthId)) {
			this.selectedTrailerLengthId = attributes.selectedTrailerLengthId;
		}
		if (isNotNullOrUndefined(attributes.towOnTypeId)) {
			this.towOnTypeId = attributes.towOnTypeId;
		}
		if (isNotNullOrUndefined(attributes.existingTransactionId)) {
			this.existingTransactionId = attributes.existingTransactionId;
		}
		if (isNotNullOrUndefined(attributes.email)) {
			this.email = attributes.email;
		}
		if (isNotNullOrUndefined(attributes.cargoMake)) {
			this.cargoMake = attributes.cargoMake;
		}
		if (isNotNullOrUndefined(attributes.cargoModel)) {
			this.cargoModel = attributes.cargoModel;
		}
		if (isNotNullOrUndefined(attributes.user)) {
			this.user = attributes.user;
		}
		if (isNotNullOrUndefined(attributes.bypassSpaceCheck)) {
			this.bypassSpaceCheck = attributes.bypassSpaceCheck;
		}
		if (isNotNullOrUndefined(attributes.note)) {
			this.note = attributes.note;
		}
	}

	public reset(): void {
		this.adultPassengers = 0;
		this.childPassengers = 0;
		this.infantPassengers = 0;
		this.passengerCountD = 0;
		this.passengerCountE = 0;
		this.passengerCountF = 0;
		this.passengerCountG = 0;
		this.passengerCountH = 0;
	}
}

/* eslint-disable dot-notation */
import {
	AlterationEntity,
	BookingEntity,
	FerryTripEntity,
} from 'Models/Entities';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import {
	filterBookedAlterationsOnly,
	filterReservedAndBookedAlterations,
	findIndexLast, getMostRecentAlteration,
	getMostRecentBookedAlterationWithFerryTrip,
	sortAlterationsByDateCreated,
} from './AlterationSortingUtils';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { PassengerTypeKey } from 'Models/Enums';
import { PriceCalculationProps } from 'Util/_HumanWritten/PriceCalculations/Helpers/PriceCalculationProps';
import { getOldFerryBookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/*
 *** Functions in this file ***
 * - getPassengerCountFromWizardData
 * - getPassengerPricePaidFromAlteration
 * - getPassengerPriceFromTrip
 * - getPassengerAlterationPriceMapping
 * - calculatePriceOfPassengerChangeAlteration
 * - getPassengersTotalPrice
 */

/**
 * This function will return the passenger count for a specific passenger ticket type
 * from the booking wizard data that is passed into the function.
 * @param priceCalculationProps: The price calculation props to get the passenger count from.
 * @param passengerTicketType: The passenger ticket type to get the count for.
 */
export const getPassengerCountFromWizardData = (
	priceCalculationProps: PriceCalculationProps,
	passengerTicketType: PassengerTypeKey,
) => {
	switch (passengerTicketType) {
		case 'A':
			return priceCalculationProps.adultsCount;
		case 'B':
			return priceCalculationProps.childrenCount;
		case 'C':
			return priceCalculationProps.infantCount;
		case 'D':
			return priceCalculationProps.passengerCountD;
		case 'E':
			return priceCalculationProps.passengerCountE;
		case 'F':
			return priceCalculationProps.passengerCountF;
		case 'G':
			return priceCalculationProps.passengerCountG;
		case 'H':
			return priceCalculationProps.passengerCountH;
	}
};

/**
 * This function will return the passenger price paid for a specific passenger
 * ticket type from the alteration that is passed into the function.
 * @param passengerTicketType: The passenger ticket type to get the price for.
 * @param alteration: The alteration to get the passenger price from.
 */
export const getPassengerPricePaidFromAlteration = (
	passengerTicketType: PassengerTypeKey,
	alteration: AlterationEntity,
) => {
	switch (passengerTicketType) {
		case 'A':
			return alteration.getPassengerTicket('A')?.price ?? 0;
		case 'B':
			return alteration.getPassengerTicket('B')?.price ?? 0;
		case 'C':
			return alteration.getPassengerTicket('C')?.price ?? 0;
		case 'D':
			return alteration.getPassengerTicket('D')?.price ?? 0;
		case 'E':
			return alteration.getPassengerTicket('E')?.price ?? 0;
		case 'F':
			return alteration.getPassengerTicket('F')?.price ?? 0;
		case 'G':
			return alteration.getPassengerTicket('G')?.price ?? 0;
		case 'H':
			return alteration.getPassengerTicket('H')?.price ?? 0;
	}
};

/**
 * This function will return price for a passenger ticket type from a ferry trip.
 * @param ferryTrip: The ferry trip to get the ticket price from.
 * @param passengerTicketType: The passenger ticket type to get the price for.
 */
export const getPassengerPriceFromTrip = (
	ferryTrip: FerryTripEntity | TicketsTabTrip,
	passengerTicketType: PassengerTypeKey,
) => {
	if (isNotNullOrUndefined(ferryTrip)) {
		switch (passengerTicketType) {
			case 'A':
				return ferryTrip['dynamicAdultPassengerPrice'] ?? ferryTrip.adultPassengerPrice;
			case 'B':
				return ferryTrip['dynamicChildPassengerPrice'] ?? ferryTrip.childPassengerPrice;
			case 'C':
				return ferryTrip['dynamicInfantPassengerPrice'] ?? ferryTrip.infantPassengerPrice;
			case 'D':
				return ferryTrip['dynamicPriceD'] ?? ferryTrip.priceD;
			case 'E':
				return ferryTrip['dynamicPriceE'] ?? ferryTrip.priceE;
			case 'F':
				return ferryTrip['dynamicPriceF'] ?? ferryTrip.priceF;
			case 'G':
				return ferryTrip['dynamicPriceG'] ?? ferryTrip.priceG;
			case 'H':
				return ferryTrip['dynamicPriceH'] ?? ferryTrip.priceH;
		}
	}
	return 0;
};

/**
 * This function returns a list of all the passenger ticket prices paid through all the alterations.
 * @param alterations: The alterations to get a list of passenger ticket prices from.
 * @param passengerTicketType: The passenger ticket type to get the prices for.
 */
export function getPassengerAlterationPriceMapping(
	alterations: AlterationEntity[],
	passengerTicketType: PassengerTypeKey,
): number[] {
	const results = [];
	if (isNullOrUndefined(alterations)) {
		return [];
	}
	const sortedAlts = sortAlterationsByDateCreated(alterations, true);
	const ascendingSortedAlterations = filterReservedAndBookedAlterations(sortedAlts);
	const mostRecentAlteration = getMostRecentAlteration(sortedAlts);

	let indexOfFerryChange = findIndexLast(
		sortAlterationsByDateCreated(ascendingSortedAlterations),
		alteration => alteration.ferryTripId !== mostRecentAlteration.ferryTripId,
	);
	if (indexOfFerryChange === -1) {
		indexOfFerryChange = 0;
	}

	const beforeFerryTripChangeAlterations = ascendingSortedAlterations.slice(0, indexOfFerryChange);
	const afterFerryTripChangeAlterations = ascendingSortedAlterations.slice(indexOfFerryChange);

	const totalPassengerTypeCount = getMostRecentAlteration(beforeFerryTripChangeAlterations)
		?.getPassengerTicketCount(passengerTicketType) ?? 0;

	for (let i = 0; i < afterFerryTripChangeAlterations.length; i++) {
		const alteration = afterFerryTripChangeAlterations[i];
		// eslint-disable-next-line max-len
		let passengerChange = alteration.getPassengerTicketCount(passengerTicketType) - (sortedAlts[sortedAlts.indexOf(alteration) - 1]?.getPassengerTicketCount(passengerTicketType) ?? 0);
		const passengerCost = getPassengerPricePaidFromAlteration(passengerTicketType, alteration);

		if (i === 0) {
			passengerChange += totalPassengerTypeCount;
		}

		if (passengerChange > 0) {
			for (let j = 0; j < passengerChange; j++) {
				results.push(passengerCost);
			}
		}

		if (passengerChange < 0) {
			for (let j = 0; j < Math.abs(passengerChange); j++) {
				results.pop();
			}
		}
	}
	return results;
}

/**
 * This function calculates the total passenger cost for a specific passenger ticket type.
 * @param priceCalculationProps: The price calculation props to get the passenger info from.
 * @param trip: The new user selected ferry trip (could be the same as old alteration if unchanged).
 * @param passengerTicketType: The ticket type that the calculations are for.
 * @param bookingToEdit: The booking that is being altered.
 * @param afterPayment: If the calculation is being done after payment has been taken
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 * @param includePriceIncrease
 * */
export const calculatePriceOfPassengerChangeAlteration = (
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity | TicketsTabTrip,
	passengerTicketType: PassengerTypeKey,
	bookingToEdit: BookingEntity,
	afterPayment: boolean,
	forEftpos = false,
	includePriceIncrease: boolean = true,
): number => {
	const oldBookingWizard = getOldFerryBookingWizardData();
	const filteredAlterations = sortAlterationsByDateCreated(
		filterReservedAndBookedAlterations(bookingToEdit?.alterations),
	);
	const oldFilteredAlterations = sortAlterationsByDateCreated(filterBookedAlterationsOnly(
		filteredAlterations,
	));
	const mostRecentFerryTripId = getMostRecentBookedAlterationWithFerryTrip(filteredAlterations)
		.ferryTripId;

	const isCargoBooking = isNotNullOrUndefined(filteredAlterations.find(x => x.hasCargo()));

	let oldListOfPrices:number[];
	let listOfPrices: number[];

	if (isNullOrUndefined(oldBookingWizard) && !filteredAlterations.some(x => x.status === 'RESERVED')) {
		const oldAlterations = filteredAlterations.slice(1, filteredAlterations.length);
		oldListOfPrices = getPassengerAlterationPriceMapping(
			oldAlterations,
			passengerTicketType,
		);
		listOfPrices = getPassengerAlterationPriceMapping(
			oldFilteredAlterations,
			passengerTicketType,
		);
	} else if (filteredAlterations.some(x => x.status === 'RESERVED')) {
		// If during the check-in flow, it will not have old booking wizard data, but will still have a reserved booking
		oldListOfPrices = getPassengerAlterationPriceMapping(
			oldFilteredAlterations,
			passengerTicketType,
		);
		listOfPrices = getPassengerAlterationPriceMapping(
			filteredAlterations,
			passengerTicketType,
		);
	} else {
		oldListOfPrices = getPassengerAlterationPriceMapping(
			oldFilteredAlterations,
			passengerTicketType,
		);
		listOfPrices = getPassengerAlterationPriceMapping(
			oldFilteredAlterations,
			passengerTicketType,
		);
	}

	let newPassengerCount: number = 0;
	let newPassengerCost: number;
	let oldPassengerCount: number = 0;
	let passengerDifference: number;
	if (isNotNullOrUndefined(priceCalculationProps) && isNotNullOrUndefined(oldBookingWizard)) {
		newPassengerCount = (getPassengerCountFromWizardData(
			priceCalculationProps,
			passengerTicketType,
		) ?? 0);
		newPassengerCost = getPassengerPriceFromTrip(trip, passengerTicketType);
		oldPassengerCount = (getPassengerCountFromWizardData(
			oldBookingWizard,
			passengerTicketType,
		) ?? 0);
		passengerDifference = newPassengerCount - oldPassengerCount;
	} else {
		newPassengerCost = getPassengerPriceFromTrip(trip, passengerTicketType);
		passengerDifference = 0;
	}

	if (passengerDifference < 0) {
		for (let i = 0; i < Math.abs(passengerDifference); i++) {
			listOfPrices.shift();
		}
	} else if (passengerDifference > 0) {
		for (let i = 0; i < passengerDifference; i++) {
			listOfPrices.push(newPassengerCost);
		}
	}

	// Only charge the price difference for passenger tickets if the ferry ticket was changed
	if (trip?.id !== mostRecentFerryTripId && includePriceIncrease) {
		for (let i = 0; i < listOfPrices.length; i++) {
			if (listOfPrices[i] < newPassengerCost) {
				listOfPrices[i] = newPassengerCost;
			}
		}
	}

	// Remove the one passenger that isn't paid for when booking a vehicle
	if (isCargoBooking && passengerTicketType === 'A' && whiteLabelStore.minAdultsForVehicle > 0) {
		oldListOfPrices.shift();
		listOfPrices.shift();
	}

	const oldPrice = oldListOfPrices.reduce((accumulator, current) => accumulator + current, 0);
	const newPrice = listOfPrices.reduce((accumulator, current) => accumulator + current, 0);

	const finalPrice = newPrice - oldPrice;

	if (forEftpos && finalPrice < 0) {
		return 0;
	}
	return finalPrice;
};

/**
 * This function calculates the total passenger cost (adult, children and infants).
 * @param priceCalculationProps: The price calculation props to get the passenger info from.
 * @param ferryTrip: The new user selected ferry trip (could be the same as old alteration if unchanged).
 * @param bookingToEdit: The booking that the user is altering.
 * @param afterPayment: If the calculation is being done after payment has been taken
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 * @param includePriceIncrease: Whether or not to include the price increases between two ferry tickets
 * */
export const getPassengersTotalPriceAlteration = (
	priceCalculationProps: PriceCalculationProps,
	ferryTrip: FerryTripEntity | TicketsTabTrip,
	bookingToEdit: BookingEntity,
	afterPayment: boolean,
	forEftpos: boolean = false,
	includePriceIncrease: boolean = true,
) => {
	const adultTotalPrice = calculatePriceOfPassengerChangeAlteration(
		priceCalculationProps,
		ferryTrip,
		'A',
		bookingToEdit,
		afterPayment,
		forEftpos,
		includePriceIncrease,
	);
	const childTotalPrice = calculatePriceOfPassengerChangeAlteration(
		priceCalculationProps,
		ferryTrip,
		'B',
		bookingToEdit,
		afterPayment,
		forEftpos,
		includePriceIncrease,
	);
	const infantTotalPrice = calculatePriceOfPassengerChangeAlteration(
		priceCalculationProps,
		ferryTrip,
		'C',
		bookingToEdit,
		afterPayment,
		forEftpos,
		includePriceIncrease,
	);

	const passengerDTotalPrice = calculatePriceOfPassengerChangeAlteration(
		priceCalculationProps,
		ferryTrip,
		'D',
		bookingToEdit,
		afterPayment,
		forEftpos,
		includePriceIncrease,
	);

	const passengerETotalPrice = calculatePriceOfPassengerChangeAlteration(
		priceCalculationProps,
		ferryTrip,
		'E',
		bookingToEdit,
		afterPayment,
		forEftpos,
		includePriceIncrease,
	);

	const passengerFTotalPrice = calculatePriceOfPassengerChangeAlteration(
		priceCalculationProps,
		ferryTrip,
		'F',
		bookingToEdit,
		afterPayment,
		forEftpos,
		includePriceIncrease,
	);

	const passengerGTotalPrice = calculatePriceOfPassengerChangeAlteration(
		priceCalculationProps,
		ferryTrip,
		'G',
		bookingToEdit,
		afterPayment,
		forEftpos,
		includePriceIncrease,
	);

	const passengerHTotalPrice = calculatePriceOfPassengerChangeAlteration(
		priceCalculationProps,
		ferryTrip,
		'H',
		bookingToEdit,
		afterPayment,
		forEftpos,
		includePriceIncrease,
	);

	return adultTotalPrice + childTotalPrice + infantTotalPrice + passengerDTotalPrice
		+ passengerETotalPrice + passengerFTotalPrice + passengerGTotalPrice + passengerHTotalPrice;
};

import { getMinimumMeasurementFromStore } from '../../MeasurementUtils';
import { FerryTripEntity } from 'Models/Entities';
import { isNotNullOrUndefined, stringNotEmpty } from '../../../TypeGuards';
import { getLengthTypePriceFromFerryTrip, getWeightTypePriceFromFerryTrip } from '../../VehicleTrailerTicketTypeUtils';

/**
 * This function will return the selected towOn length
 * type's price from the user selected ferry trip.
 * @param selectedTrailerType: The user selected towOn type.
 * @param trip: The ferry trip to find vehicle price difference from.
 * @param selectedTrailerLengthId: The user selected towOn length Id.
 * @param optionId?: The id of the drop-down menu option on vehicle tab.
 */
export const calculateOneWayTrailerLengthPriceNewBooking = (
	selectedTrailerTypeId: string,
	trip: FerryTripEntity,
	selectedTrailerLengthId?: string,
	optionId?: string,
): number => {
	let newLength;
	let newWeight;
	if (stringNotEmpty(selectedTrailerTypeId)) {
		newLength = optionId === '' || optionId === undefined ? selectedTrailerLengthId : optionId;
		newWeight = getMinimumMeasurementFromStore('WEIGHT')?.id;
	}
	const weightPrice = isNotNullOrUndefined(newWeight) ? getWeightTypePriceFromFerryTrip(trip, newWeight) : 0;
	const lengthPrice = isNotNullOrUndefined(newLength) ? getLengthTypePriceFromFerryTrip(trip, newLength) : 0;

	return weightPrice + lengthPrice;
};

import { FerryTripEntity } from 'Models/Entities';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../TypeGuards';
import { getLengthTypePriceFromFerryTrip, getWeightTypePriceFromFerryTrip } from '../../VehicleTrailerTicketTypeUtils';
import { getMeasurementValueFromId } from '../../MeasurementUtils';

export const FindVehicleTrailerDifferencePrice = (
	oldFerryTrip: FerryTripEntity,
	newFerryTrip: FerryTripEntity,
	newSelectedLengthId?: string,
	oldLengthId?: string,
	oldPricePaid?: number,
	oldWeightId?: string,
) => {
	const newLengthNewFerryTripPrice = isNotNullOrUndefined(newSelectedLengthId)
		? getLengthTypePriceFromFerryTrip(newFerryTrip, newSelectedLengthId) + getWeightTypePriceFromFerryTrip(
			newFerryTrip,
			oldWeightId,
		)
		: 0;
	const oldLengthNewFerryTripPrice = isNotNullOrUndefined(oldLengthId)
		? getLengthTypePriceFromFerryTrip(newFerryTrip, oldLengthId) + getWeightTypePriceFromFerryTrip(
			newFerryTrip,
			oldWeightId,
		)
		: 0;

	// Added towOn (can't add a cargo... once a cargo booking, always a cargo booking)
	if (isNullOrUndefined(oldLengthId) || oldPricePaid == null) {
		return newLengthNewFerryTripPrice;
	}

	// Removed towOn (can't remove a cargo... once a cargo booking, always a cargo booking)
	if (isNullOrUndefined(newSelectedLengthId)) {
		return -(oldPricePaid ?? 0);
	}

	// UNCHANGED FERRY TRIP - UNCHANGED LENGTH
	// Regardless if there is a price change due to dynamic pricing
	// we don't want the user to be charges that price difference
	// as they haven't made any changes to ferry trip tickets or lengths
	if (oldFerryTrip?.id === newFerryTrip?.id
		&& newSelectedLengthId === oldLengthId) {
		return 0;
	}

	// UNCHANGED FERRY TRIP - CHANGED LENGTH
	// Need to find the difference between the old price paid for
	// the cargo length and the new price for the new length
	if (oldFerryTrip?.id === newFerryTrip?.id
		&& newSelectedLengthId !== oldLengthId) {
		return newLengthNewFerryTripPrice - (oldPricePaid ?? 0);
	}

	// CHANGED FERRY TRIP - UNCHANGED LENGTH
	// This price difference is in the base ticket price
	// as we don't want users to know exactly what the price
	// increase is on a new ferry ticket
	if (oldFerryTrip?.id !== newFerryTrip?.id
		&& newSelectedLengthId === oldLengthId) {
		return 0;
	}

	// CHANGED FERRY TRIP - INCREASED LENGTH
	// When the length is increased the line item will only show the price
	// difference between the price of the new length on the new ferry trip
	// and the price of the old length on the new ferry trip
	// Note: The price difference between the old length old price and the
	// old length new price will be in the base ticket price and therefore
	// we don't want to double up on the same price
	if (oldFerryTrip?.id !== newFerryTrip?.id
		&& isNotNullOrUndefined(newSelectedLengthId)
		&& isNotNullOrUndefined(oldLengthId)
		&& getMeasurementValueFromId(newSelectedLengthId) > getMeasurementValueFromId(oldLengthId)) {
		if (newLengthNewFerryTripPrice <= oldPricePaid) {
			return 0;
		}
		if (oldPricePaid > oldLengthNewFerryTripPrice) {
			return newLengthNewFerryTripPrice - oldPricePaid;
		}
		return newLengthNewFerryTripPrice - oldLengthNewFerryTripPrice;
	}

	// CHANGED FERRY TRIP - DECREASED LENGTH
	// Calculations the price difference between the old length price on new ferry trip
	// and the new length price on new ferry trip to determine how much of a refund the user will get
	if (oldFerryTrip?.id !== newFerryTrip?.id
		&& isNotNullOrUndefined(newSelectedLengthId)
		&& isNotNullOrUndefined(oldLengthId)
		&& getMeasurementValueFromId(newSelectedLengthId) < getMeasurementValueFromId(oldLengthId)) {
		return newLengthNewFerryTripPrice - oldLengthNewFerryTripPrice;
	}
	return 0;
};

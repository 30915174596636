import React from 'react';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { BookingWizardData } from '../../BookingWizardData';
import usePassengerTypes from 'Hooks/usePassengerTypes';

export interface TripBreakdownDescriptionProps {
	wizardData: BookingWizardData;
}

export function TripBreakdownDescription({ wizardData }: TripBreakdownDescriptionProps) {
	usePassengerTypes();

	const description = evaluateDescription(wizardData);

	return <>{description}</>;
}

function evaluateDescription(wizardData: BookingWizardData): string {
	const description: string[] = [];

	// Vehicle detail
	if (wizardData.tabSelected === 'vehicle') {
		description.push(`1 ${whiteLabelStore.config.vehicleLabel}`);
	}

	// Passenger detail
	const passengerDetail = passengerTypeStore.getPassengerInfoFromWizardData(wizardData);

	if (passengerDetail !== '') {
		description.push(passengerDetail);
	}

	// Examples:
	// "1 vehicle"
	// "1 vehicle, 1 adult, ..."
	// "1 adult, ..."
	return description.join(', ').trim();
}

/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { store } from 'Models/Store';
import { RouteEntity } from 'Models/Entities';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import {
	BookingWizardData,
	getOldFerryBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import If from 'Views/Components/If/If';
import {
	FilteredTripCard,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/FilteredTripCard';
import {
	TripSelectionCalendar,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/TripSelectionCalendar';
import { isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { wizardModeOptions } from 'Models/Enums';
import { BookingEntity } from 'Models/Entities';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { Link } from 'react-router-dom';
import { showModal } from 'Views/Components/_HumanWritten/Modal/Base/BaseModalContents';
import { SingleActionModalContent } from 'Views/Components/_HumanWritten/Modal/Base/SingleActionModalContent';
import useStore from 'Hooks/useStore';
import { formatTripFilterInfo } from 'Util/_HumanWritten/BookingWizardDataUtils';
import { calculateTotalPassengers } from 'Util/_HumanWritten/CapacityCalculationUtils';
import {
	GetTicketsTabData,
	GetTicketsTabDataOneWay,
	TicketsTabStateData,
} from 'Services/Api/_HumanWritten/BookingWizardDataService';
import {
	isDateBeforeToday,
	isSameDate,
	isSameRoute,
} from 'Util/_HumanWritten/TicketSelectionValidationUtils';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';

export interface TicketSelectionTabProps {
	wizardData: BookingWizardData;
	saveChanges: (newData: BookingWizardData) => void;
	route: RouteEntity | null;
	selectedTrips: SelectedTrips;
	bookingToEdit: BookingEntity | null;
}

export function TicketSelectionTab({
	wizardData,
	saveChanges,
	route,
	selectedTrips,
	bookingToEdit = null,
}: TicketSelectionTabProps) {
	const { isIpad } = useDeviceDetect();
	const { isStaff, isManager } = useStore();

	const vehicleTrip = wizardData.tabSelected === 'vehicle';
	const oldWizardData = getOldFerryBookingWizardData();

	const [trips, setTrips] = useState<TicketsTabStateData>({
		departureTickets: null,
		returningTickets: null,
	});
	const currentStartDate = useRef(wizardData.startDate);
	const currentEndDate = useRef(wizardData.endDate);
	const currentFromLocation = useRef(wizardData.fromLocationId);
	const currentToLocation = useRef(wizardData.toLocationId);
	const firstRender = useRef(true);
	const currentBookingToEdit = useRef(wizardData.bookingToEdit);
	const onlyAlteringReturn = wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip === false;

	// Clear all vehicle data from the booking wizard if it is no longer a vehicle ticket
	// Or else the pricing will be affected
	useEffect(() => {
		if (!vehicleTrip) {
			const newData = { ...wizardData };
			newData.cargoTypeId = '';
			newData.cargoMake = '';
			newData.vehicleLengthId = '';
			newData.towOnTypeId = 'NO_TRAILER';
			newData.trailerLengthId = '';
			newData.departingTripOptions = [];
			newData.returningTripOptions = [];
			saveChanges(newData);
		}

		// We only want to execute this logic when the tabSelected value changes (vehicle or passenger)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wizardData.tabSelected]);

	useEffect(() => {
		if (store?.redirectedFromWaitlistEmail === true && trips.departureTickets !== null && trips.returningTickets !== null) {
			if (wizardData.departureTicketId === '') {
				showModal({
					key: 'waitlist-availability-modal',
					content: <SingleActionModalContent
						title="You've just missed out"
						body={<h5>Spaces fill up quick, sorry about that. You&apos;re still on the waitlist so we&apos;ll email you when space becomes available.</h5>}
						confirmContent="OK"
					/>,
				});
			} else {
				showModal({
					key: 'waitlist-availability-modal',
					content: <SingleActionModalContent
						title="Spaces available!"
						body={<h5>Good news - there is space available on this ferry, so we&apos;ve preselected your trip for you to book with ease.</h5>}
						confirmContent="Let's go"
					/>,
				});
			}
			store.setRedirectedFromWaitlistEmail(false);
		}
	}, [trips.departureTickets, trips.returningTickets]);

	const checkIfValidSelectedFerryTicket = () => {
		const newData = { ...wizardData };
		// Check if DEPARTURE ticket is still a valid selection
		// If there is a selected departure ticket AND either the route is not the same OR the amount of
		// passengers no longer fit on the trip, unselect the ticket
		if (newData.departureTicketId !== '' && selectedTrips.departingTrip !== undefined) {
			if (!isSameRoute(newData, selectedTrips, true)) {
				newData.departureTicketId = '';
			}

			let remainingDepartingCapacity = selectedTrips.departingTrip.passengerSpaceCapacity.remaining;
			if (newData.departureTicketId === oldWizardData?.departureTicketId
				&& isNotNullOrUndefined(remainingDepartingCapacity)) {
				remainingDepartingCapacity += calculateTotalPassengers(oldWizardData);
			}
			if (
				isNotNullOrUndefined(remainingDepartingCapacity)
				&& calculateTotalPassengers(newData) > remainingDepartingCapacity
				&& (!isStaff || (isStaff && !isDateBeforeToday(newData.startDate)))
				&& !isManager
			) {
				newData.departureTicketId = '';
			}
		}

		// Check if RETURN ticket is still a valid selection
		// If there is a selected return ticket AND either the route is not the same OR the amount of
		// passengers no longer fit on the trip, unselect the ticket
		if (newData.returningTicketId !== '' && selectedTrips.returningTrip !== undefined) {
			if (!isSameRoute(newData, selectedTrips, false)) {
				newData.returningTicketId = '';
			}

			let remainingReturningCapacity = selectedTrips.returningTrip.passengerSpaceCapacity.remaining;
			if (newData.returningTicketId === oldWizardData?.returningTicketId
				&& isNotNullOrUndefined(remainingReturningCapacity)) {
				remainingReturningCapacity += calculateTotalPassengers(oldWizardData);
			}
			if (
				isNotNullOrUndefined(remainingReturningCapacity)
				&& calculateTotalPassengers(newData) > remainingReturningCapacity
				&& (!isStaff || (isStaff && !isDateBeforeToday(newData.endDate)))
				&& !isManager
			) {
				newData.returningTicketId = '';
			}
		}

		// Saving the changes to the wizard data so that the cart is in sync.
		saveChanges(newData);
	};

	// This effect will un-select departing/returning ferry trips that are no longer valid
	// when the ferry filters are changed (route, vehicle/towOn and passengers only)
	useEffect(() => {
		checkIfValidSelectedFerryTicket();
	}, [
		saveChanges,
		selectedTrips.returningTrip,
		selectedTrips.departingTrip,
		wizardData.fromLocationId,
		wizardData.toLocationId,
		wizardData.departureTicketId,
		wizardData.returningTicketId,
		wizardData.adultsCount,
		wizardData.childrenCount,
		wizardData.infantCount,
		wizardData.passengerCountD,
		wizardData.passengerCountE,
		wizardData.passengerCountF,
		wizardData.passengerCountG,
		wizardData.passengerCountH,
	]);

	// This effect will un-select departing/returning ferry trips that are no longer valid
	// when the ferry filters are changed (start and end date only)
	useEffect(() => {
		const newData = { ...wizardData };
		// If DEPARTURE ticket date is not the same as start date, unselect the ticket
		// OR if altering the return ticket of a return trip ONLY,
		// then unselect the ticket if the date is not the same as the endDate
		if ((newData.departureTrip !== false
			&& newData.departureTicketId !== ''
			&& !isSameDate(wizardData, selectedTrips, true)
			&& selectedTrips.departingTrip?.startDate !== undefined)
		|| (newData.departureTrip === false
				&& !(moment(selectedTrips.departingTrip?.startDate).format('YYYY-MM-DD')
				=== moment(wizardData.endDate).format('YYYY-MM-DD'))
			&& selectedTrips.departingTrip?.startDate !== undefined)
		) {
			newData.departureTicketId = '';
		}

		// If DESTINATION ticket date is not the same as end date, unselect the ticket
		if (newData.returningTicketId !== '' && selectedTrips.returningTrip !== undefined
			&& !isSameDate(wizardData, selectedTrips, false)
		) {
			newData.returningTicketId = '';
		}
		// Saving the changes to the wizard data so that the cart is in sync.
		saveChanges(newData);
	}, [
		saveChanges,
		wizardData.startDate,
		wizardData.endDate,
	]);

	// This effect will un-select a returning trip if a departing trip that leaves after it is selected
	useEffect(() => {
		const newData = { ...wizardData };

		if (newData.departureTicketId !== '' && selectedTrips.departingTrip !== undefined
				&& newData.returningTicketId !== '' && selectedTrips.returningTrip !== undefined) {
			if (selectedTrips.departingTrip.departureDateTime >= selectedTrips.returningTrip.departureDateTime) {
				newData.returningTicketId = '';
				saveChanges(newData);
			}
		}
	}, [
		saveChanges,
		selectedTrips.departingTrip,
		selectedTrips.returningTrip,
		wizardData.departureTicketId,
		wizardData.returningTicketId,
	]);

	// There are 3 use effects here for fetching trips. The reason for this is because it is better for efficiency, as
	// we only fetch both directions if we need to fetch both directions, and if we only need one directions trips to
	// change then we just fetch that direction.
	// We fetch both directions of trips if:
	//  * Either of the locations have changed (all trips we currently have are irrelevant
	//  * BOTH the start and end date have changed. There was problems with state updates if we fetched each individually
	//    when both needed to be fetched, and it also meant we were sending two requests when we only needed one
	// We fetch one direction if only one out of start/end date has changed.
	// We are keeping track of what has changed using the useRef fields. They will keep track of the previous state of
	// the data so we will know when they were updated
	const updateTrips = () => {
		setTrips({
			departureTickets: null,
			returningTickets: null,
		});
		GetTicketsTabData(
			wizardData.fromLocationId,
			wizardData.toLocationId,
			wizardData.startDate,
			wizardData.endDate,
			wizardData.bookingToEdit ?? null,
			store.isStaff,
			store.isStaff ? wizardData.userId : store.userId,
		).then(data => {
			setTrips({
				departureTickets: data.departureTickets,
				returningTickets: data.returningTickets,
			});
		}).catch(_ => {
			setTrips({
				departureTickets: [],
				returningTickets: [],
			});
		});
	};

	useEffect(() => {
		if (!onlyAlteringReturn && (
			firstRender.current
			|| wizardData.fromLocationId !== currentFromLocation.current
			|| wizardData.toLocationId !== currentToLocation.current
			|| (wizardData.startDate !== currentStartDate.current && wizardData.endDate !== currentEndDate.current)
			|| wizardData.bookingToEdit !== currentBookingToEdit.current
		)
		) {
			updateTrips();
			currentStartDate.current = wizardData.startDate;
			currentEndDate.current = wizardData.endDate;
			currentFromLocation.current = wizardData.fromLocationId;
			currentToLocation.current = wizardData.toLocationId;
			currentBookingToEdit.current = wizardData.bookingToEdit;
		}
	}, [wizardData.fromLocationId, wizardData.toLocationId, wizardData.startDate, wizardData.endDate, wizardData.bookingToEdit]);

	useEffect(() => {
		if (!firstRender.current && currentStartDate.current !== wizardData.startDate) {
			const newTripState = { ...trips };
			newTripState.departureTickets = null;
			setTrips(newTripState);

			GetTicketsTabDataOneWay(
				wizardData.fromLocationId,
				wizardData.toLocationId,
				wizardData.startDate,
				true,
				wizardData.bookingToEdit ?? null,
				store.isStaff,
				store.isStaff ? wizardData.userId : store.userId,
			).then(newTrips => {
				const newState = { ...trips };
				newState.departureTickets = newTrips;
				setTrips(newState);
			}).catch(_ => {
				const newState = { ...trips };
				newState.departureTickets = [];
				setTrips(newState);
			});
			currentStartDate.current = wizardData.startDate;
		}
	}, [wizardData.startDate]);

	useEffect(() => {
		if ((!firstRender.current && currentEndDate.current !== wizardData.endDate) || (onlyAlteringReturn && firstRender.current)) {
			const newTripState = { ...trips };
			newTripState.returningTickets = null;
			setTrips(newTripState);

			GetTicketsTabDataOneWay(
				wizardData.fromLocationId,
				wizardData.toLocationId,
				wizardData.endDate,
				onlyAlteringReturn,
				wizardData.bookingToEdit ?? null,
				store.isStaff,
				store.isStaff ? wizardData.userId : store.userId,
			).then(newTrips => {
				const newState = { ...trips };
				if (onlyAlteringReturn) {
					newState.departureTickets = newTrips;
					newState.returningTickets = [];
				} else {
					newState.returningTickets = newTrips;
				}
				setTrips(newState);
			}).catch(_ => {
				const newState = { ...trips };
				newState.returningTickets = [];
				setTrips(newState);
			});
			currentEndDate.current = wizardData.endDate;
		}
	}, [wizardData.endDate]);

	// Previously there was effect code here to preselect a trip based on the query parameters. this is not needed in
	// the new version of the code so the effect has been removed

	useEffect(() => {
		firstRender.current = false;
	}, []);

	const selectedDepartingTrip = trips.departureTickets?.find(x => x.id === wizardData.departureTicketId);
	const selectedReturningTrip = trips.returningTickets?.find(x => x.id === wizardData.returningTicketId);

	// This will handle the scenario where the user clicks the book now button on a trip that
	if (
		stringIsEmpty(wizardData.bookingToEdit)
		&& !isManager
		&& selectedDepartingTrip !== undefined
		&& (
			(wizardData.tabSelected === 'vehicle' && (selectedDepartingTrip?.vehicleSpacesAvailable ?? 0) < 1)
		|| (wizardData.tabSelected === 'passenger' && (selectedDepartingTrip?.passengerSpacesAvailable ?? 0) === 0)
		)) {
		const newData = { ...wizardData };
		newData.departureTicketId = '';
		// selectedDepartingTrip = undefined;
		saveChanges(newData);
	}

	return (
		<div className="booking-wizard-tab ticket-selection-tab-container">
			<h2 className="booking-wizard__tab-header ticket-tab-text">
				<If condition={wizardData.wizardMode === wizardModeOptions.CREATE}>
					Select your ferry tickets
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION && wizardData.departureTrip !== undefined}>
					{wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip ? 'Edit your departing ferry ticket' : 'Edit your returning ferry ticket'}
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION && wizardData.departureTrip === undefined}>
					Edit your ferry tickets
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION}>
					<div>
						<Link to={`/bookings/${wizardData?.bookingToEdit ?? ''}`}>
							<Icon name="cross" classname="icon" />
						</Link>
					</div>
				</If>
			</h2>
			<div className="ticket-tab__info inline-button-container ticket-tab-text">
				<If condition={isIpad}>
					<p className="ticket-tab__info__text">
						For {
							formatTripFilterInfo(wizardData)
						}
					</p>
					<Button
						className="edit-filter-button"
						colors={Colors.Alternate}
						display={Display.Text}
						onClick={() => store.routerHistory.push('/booking-wizard/search')}
					>
						Edit
					</Button>
				</If>
			</div>
			<h4 className="ticket-tab__sub-header ticket-tab-text">
				{wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip === false ? 'Returning' : 'Departing'} Trip
			</h4>
			<If condition={!isNullOrUndefined(route)}>
				<h6 className="ticket-tab-text sub-text">
					{route?.departure?.name} to {route?.destination?.name},&nbsp;
					{moment(wizardData.wizardMode === 'ALTERATION'
					&& wizardData.departureTrip === false
						? wizardData.ticketSelectionEndDate
						: wizardData.ticketSelectionStartDate)
						.format('DD MMM YYYY')}
				</h6>
			</If>
			<If condition={isNullOrUndefined(selectedDepartingTrip) && wizardData.departureTicketId === ''}>
				<TripSelectionCalendar
					wizardData={wizardData}
					ferryTrips={trips.departureTickets}
					departure={wizardData.departureTrip !== false}
					saveChanges={saveChanges}
					selectedTrips={selectedTrips}
					isStaff={isStaff}
					bookingToEdit={wizardData.wizardMode === 'ALTERATION' ? bookingToEdit : null}
					refreshTrips={updateTrips}
				/>
			</If>
			<If condition={isNotNullOrUndefined(selectedDepartingTrip) && wizardData.departureTicketId !== ''}>
				<FilteredTripCard
					className="selected-trip-card-tile"
					departure={wizardData.departureTrip !== false}
					ticketsTabTrip={selectedDepartingTrip!}
					index={0}
					key={wizardData.departureTicketId}
					wizardData={wizardData}
					saveChanges={saveChanges}
					departureTripTime={null}
					isStaff={isStaff}
					bookingToEdit={wizardData.wizardMode === 'ALTERATION' ? bookingToEdit : null}
					locationInApp="sidebar"
				/>
			</If>
			<If condition={isNullOrUndefined(selectedDepartingTrip) && wizardData.departureTicketId !== ''}>
				<div className="spinner-container">
					<LottieSpinner className="relative" />
				</div>
			</If>
			<If condition={wizardData.tripType === 'return'}>
				<div className="ticket-tab__sub-header return-booking inline-button-container ticket-tab-text">
					<h4 className="">Returning Trip</h4>
					<If condition={wizardData.wizardMode !== 'ALTERATION'}>
						<Button
							className="edit-filter-button"
							colors={Colors.Red}
							display={Display.Text}
							onClick={() => {
								const newData = { ...wizardData };
								newData.tripType = 'one way';
								newData.returningTicketId = '';
								saveChanges(newData);
							}}
						>
							Remove
						</Button>
					</If>
				</div>
				<h6 className="ticket-tab-text sub-text">
					{route?.destination?.name} to {route?.departure?.name},&nbsp;
					{moment(wizardData.ticketSelectionEndDate).format('DD MMM YYYY')}
				</h6>
				<If condition={isNullOrUndefined(selectedReturningTrip) && wizardData.returningTicketId === ''}>
					<TripSelectionCalendar
						wizardData={wizardData}
						ferryTrips={trips.returningTickets}
						departure={false}
						saveChanges={saveChanges}
						selectedTrips={selectedTrips}
						isStaff={isStaff}
						bookingToEdit={wizardData.wizardMode === 'ALTERATION' ? bookingToEdit : null}
						refreshTrips={updateTrips}
					/>
				</If>
				<If condition={isNotNullOrUndefined(selectedReturningTrip) && wizardData.returningTicketId !== ''}>
					<FilteredTripCard
						className="selected-trip-card-tile"
						departure={false}
						ticketsTabTrip={selectedReturningTrip!}
						index={1}
						key={wizardData.returningTicketId}
						wizardData={wizardData}
						saveChanges={saveChanges}
						departureTripTime={selectedDepartingTrip?.startDate ?? null}
						isStaff={isStaff}
						bookingToEdit={wizardData.wizardMode === 'ALTERATION' ? bookingToEdit : null}
						locationInApp="sidebar"
					/>
				</If>
				<If condition={isNullOrUndefined(selectedReturningTrip) && wizardData.returningTicketId !== ''}>
					<div className="spinner-container">
						<LottieSpinner className="relative" />
					</div>
				</If>
			</If>
		</div>
	);
}

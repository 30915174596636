import { store } from '../../Models/Store';
import { FerryTripEntity } from 'Models/Entities';
import { measurementType } from '../../Models/Enums';
import { isNotNullOrUndefined, isNullOrUndefined } from '../TypeGuards';
import { TicketsTabTrip } from '../../Services/Api/_HumanWritten/BookingWizardDataService';

export const getMinimumMeasurementTypeForFerryTrip = (
	ferryTrip?: FerryTripEntity | TicketsTabTrip | undefined,
	measurementOption: measurementType = 'LENGTH',
) => {
	if (isNullOrUndefined(ferryTrip)) {
		// This is a fallback for bulk bookings as there are multiple ferry trips associated to them.
		// Assumption: all ferry trips should have the same length/weight types associated to them.
		const measurements = store?.measurements;
		const storeMeasurements = measurements
			?.filter(x => x.measurementType === measurementOption)
			?.sort((m1, m2) => (m1.value > m2.value ? 1 : -1))[0];
		return storeMeasurements;
	}
	const filteredMeasurements = findAllMeasurementsForFerryTrip(ferryTrip, measurementOption);
	// Sort smallest measurement value to largest to find the base measurement type/price
	return filteredMeasurements?.sort((m1, m2) => (m1.value > m2.value ? 1 : -1))[0];
};

export const getMinimumMeasurementFromStore = (measurementOption: measurementType = 'LENGTH') => {
	return store?.measurements
		?.filter(x => x.measurementType === measurementOption)
		?.sort((m1, m2) => (m1.value > m2.value ? 1 : -1))[0];
};

export const findAllMeasurementsForFerryTrip = (
	ferryTrip: FerryTripEntity | TicketsTabTrip | undefined,
	measurementOption?: measurementType,
) => {
	const listOfMeasurementIds: string[] = [];
	if (isNullOrUndefined(ferryTrip)) {
		// This is a fallback for bulk bookings as there are multiple ferry trips associated to them.
		// Assumption: all ferry trips should have the same length/weight types associated to them.
		let measurements = store?.measurements;
		if (measurementOption) {
			measurements = measurements?.filter(x => x.measurementType === measurementOption);
		}
		return measurements;
	}

	// Find all measurement types associated to the ferry trip
	ferryTrip.prices?.forEach(x => listOfMeasurementIds.push(x.measurementId));

	const listOfMeasurements = store?.measurements?.filter(x => listOfMeasurementIds.includes(x.id));

	// If a measurement type (weight or length) is specified, filter the list
	if (isNotNullOrUndefined(measurementOption)) {
		return listOfMeasurements
			?.sort((x, y) => x.value > y.value ? 1 : -1)
			?.filter(x => x.measurementType === measurementOption);
	}

	return listOfMeasurements?.sort((x, y) => x.value > y.value ? 1 : -1);
};

export const getMeasurementLabelFromId = (measurementId: string) => {
	const measurement = store?.measurements?.find(x => x.id === measurementId);
	if (isNullOrUndefined(measurement)) {
		return '';
	}
	return measurement.label;
};

export const getMeasurementValueFromId = (measurementId: string, isTrailer: boolean = false) => {
	const measurement = store?.measurements?.find(x => x.id === measurementId);
	if (isNullOrUndefined(measurement)) {
		return isTrailer ? 1 : 0;
	}
	return measurement.value;
};

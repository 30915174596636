import * as React from 'react';
import classNames from 'classnames';
import useCheckInStore from 'Hooks/useCheckInStore';
import { Flex } from 'Views/Components/Flex/Flex';
import If from 'Views/Components/If/If';
import { Text } from 'Views/Components/Text/Text';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { isNotNullOrUndefined, isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { getMeasurementLabelFromId, getMeasurementValueFromId } from 'Util/_HumanWritten/MeasurementUtils';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { IBookingFormState } from '../context/BookingFormState';
import addOnStore from 'Models/AddOnStore';
import CheckInListRowPassengers from './CheckInListRowPassengers';
import { CheckInListRowDetail } from './CheckInListRowDetail';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { CheckInOrUndoButton } from '../CheckInButtons/CheckInOrUndoButton';
import { CheckInCountType, useSortSettings } from '../CheckInView';
import { Tag } from './Tag';
import {
	CheckInSorter,
	getAddOnsToShow,
	isAnyAddOnsToShow,
	transformMobileNumber,
	transformRego,
	transformTrailerSpace,
	transformVehicleMakeModel,
	transformVehicleSpace,
} from '../CheckInUtils';

export interface CheckInListRowProps {
	booking: CheckInBookingOverviewDto;
	showVehicles?: boolean;
	forCheckIn?: boolean;
	onClick?: (booking: CheckInBookingOverviewDto) => void;
}

export function GetModalContentForCheckIn(booking?: CheckInBookingOverviewDto) {
	if (isNullOrUndefined(booking)) {
		return '';
	}
	const message = [];

	// Passengers
	const passengers = passengerTypeStore.getPassengerInfoFromBookingSummary(
		booking.bookedSummary ?? null,
		true,
	);
	if (stringNotEmpty(passengers)) {
		message.push(<p><strong>Passengers:</strong> {passengers}</p>);
	}

	// Vehicle
	const cargo = booking.bookedSummary?.cargoInfo;
	if (isNotNullOrUndefined(cargo)) {
		// eslint-disable-next-line max-len
		message.push(<p><strong>Vehicle:</strong> {`${cargo?.selectedLength?.label} (${cargo?.selectedLength?.value})`}</p>);
	}

	// Trailer
	const towOn = booking.bookedSummary?.towOnInfo;
	if (isNotNullOrUndefined(towOn)) {
		// eslint-disable-next-line max-len
		message.push(<p><strong>Trailer:</strong> {`${towOn?.selectedLength?.label} (${towOn?.selectedLength?.value})`}</p>);
	}

	// Add ons
	const addOns = booking.bookedSummary?.additionalBookingOptions;

	if (isNotNullOrUndefined(addOns) && addOns.length > 0) {
		addOns?.map(x => {
			const name = x.option.name.toLowerCase();
			return message.push((
				<p>
					<span><strong>{x.quantity}</strong></span>
					{` ${x.quantity > 1 ? toPlural(name) : name}`}
				</p>
			));
		});
	}

	return message;
}

export function GetModalContentForNewBookingCheckIn(formState: IBookingFormState) {
	if (isNullOrUndefined(formState)) {
		return '';
	}
	const message = [];
	// Passengers
	const passengers = passengerTypeStore.getPassengerInfoFromFormState(formState, true);
	if (stringNotEmpty(passengers)) {
		message.push(<p><strong>Passengers:</strong> {passengers}</p>);
	}
	// Vehicle
	const vehicle = formState.cargoTypeId;
	if (isNotNullOrUndefined(vehicle) && formState.selectedVehicleLengthId) {
		// eslint-disable-next-line max-len
		message.push(<p><strong>Vehicle:</strong> {`${getMeasurementLabelFromId(formState.selectedVehicleLengthId)} (${getMeasurementValueFromId(formState.selectedVehicleLengthId)})`}</p>);
	}
	// Trailer
	const trailer = stringNotEmpty(formState.towOnTypeId);
	if (trailer && formState.selectedTrailerLengthId) {
		// eslint-disable-next-line max-len
		message.push(<p><strong>Trailer:</strong> {`${getMeasurementLabelFromId(formState.selectedTrailerLengthId)} (${getMeasurementValueFromId(formState.selectedTrailerLengthId)})`}</p>);
	}

	// Add ons
	const addOns = formState.departingOptions;

	if (isNotNullOrUndefined(addOns) && addOns.length > 0) {
		addOns
			?.filter(x => x.amount > 0)
			?.map(x => {
				const addOn = addOnStore.findAddOn(x.optionId);
				const name = addOn?.name.toLowerCase() ?? '';
				return message.push((
					<p>
						<span><strong>{x.amount}</strong></span>
						{` ${x.amount > 1 ? toPlural(name) : name}`}
					</p>
				));
			});
	}

	return message;
}
export function CheckInListRow(props: CheckInListRowProps) {
	const {
		booking,
		showVehicles = false,
		forCheckIn = false,
		onClick,
	} = props;

	const {
		countType,
		cargoSorting,
		passengerSorting,
	} = useSortSettings(x => ({
		countType: x.countType,
		cargoSorting: x.cargoSorting,
		passengerSorting: x.passengerSorting,
	}));

	const checkInStore = useCheckInStore();

	const onCheckIn = (e: any) => {
		e.stopPropagation();
		if (!booking.checkedIn) {
			confirmModal(
				'Confirm booking?',
				GetModalContentForCheckIn(booking),
				{},
			).then(() => {
				checkInStore.checkInBooking(booking.id, forCheckIn);
			}).catch(() => {});
		} else {
			checkInStore.checkInBooking(booking.id, forCheckIn);
		}
	};

	const onCardClick = (e: any) => {
		if (onClick) {
			onClick(booking);
		}
	};

	return (
		<Flex className={classNames('check-in-list__row', { 'checked-in': !forCheckIn })} onClick={onCardClick}>
			<Flex direction="col" flex={1} gap="xxxs" className="p-sm">
				<Flex gap="xxs">
					<Text
						weight={getFontWeight(showVehicles
							? cargoSorting
							: passengerSorting,
						[CheckInSorter.Fullname, CheckInSorter.FullnameDesc, CheckInSorter.Default])}
						color={getFontColour(showVehicles
							? cargoSorting
							: passengerSorting,
						[CheckInSorter.Fullname, CheckInSorter.FullnameDesc, CheckInSorter.Default])}
						size="sm"
						wordBreak="break-all"
						className="flex-wrap"
					>
						<span className="mr-xxs fullname">
							{booking.getFullName()}
						</span>
						<If condition={booking.firstTimeOnRoute}>
							<Tag
								text="NEW"
								tagClassName="tag--new"
							/>
						</If>
						<If condition={isNotNullOrUndefined(booking?.user?.userDisplayName)}>
							<Tag
								text={booking?.user?.userDisplayName?.displayName}
								customStyles={{ background: booking?.user?.userDisplayName?.tagColour }}
							/>
						</If>
						<If condition={isAnyAddOnsToShow(booking)}>
							{
								getAddOnsToShow(booking)
									?.map(addOn => {
										return (
											<Tag
												text={stringNotEmpty(addOn.option.abbreviation)
													? addOn.option.abbreviation
													: addOn.option.name}
												customStyles={{ background: addOn?.option?.colour }}
											/>
										);
									})
							}
						</If>
					</Text>
				</Flex>
				<Text size="sm" color="gray">
					<If condition={showVehicles}>
						<span
							className={`text ${
								getFontColour(showVehicles
									? cargoSorting
									: passengerSorting,
								[CheckInSorter.Rego])
							}`}
							style={{
								fontWeight: getFontWeight(showVehicles
									? cargoSorting
									: passengerSorting,
								[CheckInSorter.Rego]),
							}}
						>
							{transformRego(booking)}
						</span>
					</If>
					<If condition={showVehicles}>
						<span className="mr-xxs ml-xxs">|</span>
						<span
							className={`vehicle-make-model text ${
								getFontColour(showVehicles
									? cargoSorting
									: passengerSorting,
								[CheckInSorter.VehicleTypeDesc, CheckInSorter.VehicleType])
							}`}
							style={{
								fontWeight: getFontWeight(showVehicles
									? cargoSorting
									: passengerSorting,
								[CheckInSorter.VehicleTypeDesc, CheckInSorter.VehicleType]),
							}}
						>
							{transformVehicleMakeModel(booking)}
						</span>
					</If>
					<If condition={!showVehicles}>
						<span className="mobile">{transformMobileNumber(booking)}</span>
					</If>
				</Text>
				<Flex gap="sm" rowGap="none" flexWrap="wrap" className="icons-no-height">
					<If condition={showVehicles}>
						<CheckInListRowDetail
							label={(
								<div className={`${whiteLabelStore.vehicleIconDarkClassName} ${getFontColour(
									showVehicles
										? cargoSorting
										: passengerSorting,
									[CheckInSorter.Vehicle, CheckInSorter.VehicleDesc],
								)}`}
								/>
							)}
							value={transformVehicleSpace(
								booking,
								countType === CheckInCountType.WeightCount,
							) ?? 0}
							className="row-detail-vehicle"
						/>
						<If condition={whiteLabelStore.config.trailersEnabled}>
							<CheckInListRowDetail
								label={<Icon name="chevron-trailer" classname="m-none" />}
								value={transformTrailerSpace(
									booking,
									countType === CheckInCountType.WeightCount,
								) ?? 0}
								className="row-detail-trailer"
								color={getFontColour(
									showVehicles
										? cargoSorting
										: passengerSorting,
									[CheckInSorter.Trailer, CheckInSorter.TrailerDesc],
								)}
							/>
						</If>
					</If>
				</Flex>
				<Flex gap="sm">
					<CheckInListRowPassengers booking={booking} />
				</Flex>
			</Flex>
			<Flex direction="col" justify="center" className="mr-sm">
				<CheckInOrUndoButton
					forCheckIn={forCheckIn}
					iconOnly
					className={forCheckIn ? 'check-in-list__row__btn-green' : 'check-in-list__row__btn-grey'}
					onClick={onCheckIn}
				/>
			</Flex>
		</Flex>
	);
}

function toPlural(text: string): string {
	if (!!!text || text.length === 0) {
		return '';
	}

	if (text.charAt(text.length - 1).toLowerCase() === 's') {
		return `${text}es`;
	}

	return `${text}s`;
}

function getFontWeight(checkInSorter: CheckInSorter, matchingSorters: CheckInSorter[]) {
	if (matchingSorters.includes(checkInSorter)) {
		return '600';
	}
	return 'normal';
}

function getFontColour(checkInSorter: CheckInSorter, matchingSorters: CheckInSorter[]) {
	if (matchingSorters.includes(checkInSorter)) {
		return 'high-emphasis';
	}
	return 'grey-7';
}

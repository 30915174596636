import { BookingEntity, FerryTripEntity } from '../../../../Models/Entities';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';
import { calculateBasePriceNewBooking } from './Creation_CalculateBasePrice';
import { stringNotEmpty } from '../../../TypeGuards';
import { getTotalPriceOfAdditionalOptions } from '../../AdditionalBookingOptionsUtils';
import { getOneWayVehicleLengthPriceDifference } from '../PriceFunctionSelectors/GetOneWayVehicleLengthPriceDifference';
import { getOneWayVehicleWeightPriceDifference } from '../PriceFunctionSelectors/GetOneWayVehicleWeightPriceDifference';
import { getOneWayTrailerLengthPriceDifference } from '../PriceFunctionSelectors/GetOneWayTrailerLengthPriceDifference';
import { getTruncatedPrice } from '../../PriceUtils';

/**
 * This function will calculate the total one way price for a new booking.
 * This will include base ticket price, additional vehicle length,
 * additional towOn length and additional booking options.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param priceCalculationProps: The price calculation props needed for calculating the base price.
 * @param trip: The ferry trip to find the total one way ticket price from.
 * @param booking: The booking that will be used to show the trip cards when there is no wizard data.
 */
export const calculateTotalOneWayTicketPriceNewBooking = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity,
	booking: BookingEntity | null = null,
): number => {
	let calculatedPrice = 0.0;

	if (trip === undefined) {
		return 0.0;
	}

	// Calculate the base ticket price (passengers and base vehicle length price)
	const newTicketBasePrice = calculateBasePriceNewBooking(departureTrip, priceCalculationProps, trip);

	// Check if there is a vehicle or towOn associated to the booking
	const vehicle = priceCalculationProps.tabSelected === 'vehicle';
	const towOn = stringNotEmpty(priceCalculationProps.trailerLengthId)
		&& (priceCalculationProps.towOnTypeId !== 'NO_TRAILER' || priceCalculationProps.trailerCheckboxSelected);
	const sumOfAdditionalItems = getTotalPriceOfAdditionalOptions(
		departureTrip ? priceCalculationProps.departingTripOptions : priceCalculationProps.returningTripOptions,
		[],
	);

	calculatedPrice = newTicketBasePrice
		+ sumOfAdditionalItems
		+ (vehicle
			? (getOneWayVehicleLengthPriceDifference(
				departureTrip,
				priceCalculationProps,
				trip,
				booking,
			) + getOneWayVehicleWeightPriceDifference(departureTrip, priceCalculationProps, trip, booking))
			: 0)
		+ (towOn
			? (getOneWayTrailerLengthPriceDifference(
				departureTrip,
				priceCalculationProps,
				trip,
				booking,
			))
			: 0);

	return getTruncatedPrice(calculatedPrice, true);
};

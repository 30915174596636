import { getTruncatedPrice } from '../../PriceUtils';
import { BookingWizardCartFields } from '../../../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * This function will calculate the GST included in the subtotal.
 * This will not include GST exclusive additional booking options.
 * @param totalCartPrice: The price to calculate the GST on.
 * @param gstFreePrice: The price that does not include GST.
 * @param gstMultiplier: The GST percentage.
 */
export const getGST = (
	totalCartPrice: number,
	gstFreePrice: number,
	gstMultiplier: number,
): number => {
	if (totalCartPrice <= 0) {
		return 0;
	}

	const totalForGst = totalCartPrice - gstFreePrice;
	const gstSubtotal = totalForGst / (1 + gstMultiplier);
	return getTruncatedPrice(Math.abs(totalForGst - gstSubtotal), true);
};

/**
 * This function will determine the price of the booking that is exempt from GST.
 * @param bookings
 */
export function getGstExemptPrice(
	bookings: BookingWizardCartFields[],
): number {
	let exemptPrice: number = 0;

	bookings.forEach(x => {
		// The wizard data we get on the booking success page can have duplicates, so we need to ensure that within a
		// given array we only process one of the entries for each option id. This is also why the departing and
		// returning is separate
		const departingTripIds: string[] = [];
		x.wizardData.departingTripOptions.forEach(y => {
			if (!departingTripIds.includes(y.optionId)) {
				const optionTotalPrice = y.optionPrice * y.amount;
				if (y.gstExempt && optionTotalPrice >= 0) {
					exemptPrice += optionTotalPrice;
				}
				departingTripIds.push(y.optionId);
			}
		});

		const returningTripIds: string[] = [];
		x.wizardData.returningTripOptions.forEach(z => {
			if (!returningTripIds.includes(z.optionId)) {
				const optionTotalPrice = z.optionPrice * z.amount;
				if (z.gstExempt && optionTotalPrice >= 0) {
					exemptPrice += optionTotalPrice;
				}
			}
			returningTripIds.push(z.optionId);
		});
	});

	return exemptPrice;
}

/**
 * True when:
 *  - card number is 16 characters long exactly
 *  - and each character is a number
 *
 * False otherwise.
 */
export function isCardNumberLength(value: string): boolean {
	const regex = new RegExp(/^([0-9]{16})$/);
	return regex.test(value);
}

/**
 * True when:
 *  - card number starts with 4 or 5
 *
 * False otherwise.
 */
export function isCardNumberInitial(value: string): boolean {
	return (value.charAt(0) === '4' || value.charAt(0) === '5');
}

/**
 * True when:
 *  - value starts with a valid month
 *  - and followed by a slash and then a 2 digit number
 *  - e.g. 12/23
 *
 * False otherwise.
 */
export function isCardNumberExpiryDate(value: string): boolean {
	// I was considering whether I should get the regex to check whether the year started with a 2 or a 20, but I
	// decided against it. The potential issue is that it would stop working once expiry dates reach 2100 (Quite
	// unlikely the site will still be around at that point but we might as well be sure) and we are introducing another
	// check to ensure that the date is in the future so the 21st century validation is ultimately unnecessary.
	// Months don't change so it is safe to mandate in the regex that the month is valid
	const regex = new RegExp(/^(01|02|03|04|05|06|07|08|09|10|11|12)(\/)([0-9]{2})$/);
	return regex.test(value);
}

import axios from 'axios';
import Axios, { AxiosResponse } from 'axios';
import { SERVER_URL } from 'Constants';
import saveAs from 'file-saver';
import { FerryTripEntity } from 'Models/Entities';
import {
	FerryTripImportResponse,
	IFerryTripImportResponse,
} from 'Models/_HumanWritten/FerryTripImport/FerryTripImportResponse';
import { buildUrl } from 'Util/FetchUtils';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { CommunicationTabContactsCount } from 'Models/Entities/FerryTripEntity';
import { stringIsEmpty } from '../../../Util/TypeGuards';

const FERRY_TRIP_API_URL = `${SERVER_URL}/api/entity/FerryTripEntity`;

export interface OverbookingSpaceResult {
	isOverbooking: boolean;
	cargoSpaceRemaining: number;
	cargoSpaceOverbookingAmount: number;
	passengerSpaceRemaining: number;
	passengerSpaceOverbookingAmount: number;
}

/**
 * @param file A csv file with a list of ferry trip details.
 */
export function validateFerryTripCsv(file: File): Promise<FerryTripImportResponse> {
	const formData = new FormData();
	//
	// Set file as the value of key 'csvFile' (the name of the parameter in the endpoint)
	//
	formData.append('csvFile', file);
	return Axios.post<IFerryTripImportResponse>(
		`${FERRY_TRIP_API_URL}/validate-import`,
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		},
	).then(res => {
		// Add 1 to all lines because serverside based the line number from 0
		res.data.errors = res.data.errors.map(x => ({ ...x, line: x.line + 1 }));
		return new FerryTripImportResponse(res.data);
	});
}

/**
 * @param file A csv file with a list of ferry trip details.
 */
export function saveFerryTripCsv(file: File): Promise<FerryTripImportResponse> {
	const formData = new FormData();
	//
	// Set file as the value of key 'csvFile' (the name of the parameter in the endpoint)
	//
	formData.append('csvFile', file);
	formData.append('continueImport', 'false');
	return Axios.post<IFerryTripImportResponse>(
		`${FERRY_TRIP_API_URL}/import`,
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		},
	).then(res => {
		// Add 1 to all lines because serverside based the line number from 0
		res.data.errors = res.data.errors.map(x => ({ ...x, line: x.line + 1 }));
		return new FerryTripImportResponse(res.data);
	});
}

export async function downloadSampleFerryTripCsv(): Promise<void> {
	window.open(
		`${FERRY_TRIP_API_URL}/sample-csv`,
		'_blank',
	);
}

export async function generateVehiclePassengerManifest(ferryTrip: FerryTripEntity): Promise<void> {
	// one of the ways to download two csv files on one click
	const [vehicleManifest, passengerManifest] = await Promise.all([
		axios.get(buildUrl(`${FERRY_TRIP_API_URL}/vehicle-manifest`, {
			Id: ferryTrip.id,
		})),
		axios.get(buildUrl(`${FERRY_TRIP_API_URL}/passenger-manifest`, {
			Id: ferryTrip.id,
		})),
	]);
	const vehicleBlobData = new Blob([vehicleManifest.data], { type: 'text/plain; charset=utf-8' });
	const passengerBlobData = new Blob([passengerManifest.data], { type: 'text/plain; charset=utf-8' });

	// extract the date and time to generate the file name
	const a = vehicleManifest.data.toString().split(',');
	const date = a[2].split('\r')[0].split(' ').join('-');
	const time = a[3].split(':').join('-');
	// eslint-disable-next-line no-useless-concat
	const vehicleFileName = `${`${whiteLabelStore.config.vehicleLabel}Manifest` + '('}${date}_${time}).csv`;
	// eslint-disable-next-line no-useless-concat
	const passengerFileName = `${'PassengerManifest' + '('}${date}_${time}).csv`;
	await saveAs(vehicleBlobData, vehicleFileName);
	await saveAs(passengerBlobData, passengerFileName);
}

/**
 * Returns true if the booking's new vehicle + trailer length and passenger count will fit in booking's
 * assigned ferry trip, based on ferry capacity.
 *
 * Returns false if it will exceed vehicle or passenger capacity.
 *
 * Note the passenger capacity's remaining spaces (not include OverbookingAllowance).
 *
 * Returns null if error occured.
 * @param bookingId The related booking where the passenger space checking is applied to it's assigned ferry trip.
 * @param newLength The predicted total vehicle and trailer length for the booking.
 * @param newPassengerCount The predicted total passengers for the booking.
 */
export async function bookingCanFit(bookingId: string, newLength: number, newPassengerCount: number) {
	try {
		const response = await Axios.post<any, AxiosResponse<OverbookingSpaceResult>>(
			buildUrl(`${FERRY_TRIP_API_URL}/booking-can-fit`, {
				bookingId: bookingId,
				newLength: newLength.toString(),
				newPassengerCount: newPassengerCount.toString(),
			}),
		);

		return response.data as OverbookingSpaceResult;
	} catch (e) {
		console.error(e);
		return null;
	}
}

/**
 * Returns true if successfully deleted else false
 * @param tripId ferry trip id to be deleted
 * @returns
 */
export async function disableFerryTrip(tripId: string) {
	try {
		const response = await Axios.post<any, AxiosResponse<boolean>>(
			buildUrl(`${FERRY_TRIP_API_URL}/ferry-trip-disable`, {
				tripId: tripId,
			}),
		);

		return response.data;
	} catch (e) {
		console.error(e);
		return null;
	}
}

/**
 * Returns the count for the mobile numbers and email numbers
 * @param tripId ferry trip id to get the contact counts from.
 * @returns tripContactCount (smsCount and emailCount)
 */
export async function getTripContactCount(tripId: string) {
	try {
		const response = await Axios.get<any, AxiosResponse<CommunicationTabContactsCount>>(
			buildUrl(`${FERRY_TRIP_API_URL}/ferry-trip-contact-count`, {
				tripId: tripId,
			}),
		);

		return response.data;
	} catch (e) {
		console.error(e);
		return null;
	}
}

/**
 * Opens/Closes a ferry trip from online bookings by customers.
 * @param tripId ferry trip id to open/close.
 * @returns boolean of whether or not it was successful
 */
export async function toggleFerryTripOpenState(tripId: string) {
	try {
		const response = await Axios.get<any, AxiosResponse<boolean>>(
			buildUrl(`${FERRY_TRIP_API_URL}/toggle-trip-open-state`, {
				tripId: tripId,
			}),
		);

		return response.data;
	} catch (e) {
		console.error(e);
		return null;
	}
}

export function fetchFilteredFerryTrips(
	startDate: string,
	endDate: string,
	routeId: string,
	userId: string | null,
) {
	const res = axios.get(
		buildUrl(`${SERVER_URL}/api/entity/FerryTripEntity/fetch-trips-for-schedule`, {
			startDate,
			endDate,
			routeId,
			userId: userId ?? '',
		}),
	);
	return res;
}

export function clearFerryTripCache() {
	const res = axios.get(
		buildUrl(`${SERVER_URL}/api/entity/FerryTripEntity/clear-ferry-trip-cache`),
	);
	return res;
}

export function FetchSelectedFerryTrips(
	tripIds: string[],
	userId: string | null = null,
	bookingToEditId: string | null = null,
) {
	const res = axios.get(
		buildUrl(`${SERVER_URL}/api/entity/FerryTripEntity/fetch-selected-ferry-trips`,
			{
				tripIds: tripIds.join(','),
				userId: stringIsEmpty(userId) ? 'null' : userId,
				bookingToEditId: stringIsEmpty(bookingToEditId) ? 'null' : bookingToEditId,
			}),
	);
	return res;
}

import { calculateBasePriceNewBooking } from '../CreationPriceCalculations/Creation_CalculateBasePrice';
import { BookingEntity, FerryTripEntity } from '../../../../Models/Entities';
import { TicketsTabTrip } from '../../../../Services/Api/_HumanWritten/BookingWizardDataService';
import { isNotNullOrUndefined } from '../../../TypeGuards';
import { calculateBasePriceAlterBooking } from '../AlterationPriceCalculations/Alteration_CalculateBasePrice';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';

/**
 * This function will calculate the minimum ticket price from a list of ferry trips.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param priceCalculationProps: The props needed for price calculations.
 * @param trip: The ferry trip to get the pricing from.
 * @param afterPayment: If the calculation is being done after payment has been taken
 * @param bookingToEdit: The booking that is being edited (if in alteration mode).
 * Note: This is used in the TICKET SELECTION tab in the booking wizard.
 * It shows in the tab header to show the user the minimum ticket price for a day.
 * The minimum price is based on user selections (e.g. passengers).
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 * @param priceIncreasesIncluded
 */
export const getBaseFerryTicketPriceForFerryTrip = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity | TicketsTabTrip,
	afterPayment: boolean,
	bookingToEdit: BookingEntity | null = null,
	forEftpos = false,
	priceIncreasesIncluded: boolean = true,
) => {
	/* Alteration mode */
	if (isNotNullOrUndefined(bookingToEdit) && isNotNullOrUndefined(bookingToEdit.id)) {
		return calculateBasePriceAlterBooking(
			departureTrip,
			priceCalculationProps,
			trip,
			bookingToEdit,
			afterPayment,
			forEftpos,
			priceIncreasesIncluded,
		);
	}

	/* Create mode */
	return calculateBasePriceNewBooking(departureTrip, priceCalculationProps, trip);
};

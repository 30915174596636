import React, { useEffect } from 'react';

import { useAbortController } from 'Hooks/useAbortController';
import { useInterval } from 'Hooks/useInterval';
import { SecuredGroups } from 'Models/Security/UserGroups';
import alertToast from 'Util/ToastifyUtils';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import SecuredRender from 'Views/Components/_HumanWritten/SecuredRender/SecuredRender';
import { CameraFrameTopOffset } from '../Constants';
import { useCamera } from '../Hooks/useCamera';
import { useCameraFrame } from '../Hooks/useCameraFrame';
import { useScannerLocation } from '../Hooks/useScannerLocation';
import { useTicket } from '../Hooks/useTicket';
import { useScanner } from '../Stores/useScanner';
import { ScannerActionMap } from '../Types/scannerAction';
import { ScannerModeMap } from '../Types/scannerMode';
import { ScannerResultState } from '../Types/scannerResultState';
import { captureHorizontallyCentered } from '../Utils/captureHorizontallyCentered';
import CameraFrame from './CameraFrame';
import DevModeButton from './DevModeButton';
import RetryMessage from './RetryMessage';
import ScannerLoading from './ScannerLoading';
import ScannerToggleButton from './ScannerToggleButton';
import SearchButton from './SearchButton';

/**
 * This component handles scanning for QR codes and Cargo Ids (e.g. rego plates).
 */
export default function Scanner() {
	// Stops promises from getting completed when user has decided to exit scanner
	const abortControllerRef = useAbortController();

	const { goToCheckIn, goToScannerResult } = useScannerLocation();

	const mode = useScanner(x => x.mode);
	const image = useScanner(x => x.image);
	const dispatch = useScanner(x => x.dispatch);
	const frame = useCameraFrame(mode);

	const { processTicket } = useTicket();

	const { videoRef, capture } = useCamera({
		capturer: canvas => captureHorizontallyCentered(canvas, frame.width, frame.height, CameraFrameTopOffset),
		onCaptureSuccess: canvas => {
			const abortController = new AbortController();
			abortControllerRef.current = abortController;
			if (mode === ScannerModeMap.QrCode) {
				dispatch({
					action: ScannerActionMap.ScanQrCode,
					canvas,
					onSuccess(result: string) {
						stop();
						processTicket(result).finally(start);
					},
					abortSignal: abortController.signal,
				});
			} else {
				dispatch({
					action: ScannerActionMap.ScanCargoId,
					canvas,
					onSuccess(result: ScannerResultState) {
						goToScannerResult(result);
					},
					onError() {
						alertToast('Something went wrong', undefined, undefined, { autoClose: 2200 });
						console.error('Something went wrong while scanning cargo id');
					},
					abortSignal: abortController.signal,
				});
			}
		},
	});

	const { start, stop } = useInterval(capture, 300);

	useEffect(() => {
		if (mode === ScannerModeMap.QrCode) {
			start();
		} else {
			stop();
		}
	}, [mode]);

	const handleClicks = {
		onClose() {
			goToCheckIn();
		},
		onReset(e?: React.MouseEvent<HTMLButtonElement>) {
			e?.stopPropagation();
			abortControllerRef.current?.abort();
			dispatch({ action: ScannerActionMap.ResetImage });
		},
		onToggle(e?: React.MouseEvent<HTMLButtonElement>) {
			e?.stopPropagation();
			dispatch({ action: ScannerActionMap.ToggleMode });
		},
		onCapture(e?: React.MouseEvent<HTMLButtonElement>) {
			e?.stopPropagation();
			capture();
		},
		onSearch() {
			goToCheckIn(true);
		},
	};

	return (
		<>
			<ScannerLoading image={image} marker={frame} onResetClick={handleClicks.onReset} />
			<video muted ref={videoRef} autoPlay playsInline />
			<CameraFrame width={frame.width} height={frame.height} topOffset={CameraFrameTopOffset} />
			{/* Elements must be after CameraFrame component to be accessible, due to CameraFrame's overlay */}
			<button className="close-btn" onClick={handleClicks.onClose}>
				<Icon name="cross" />
			</button>
			<ScannerToggleButton mode={mode} onToggle={handleClicks.onToggle} />
			<h6 className="description" style={{ top: `${frame.height + CameraFrameTopOffset}rem` }}>
				Scan {mode === ScannerModeMap.QrCode ? 'ticket' : 'rego plate'}
			</h6>
			<div className="description" style={{ top: `${frame.height + CameraFrameTopOffset + 2}rem` }}>
				<RetryMessage />
			</div>
			{mode === ScannerModeMap.Rego && (
				<>
					<div className="bottom-center-anchor">
						<button className="capture-btn" onClick={handleClicks.onCapture} />
						<SearchButton />
					</div>
				</>
			)}
			<SecuredRender groups={SecuredGroups.create.onlySuper().groups}>
				<DevModeButton />
			</SecuredRender>
		</>
	);
}

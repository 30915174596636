import * as React from 'react';
import { action } from 'mobx';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import { getFormattedHoursDays } from 'Util/StringUtils';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';

export interface RemoveTrailerModalProps {
	removalFee: number,
	hoursBeforeDeparture: number,
}

export const ModalContent = ({
	removalFee,
	hoursBeforeDeparture,
}: RemoveTrailerModalProps) => {
	return (
		<div className="manager-cancel-booking-modal-content">
			{/* eslint-disable-next-line max-len */}
			<h5>As you are providing less than {getFormattedHoursDays(hoursBeforeDeparture)} notice, you will receive a credit of
				<span>
					<strong> {displayAsAud(removalFee)}</strong>.
				</span>
			</h5>
			<h5>Would you like to proceed?</h5>
		</div>
	);
};

export const RenderRemoveTrailerModal = async (
	percentageFee: number,
	hoursBeforeDeparture: number,
) => {
	return modalWithCustomInput(
		'Cancel trailer?',
		<ModalContent removalFee={percentageFee} hoursBeforeDeparture={hoursBeforeDeparture} />,
		{
			cancelText: 'Back',
			confirmText: 'Continue',
			resolveOnCancel: true,
			onConfirm: action(() => {
				return true;
			}),
		},
	);
};

import { BookingEntity, FerryTripEntity } from '../../../../Models/Entities';
import { isNotNullOrUndefined } from '../../../TypeGuards';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';
import {
	calculateOneWayVehicleLengthPriceAlterBooking,
} from '../AlterationPriceCalculations/Alteration_CalculateOneWayVehicleLengthPrice';
import {
	calculateOneWayVehicleLengthPriceNewBooking,
} from '../CreationPriceCalculations/Creation_CalculateOneWayVehicleLengthPrice';

/**
 * This function will calculate the price difference between the
 * old vehicle length and the new vehicle length.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param priceCalculationProps: The props needed for price calculations.
 * @param trip: The new ferry trip to find vehicle price difference from.
 * @param bookingToEdit?: The booking that is being edited (if in alteration mode).
 * @param option?: The drop-down menu option on vehicle tab.
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 */
export const getOneWayVehicleLengthPriceDifference = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity,
	bookingToEdit: BookingEntity | null = null,
	option?: string,
	forEftpos = false,
): number => {
	/* Alteration mode */
	if (isNotNullOrUndefined(bookingToEdit) && isNotNullOrUndefined(bookingToEdit.id)) {
		return calculateOneWayVehicleLengthPriceAlterBooking(
			trip,
			(departureTrip ? bookingToEdit : bookingToEdit.returnBooking) ?? bookingToEdit,
			priceCalculationProps?.vehicleLengthId ?? undefined,
			option,
			forEftpos,
		);
	}
	/* Create mode */
	if (isNotNullOrUndefined(priceCalculationProps.vehicleLengthId) && priceCalculationProps.vehicleLengthId !== '') {
		return calculateOneWayVehicleLengthPriceNewBooking(
			priceCalculationProps.vehicleLengthId,
			trip,
			priceCalculationProps.vehicleWeightId,
			option,
		);
	}
	return 0;
};

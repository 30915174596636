import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
	getEventBookingTransactionIdFromStorage,
	getFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { CheckOutFormWrapper } from '../../../Payment/CheckOutFormWrapper';
import {
	resetReservationTimer,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { usePaymentTab } from 'Hooks/usePaymentTab';
import { alertModal } from '../../../../Modal/ModalUtils';
import { getPaymentModalContents, getPaymentModalTitle } from './PaymentTabModalUtils';
import {
	clearEventBookingWizard,
	clearFerryBookingWizard,
} from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { LottieSpinner } from '../../../Lottie/LottieSpinner';
import { PaymentError } from './PaymentTab';
import { useHistory } from 'react-router';
import { isNullOrUndefined } from '../../../../../../Util/TypeGuards';

export interface StripePaymentTabProps {
	paymentCompletedUrl: string;
	timeoutExpiryUrl: string;
	nameToPrefill: string;
	errorCode: string | null;
	onCompletePayment: () => void;
	onPageLoaded: () => void;
	bulkBooking: boolean;
	returnTrip: boolean;
	userId?: string;
	isAlteration: boolean;
	bookingToEditId?: string;
	eventBooking: boolean;
	setTabIsLoading: (loading: boolean) => void;
	setDisableContinueButton: (disableContinueButton: boolean) => void;
	disableContinueButton: boolean;
}

function StripePaymentTab({
	paymentCompletedUrl,
	timeoutExpiryUrl,
	nameToPrefill,
	errorCode,
	onCompletePayment,
	onPageLoaded,
	bulkBooking,
	returnTrip,
	userId,
	isAlteration,
	bookingToEditId,
	eventBooking,
	setTabIsLoading,
	setDisableContinueButton,
	disableContinueButton,
}: StripePaymentTabProps) {
	const history = useHistory();
	const transactionId = eventBooking
		? getEventBookingTransactionIdFromStorage()
		: getFerryBookingTransactionIdFromStorage();

	const {
		transactionDetails,
		accessCodeError,
	} = usePaymentTab({
		transactionId,
		paymentCompletedUrl,
		bookingForAlteration: isAlteration,
		eventBooking,
		errorCode,
		bulkBooking,
		returnTrip,
		userId,
	});

	const errorText = getErrorText(errorCode);
	useEffect(() => {
		if (errorText !== null && transactionId !== null) {
			resetReservationTimer(transactionId).catch(err => {});
		}
	}, [errorText, transactionId]);

	if (accessCodeError !== PaymentError.NO_ERROR) {
		// If a new error case is introduced in the future, it will need to be accounted for in here. If it is not then
		// the modal for that case would be empty, with just the button to return to the cart
		alertModal(
			getPaymentModalTitle(accessCodeError),
			getPaymentModalContents(accessCodeError),
			{
				cancelText: `Return to ${accessCodeError === PaymentError.NO_PAYMENT_ERROR ? 'Booking' : 'Payment'}`,
				shouldCloseOnEsc: false,
				shouldCloseOnOverlayClick: false,
			},
		).then(() => {
			let urlToNavigateTo = timeoutExpiryUrl;
			if (accessCodeError === PaymentError.NO_PAYMENT_ERROR) {
				const bookingId = bookingToEditId ?? '';
				if (!eventBooking) {
					urlToNavigateTo = bookingToEditId !== '' && isAlteration
						? `/bookings/${bookingId}`
						: '/bookings';
					clearFerryBookingWizard();
				} else {
					urlToNavigateTo = '/upcoming-events';
					clearEventBookingWizard();
				}
			}
			history.push(urlToNavigateTo);
		});

		return <></>;
	}

	if (transactionDetails == null) {
		return <LottieSpinner />;
	}

	onPageLoaded();

	if (isNullOrUndefined(transactionId)) {
		return <></>;
	}

	return (
		<CheckOutFormWrapper
			transactionId={transactionId}
			paymentDetails={transactionDetails}
			onPageLoaded={onPageLoaded}
			userId={userId}
			isAlteration={isAlteration}
			eventBooking={eventBooking}
			setTabIsLoading={setTabIsLoading}
			setDisableContinueButton={setDisableContinueButton}
			redirectUrl={transactionDetails.formActionUrl}
		/>
	);
}

function getErrorText(errorCode: string | null): string | null {
	if (errorCode === null) {
		return null;
	}

	switch (errorCode) {
		default:
			return 'No payment has been made. Please re-enter your details and try again.';
	}
}

export default observer(StripePaymentTab);

import React from 'react';
import { FeeEntity } from 'Models/Entities';
import { observer } from 'mobx-react';
import { CartCalculationLineItem } from './CartCalculationLineItem';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { store } from '../../../../../../../Models/Store';

export interface AddedFeeLinesProps {
	fees: FeeEntity[];
	onRemoveFee?: ((id: string) => void);
}

function AddedFeeLines({ fees, onRemoveFee }: AddedFeeLinesProps) {
	const feesToDisplay = fees?.filter(x => x.amount > 0);
	const { isStaff } = store;

	return (
		<>
			{feesToDisplay
				?.filter(x => x.feeOption !== null)
				?.map(fee => (
					<CartCalculationLineItem
						keyToUse={fee?.id}
						lineItemDisplayName={fee.feeOption.name ?? 'Service fee'}
						lineItemPrice={formatPriceDisplay(fee.amount, true)}
						className="service-fee__line-item"
						hasRemovalButton={isNotNullOrUndefined(onRemoveFee) && fee.feeOption.removable && isStaff}
						removalOnClick={() => {
							if (isNotNullOrUndefined(onRemoveFee)) {
								return onRemoveFee(fee.id);
							}
							return () => {};
						}}
					/>
				))}
		</>
	);
}

export default observer(AddedFeeLines);

import { BookingEntity, FerryTripEntity } from 'Models/Entities';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';
import { isNotNullOrUndefined, isNullOrUndefined, stringNotEmpty } from '../../../TypeGuards';
import { FindVehicleTrailerDifferencePrice } from '../Helpers/FindLengthPriceDifference';
import {
	filterReservedAndBookedAlterations,
	getMostRecentBookedAlteration,
	sortAlterationsByDateCreated,
} from '../../AlterationSortingUtils';
import { getMinimumMeasurementFromStore } from '../../MeasurementUtils';
import { getOldFerryBookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * This function calculates the price difference between the old and new towOn price for the selected length.
 * @param priceCalculationProps
 * @param trip: The newest ferry trip that the user has selected (may be the same as the original booking).
 * @param bookingToEdit: The booking that is being altered.
 * @param optionId: The length type option from the dropdown menu on the vehicle tab
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 * @param departingTrip */
export const calculateOneWayTrailerLengthPriceAlterBooking = (
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity,
	bookingToEdit: BookingEntity,
	optionId?: string,
	forEftpos = false,
	departingTrip: boolean = true,
): number => {
	const oldWizardData = getOldFerryBookingWizardData();
	const sortedList = filterReservedAndBookedAlterations(
		sortAlterationsByDateCreated(bookingToEdit?.alterations),
	);

	const lengthToCalculate = optionId === '' || optionId === undefined
		? priceCalculationProps.trailerLengthId
		: optionId;

	let newTrailerPrice = 0;
	const mostRecentBookedAlteration = getMostRecentBookedAlteration(sortedList);

	// In booking wizard (where old booking wizard data exists)
	if (isNotNullOrUndefined(oldWizardData)) {
		newTrailerPrice = FindVehicleTrailerDifferencePrice(
			mostRecentBookedAlteration.ferryTrip,
			trip,
			(priceCalculationProps.towOnTypeId !== 'NO_TRAILER' && stringNotEmpty(lengthToCalculate))
				? lengthToCalculate
				: undefined,
			oldWizardData.towOnTypeId !== 'NO_TRAILER'
				? mostRecentBookedAlteration?.getTowOn()?.selectedLengthId
				: undefined,
			mostRecentBookedAlteration.getTowOnPrice(),
			getMinimumMeasurementFromStore('WEIGHT')?.id,
		);
		if (priceCalculationProps.towOnTypeId === 'NO_TRAILER'
			&& mostRecentBookedAlteration?.getTowOn()?.towOnTypeId !== 'NO_TRAILER'
			&& !priceCalculationProps.cancellationFeesRemoved) {
			const removalFee = departingTrip
				? oldWizardData.departingCancellationFee
				: oldWizardData.returningCancellationFee;
			if (isNotNullOrUndefined(removalFee) && removalFee > 0) {
				newTrailerPrice += removalFee;
			}
		}
	}

	// If the calculations are being done on the booking success page or the check-in page
	// (where old booking wizard data doesn't exist).
	if (isNullOrUndefined(oldWizardData)) {
		const oldFerryTripAlteration = sortedList
			.find(x => x.ferryTripId !== null && x.id !== mostRecentBookedAlteration.id);

		newTrailerPrice = FindVehicleTrailerDifferencePrice(
			oldFerryTripAlteration?.ferryTrip ?? trip,
			trip,
			sortedList[0]?.getTowOn()?.towOnTypeId !== 'NO_TRAILER'
				? sortedList[0]?.getTowOn()?.selectedLengthId
				: undefined, // new towOn length
			sortedList[1]?.getTowOn()?.towOnTypeId !== 'NO_TRAILER'
				? sortedList[1]?.getTowOn()?.selectedLengthId
				: undefined, // old towOn length
			sortedList[1]?.getTowOnPrice(), // old towOn price paid
			getMinimumMeasurementFromStore('WEIGHT')?.id,
		);
		// Add the towOn removal fee on (if there is one)
		const trailerRemovalFee = mostRecentBookedAlteration.fees.find(x => x.feeType === 'CANCELLATION');
		if (isNotNullOrUndefined(trailerRemovalFee)) {
			newTrailerPrice += trailerRemovalFee.amount;
		}
	}

	// If the booking was eftposed and total cost of booking is less than $0, return $0
	if (forEftpos && newTrailerPrice < 0) {
		return 0;
	}

	return newTrailerPrice;
};

import axios from 'axios';
import { SERVER_URL } from '../../../Constants';
import { serialiseDate } from '../../../Util/AttributeUtils';
import { measurementPrice } from '../../CustomGql/FerryTripEntityType';

export interface TicketsTabTrip {
	id: string;
	isWaitListed: boolean,
	isHidden: boolean | null,
	isClosed: boolean | null,
	startDate: Date;
	tripTimeTakenMinutes: number;

	// Passenger pricing
	adultPassengerPrice: number;
	childPassengerPrice: number;
	infantPassengerPrice: number;

	// Measurement pricing
	prices: measurementPrice[];

	// Ferry trip Capacity
	passengerSpacesAvailable: number;
	passengerSpacesTotal: number;
	vehicleSpacesAvailable: number;
	vehicleSpacesTotal: number;
	trailerSpotsAvailable: number;
	trailerSpotsTotal: number;
	weightAvailable: number;
	totalWeight: number;

	priceD: number;
	priceE: number;
	priceF: number;
	priceG: number;
	priceH: number;
}

export interface TicketsTabData {
	departureTickets: TicketsTabTrip[];
	returningTickets: TicketsTabTrip[];
}

export interface TicketsTabStateData {
	departureTickets: TicketsTabTrip[] | null;
	returningTickets: TicketsTabTrip[] | null;
}

export async function GetTicketsTabData(
	departureId: string,
	returningId: string,
	departureDateNoFormat: Date,
	returnDateNoFormat: Date,
	bookingBeingEditedId: string | null,
	isStaff: boolean,
	userId?: string,
): Promise<TicketsTabData> {
	const departureDate = serialiseDate(departureDateNoFormat);
	const returnDate = serialiseDate(returnDateNoFormat);
	const { data } = await axios.get<TicketsTabData>(
		`${SERVER_URL}/api/booking-wizard-data/tickets-tab`,
		{
			params: {
				departureId,
				returningId,
				departureDate,
				returnDate,
				bookingBeingEditedId,
				userId,
				isStaff,
			},
		},
	);

	data.departureTickets.forEach(x => {
		x.startDate = new Date(x.startDate);
	});
	data.departureTickets = data.departureTickets.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	data.returningTickets.forEach(x => {
		x.startDate = new Date(x.startDate);
	});
	data.returningTickets = data.returningTickets.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	return data;
}

export async function GetTicketsTabDataOneWay(
	departureId: string,
	returningId: string,
	departureDateNoFormat: Date,
	takeDeparture: boolean,
	bookingBeingEditedId: string | null = null,
	isStaff: boolean,
	userId?: string,
): Promise<TicketsTabTrip[]> {
	const departureDate = serialiseDate(departureDateNoFormat);
	const { data } = await axios.get<TicketsTabData>(
		`${SERVER_URL}/api/booking-wizard-data/tickets-tab-one-way`,
		{
			params: {
				departureId,
				returningId,
				departureDate,
				takeDeparture,
				bookingBeingEditedId,
				userId,
				isStaff,
			},
		},
	);

	const tripsToReturn = takeDeparture ? data.departureTickets : data.returningTickets;
	tripsToReturn.forEach(x => {
		x.startDate = new Date(x.startDate);
	});
	tripsToReturn.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	return tripsToReturn;
}

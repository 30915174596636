import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import useAsync from 'Hooks/useAsync';
import { action, runInAction } from 'mobx';
import { TextField } from 'Views/Components/TextBox/TextBox';
import _ from 'lodash';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import useStore from 'Hooks/useStore';
import {
	BookingWizardData,
	getOldFerryBookingWizardData,
	WizardErrors,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { getCargoAndTowOnTypes } from 'Util/_HumanWritten/VehicleMakeAndModelUtils';
import alertToast from 'Util/ToastifyUtils';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import {
	PreviousVehicle,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Vehicle/PreviousVehicle';
import If from 'Views/Components/If/If';
import { BookingEntity, TowOnTypeEntity } from 'Models/Entities';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import {
	AddOnsCollection,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/AddOn/AddOnCollection';
import {
	AddOnsContext,
	AddOnsTabProps,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/AddOn/AddOnTab';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { getMostRecentBookedAlteration } from 'Util/_HumanWritten/AlterationSortingUtils';
import {
	RenderRemoveTrailerModal,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/RemoveTrailerModal';
import {
	FerryTripEntity,
	RouteEntity,
	CargoEntity,
	CargoTypeEntity,
} from 'Models/Entities';
import {
	findAllMeasurementsForFerryTrip,
	getMeasurementLabelFromId,
	getMeasurementValueFromId,
} from 'Util/_HumanWritten/MeasurementUtils';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import {
	wizardModeOptions,
} from 'Models/Enums';
import { upperCaseFirst } from 'Util/StringUtils';
import {
	getOneWayVehicleLengthPriceDifference,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetOneWayVehicleLengthPriceDifference';
import {
	getOneWayTrailerLengthPriceDifference,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetOneWayTrailerLengthPriceDifference';
import SecuredPage from 'Views/Components/Security/SecuredPage';

export interface VehicleTabProps {
	wizardData: BookingWizardData;
	previousVehicle: { previous: boolean };
	onUpdateWizardData: (wizardData: BookingWizardData) => void;
	isCustomer: boolean;
	ferryTrips: SelectedTrips;
	route: RouteEntity | null;
	cargoType: CargoTypeEntity | null;
	setVehicleType: (newType: CargoTypeEntity) => void;
	errors: WizardErrors<BookingWizardData>;
	onUpdateErrors: (newErrors: WizardErrors<BookingWizardData>) => void;
	bookingToEdit: BookingEntity | null;
	bulkBookingTrips: FerryTripEntity[] | null;
	selectedTrips: SelectedTrips;
}

export function VehicleTab({
	previousVehicle,
	wizardData,
	onUpdateWizardData,
	isCustomer,
	ferryTrips,
	route,
	cargoType,
	setVehicleType,
	errors,
	onUpdateErrors,
	bookingToEdit = null,
	bulkBookingTrips,
	selectedTrips,
}: VehicleTabProps) {
	const store = useStore();

	const returnTrip = wizardData.tripType === 'return';

	const calculateRefundAmount = () => {
		let refund = 0;
		if (isNotNullOrUndefined(bookingToEdit)) {
			refund += getMostRecentBookedAlteration(bookingToEdit?.alterations)?.getTowOnPrice() ?? 0;
			refund -= wizardData.departingCancellationFee ?? 0;
			if (isNotNullOrUndefined(bookingToEdit.returnBooking) && wizardData.returningTicketId !== '') {
				refund += getMostRecentBookedAlteration(bookingToEdit?.returnBooking?.alterations)?.getTowOnPrice()
					?? 0;
				refund -= wizardData?.returningCancellationFee ?? 0;
			}

			return refund;
		}
		return 0;
	};

	useEffect(() => {
		const newData = { ...wizardData };
		const oldWizardData = getOldFerryBookingWizardData();
		if (!newData.trailerCheckboxSelected
			&& oldWizardData?.towOnTypeId !== 'NO_TRAILER'
			&& wizardData.towOnTypeId !== 'NO_TRAILER') {
			if (oldWizardData?.trailerCheckboxSelected) {
				if (isNotNullOrUndefined(wizardData.departingCancellationFee)
					&& isNotNullOrUndefined(wizardData.departingCancellationCutoffHours)) {
					RenderRemoveTrailerModal(
						calculateRefundAmount(),
						wizardData.departingCancellationCutoffHours,
					).then(res => {
						if (res) {
							newData.towOnTypeId = 'NO_TRAILER';
							newData.trailerLengthId = '';
							newData.trailerCheckboxSelected = false;
						} else if (isNotNullOrUndefined(oldWizardData)) {
							newData.towOnTypeId = oldWizardData.towOnTypeId;
							newData.trailerCheckboxSelected = oldWizardData
								.trailerCheckboxSelected;
							newData.trailerLengthId = oldWizardData
								.trailerLengthId;
						}
						onUpdateWizardData(newData);

						const newErrors = { ...errors };
						delete newErrors.towOnTypeId;
						delete newErrors.trailerLengthId;
						onUpdateErrors(newErrors);
						// Un-focus the input after user has finished with the pop-up
						(document.activeElement as HTMLElement).blur();
					});
				}
			} else {
				newData.towOnTypeId = 'NO_TRAILER';
				newData.trailerLengthId = '';
			}
		}
		if ((store.loggedIn && newData.driverFirstName === '') && isCustomer) {
			newData.driverFirstName = store.userData?.firstName ?? '';
			newData.driverLastName = store.userData?.lastName ?? '';
			newData.driverPhone = store.userData?.phone ?? '';
		}

		onUpdateWizardData(newData);
	}, [
		isCustomer,
		onUpdateWizardData,
		store.loggedIn,
		store.userData?.firstName,
		store.userData?.lastName,
		store.userData?.phone,
		wizardData.trailerCheckboxSelected,
	]);

	const response = useAsync(() => {
		const skipQuery = false;

		const promises: Promise<any>[] = [
			getCargoAndTowOnTypes(
				wizardData.userId,
				skipQuery,
			),
		];

		return Promise.all(promises);
	}, [wizardData.userId]);

	if (response.type === 'loading' || isNullOrUndefined(ferryTrips)) {
		return <LottieSpinner />;
	}

	if (response.type === 'error') {
		alertToast(`Could not load ${whiteLabelStore.config.vehicleLabel} types`, 'error', 'Error fetching data');
		return <></>;
	}
	const [
		cargoTypeList,
		unfilteredPreviousVehicleList,
		trailerTypeList,
	] = response.data[0] as [CargoTypeEntity[], CargoEntity[], TowOnTypeEntity[]];

	const trailerTypeOptions = [{ display: 'No trailer', value: 'NO_TRAILER' }];

	trailerTypeList.forEach(x => {
		trailerTypeOptions.push({
			display: upperCaseFirst(x.label.toLowerCase()),
			value: x.id,
		});
	});

	const previousVehicleList = unfilteredPreviousVehicleList;

	const isDisabledOption = (optionId: string, minimumLengthId?: string) => {
		if (minimumLengthId) {
			return getMeasurementValueFromId(optionId) < getMeasurementValueFromId(minimumLengthId);
		}
		return false;
	};

	const getVehicleLengthOptionDisplay = (optionId: string, isDisabledProp?: boolean) => {
		const isDisabled = isNotNullOrUndefined(isDisabledProp) ? isDisabledProp : isDisabledOption(
			optionId,
			cargoTypeList.find(v => v.id === cargoType?.cargoModel)?.minimumLengthId,
		);

		let lengthPrice = 0;
		if (bulkBookingTrips === null) {
			if (ferryTrips.departingTrip) {
				lengthPrice += getOneWayVehicleLengthPriceDifference(
					true,
					wizardData,
					ferryTrips.departingTrip,
					bookingToEdit,
					optionId,
				);
			}
			if (returnTrip && ferryTrips.returningTrip) {
				lengthPrice += getOneWayVehicleLengthPriceDifference(
					false,
					wizardData,
					ferryTrips.returningTrip,
					bookingToEdit?.returnBooking,
					optionId,
				);
			}
		} else {
			bulkBookingTrips.forEach(x => {
				lengthPrice += getOneWayVehicleLengthPriceDifference(
					true,
					wizardData,
					x,
					bookingToEdit,
					optionId,
				);
			});
		}

		return `${getMeasurementLabelFromId(optionId)} (${formatPriceDisplay(
			lengthPrice, wizardData.wizardMode === 'ALTERATION', true, true,
		)}) ${isDisabled
			? '- vehicle too large'
			: ''}`;
	};

	const getTrailerLengthOptionDisplay = (optionId: string) => {
		let lengthPrice = 0;
		if (bulkBookingTrips === null) {
			if (ferryTrips.departingTrip) {
				lengthPrice += getOneWayTrailerLengthPriceDifference(
					true,
					wizardData,
					ferryTrips.departingTrip,
					bookingToEdit,
					optionId,
				);
			}
			if (returnTrip && ferryTrips.returningTrip) {
				lengthPrice += getOneWayTrailerLengthPriceDifference(
					false,
					wizardData,
					ferryTrips.returningTrip,
					bookingToEdit?.returnBooking,
					optionId,
				);
			}
		} else {
			bulkBookingTrips.forEach(x => {
				lengthPrice += getOneWayTrailerLengthPriceDifference(true, wizardData, x, bookingToEdit, optionId);
			});
		}
		return `${getMeasurementLabelFromId(optionId)} (${formatPriceDisplay(
			lengthPrice,
			wizardData.wizardMode === 'ALTERATION',
			true,
			true,
		)})`;
	};

	const clearSelectedVehicle = () => {
		const newData = { ...wizardData };

		newData.cargoMake = '';
		newData.cargoModel = '';
		newData.vehicleLengthId = '';
		newData.vehicleWeightId = '';

		onUpdateWizardData(newData);
	};

	if (route === null) {
		return <></>;
	}

	const addOnsContextValue: AddOnsTabProps = {
		wizardData,
		route,
		selectedTrips,
		bookingToEdit,
		onUpdateWizardData,
	};

	const isCreateMode = wizardData.wizardMode === wizardModeOptions.CREATE;

	return (
		<SecuredPage groups={SecuredGroups.create.onlyLoggedIn().groups}>
			<div className="booking-wizard-tab add-vehicle-details-tab">
				<div className="vehicle-details-section">
					<div className="booking-wizard__tab-header vehicle-tab-header">
						{
							// eslint-disable-next-line no-nested-ternary
							<h2 className="vehicle-details-header">
								<If condition={isCreateMode && previousVehicle.previous}>
									{whiteLabelStore.vehicleLabelPascalCase} details
								</If>
								<If condition={isCreateMode && !previousVehicle.previous}>
									New {whiteLabelStore.config.vehicleLabel} details
								</If>
								<If condition={wizardData.wizardMode !== wizardModeOptions.CREATE}>
									Edit {whiteLabelStore.config.vehicleLabel} details
								</If>
								<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION}>
									<div>
										<Link to={`/bookings/${wizardData?.bookingToEdit ?? ''}`}>
											<Icon name="cross" classname="icon" />
										</Link>
									</div>
								</If>
							</h2>
						}
						{wizardData.cargoMake !== ''
							&& previousVehicleList.length !== 0
							&& isCreateMode
							&& (
								<Button
									className="close-button icon-close icon-right"
									colors={Colors.None}
									display={Display.Text}
									sizes={Sizes.ExtraLarge}
									onClick={action(() => {
										previousVehicle.previous = false;
										clearSelectedVehicle();
									})}
								/>
							)}
					</div>
					<div className="vehicle-details-body">
						<Combobox
							className="vehicle-make"
							model={wizardData}
							modelProperty="cargoMake"
							label={whiteLabelStore.config.vehicleMakeLabel}
							placeholder={whiteLabelStore.config.vehicleMakeInputPlaceholder}
							options={_.uniq(cargoTypeList.map(x => x.cargoMake))
								/*
								 Generic vehicle types are introduced to handle edge cases during data migration.
								 They are not intended to be displayed in the front-end, hence, filtering them out.
								*/
								.filter(x => !x.startsWith('Generic'))
								.map(x => {
									return { display: x, value: x };
								})}
							searchable
							isRequired
							onAfterChange={() => {
								const newData = { ...wizardData };
								newData.cargoTypeId = '';
								newData.vehicleLengthId = '';
								newData.vehicleWeightId = '';
								newData.cargoModel = '';
								onUpdateWizardData(newData);

								const newErrors = { ...errors };
								delete newErrors.cargoMake;
								onUpdateErrors(newErrors);
							}}
							inputProps={{
								upward: false,
							}}
							errors={errors.cargoMake}
						/>
						{((wizardData.cargoMake !== '' && previousVehicleList.length > 0)
								|| previousVehicleList.length === 0
								|| !whiteLabelStore.config.previousVehicleSelectionEnabled)
							&& (
								<>
									<Combobox
										className="vehicle-model"
										model={wizardData}
										modelProperty="cargoModel"
										label={whiteLabelStore.config.vehicleModelLabel}
										placeholder={whiteLabelStore.config.vehicleModelInputPlaceholder}
										options={cargoTypeList
											.filter(x => x.cargoMake === wizardData.cargoMake)
											.map(x => {
												return { display: x.cargoModel, value: x.cargoModel };
											})}
										searchable
										isRequired
										onAfterChange={() => {
											const newData = { ...wizardData };

											const selectedVehicleType = cargoTypeList
												.filter(x => x.cargoModel === newData.cargoModel)[0];

											if (!!selectedVehicleType) {
												newData.cargoTypeId = selectedVehicleType.id;
												newData.vehicleLengthId = selectedVehicleType.minimumLengthId;
												newData.vehicleWeightId = selectedVehicleType.minimumWeightId;
												setVehicleType(selectedVehicleType);
												onUpdateWizardData(newData);
											}

											const newErrors = { ...errors };
											delete newErrors.cargoModel;
											// Setting the model will also set the vehicle length, so we can remove
											// that error as well
											delete newErrors.vehicleLengthId;
											onUpdateErrors(newErrors);
										}}
										inputProps={{
											upward: false,
										}}
										errors={errors.cargoModel}
									/>
									<If condition={whiteLabelStore.config.vehicleLengthEnabled}>
										<Combobox
											className="vehicle-length"
											model={wizardData}
											modelProperty="vehicleLengthId"
											label={(returnTrip ? 'Vehicle length (+ Return price)' : 'Vehicle length')}
											placeholder="Select vehicle length"
											options={findAllMeasurementsForFerryTrip(ferryTrips.departingTrip, 'LENGTH')
												.map(x => {
													const isDisabled = isDisabledOption(x.id, cargoTypeList
														.find(v => v.id
															=== wizardData.cargoTypeId)?.minimumLengthId);
													return {
														display: getVehicleLengthOptionDisplay(x.id, isDisabled),
														value: x.id,
														disabled: isDisabled,
													};
												})}
											searchable
											isRequired
											onAfterChange={() => {
												const newData = { ...wizardData };
												onUpdateWizardData(newData);

												const newErrors = { ...errors };
												delete newErrors.vehicleLengthId;
												onUpdateErrors(newErrors);
											}}
											inputProps={{
												upward: false,
											}}
											errors={errors.vehicleLengthId}
										/>
									</If>
									<If condition={whiteLabelStore.config.trailersEnabled}>
										<Combobox
											className="trailer-type"
											model={wizardData}
											modelProperty="towOnTypeId"
											label="Towing a boat, caravan or trailer?"
											placeholder="Select trailer type"
											options={trailerTypeOptions}
											searchable
											isRequired
											onAfterChange={() => {
												const newData = { ...wizardData };
												if (newData.towOnTypeId !== 'NO_TRAILER') {
													runInAction(() => {
														newData.trailerCheckboxSelected = true;
													});
												} else {
													newData.trailerLengthId = '';

													if (newData.wizardMode === 'ALTERATION'
														&& isNotNullOrUndefined(
															wizardData.departingCancellationFee,
														)
														&& isNotNullOrUndefined(
															wizardData.departingCancellationCutoffHours,
														)
														&& wizardData.departingCancellationFee > 0) {
														const oldWizardData = getOldFerryBookingWizardData();
														if (isNotNullOrUndefined(oldWizardData)
															&& oldWizardData.towOnTypeId !== 'NO_TRAILER') {
															RenderRemoveTrailerModal(
																calculateRefundAmount(),
																wizardData.departingCancellationCutoffHours,
															).then(res => {
																if (res) {
																	newData.towOnTypeId = 'NO_TRAILER';
																	newData.trailerLengthId = '';
																	newData.trailerCheckboxSelected = false;
																} else if (isNotNullOrUndefined(oldWizardData)) {
																	newData.towOnTypeId = oldWizardData.towOnTypeId;
																	newData.trailerCheckboxSelected = oldWizardData
																		.trailerCheckboxSelected;
																	newData.trailerLengthId = oldWizardData
																		.trailerLengthId;
																}
																onUpdateWizardData(newData);

																const newErrors = { ...errors };
																delete newErrors.towOnTypeId;
																delete newErrors.trailerLengthId;
																onUpdateErrors(newErrors);
																// Un-focus the input after user has finished with the pop-up
																(document.activeElement as HTMLElement).blur();
															});
														}
													}
												}
												onUpdateWizardData(newData);

												const newErrors = { ...errors };
												delete newErrors.towOnTypeId;
												delete newErrors.trailerLengthId;
												onUpdateErrors(newErrors);
											}}
											inputProps={{
												upward: false,
											}}
											errors={errors.towOnTypeId}
										/>
										{wizardData.towOnTypeId !== 'NO_TRAILER' && (
											<Combobox
												className="trailer-length"
												model={wizardData}
												modelProperty="trailerLengthId"
												label={
													(returnTrip ? 'Trailer length (+ Return price)' : 'Trailer length')
												}
												placeholder="Select trailer length"
												options={
													findAllMeasurementsForFerryTrip(ferryTrips.departingTrip, 'LENGTH')
														.map(x => {
															return {
																display: getTrailerLengthOptionDisplay(x.id),
																value: x.id,
															};
														})
												}
												searchable
												isRequired
												onAfterChange={() => {
													const newData = { ...wizardData };
													onUpdateWizardData(newData);

													const newErrors = { ...errors };
													// delete newErrors.trailerLength;
													onUpdateErrors(newErrors);
												}}
												inputProps={{
													upward: false,
												}}
												errors={errors.trailerLengthId}
											/>
										)}
									</If>
								</>
							)}
					</div>
				</div>
				{(wizardData.cargoMake !== ''
					&& previousVehicleList.length > 0)
				|| previousVehicleList.length === 0
				|| !whiteLabelStore.config.previousVehicleSelectionEnabled
					? (
						<div className="driver-details-section">
							<h4 className="driver-details-header">
								{whiteLabelStore.config.driverDetailsTitle} details
							</h4>
							<p>{whiteLabelStore.config.driverDetailsSubtitle}</p>
							<div className="driver-details-body">
								<TextField
									model={wizardData}
									modelProperty="driverFirstName"
									id="driver-first-name"
									label="First name"
									placeholder="First name"
									isRequired
									onAfterChange={() => {
										const newData = { ...wizardData };
										onUpdateWizardData(newData);

										const newErrors = { ...errors };
										delete newErrors.driverFirstName;
										onUpdateErrors(newErrors);
									}}
									errors={errors.driverFirstName}
								/>
								<TextField
									model={wizardData}
									modelProperty="driverLastName"
									id="driver-last-name"
									label="Last name"
									placeholder="Last name"
									isRequired
									onAfterChange={() => {
										const newData = { ...wizardData };
										onUpdateWizardData(newData);

										const newErrors = { ...errors };
										delete newErrors.driverFirstName;
										onUpdateErrors(newErrors);
									}}
									errors={errors.driverLastName}
								/>
								<TextField
									model={wizardData}
									modelProperty="driverPhone"
									id="driver-phone"
									label="Phone"
									placeholder="Phone"
									isRequired
									onAfterChange={() => {
										const newData = { ...wizardData };
										onUpdateWizardData(newData);

										const newErrors = { ...errors };
										delete newErrors.driverPhone;
										onUpdateErrors(newErrors);
									}}
									errors={errors.driverPhone}
									inputProps={{
										inputMode: 'numeric',
									}}
								/>
								<TextField
									className="vehicle-rego-input"
									model={wizardData}
									modelProperty="cargoIdentification"
									id="vehicle-registration"
									label={whiteLabelStore.config.driverDetailsRegoLabel}
									placeholder={whiteLabelStore.config.driverDetailsRegoPlaceholder}
									isRequired
									isDisabled={wizardData.hiredVehicle}
									onAfterChange={() => {
										const newData = { ...wizardData };
										onUpdateWizardData(newData);

										const newErrors = { ...errors };
										delete newErrors.cargoIdentification;
										onUpdateErrors(newErrors);
									}}
									errors={errors.cargoIdentification}
								/>
								<Checkbox
									className="hired-vehicle-checkbox"
									model={wizardData}
									modelProperty="hiredVehicle"
									id="hired-vehicle-checkbox"
									label={whiteLabelStore.config.driverDetailsRegoCheckboxInfo}
									onAfterChecked={() => {
										const newData = { ...wizardData };
										newData.cargoIdentification = newData.hiredVehicle
											? 'N/A'
											: '';

										onUpdateWizardData(newData);
									}}
								/>
							</div>
							{whiteLabelStore.vehicleNoteEnabled && (
								<TextField
									className="mt-none"
									model={wizardData}
									modelProperty="note"
									id="note"
									label={whiteLabelStore.cargoBookingNoteLabel}
									placeholder={whiteLabelStore.cargoBookingNotePlaceholder}
									onAfterChange={() => {
										const newData = { ...wizardData };
										onUpdateWizardData(newData);

										const newErrors = { ...errors };
										delete newErrors.note;
										onUpdateErrors(newErrors);
									}}
									errors={errors.note}
								/>
							)}
						</div>
					) : (
						<PreviousVehicle
							wizardData={wizardData}
							previousVehicleList={previousVehicleList}
							previousVehicle={previousVehicle}
							saveChanges={onUpdateWizardData}
							refresh={response.refresh}
							isCustomer={isCustomer}
							userId={wizardData.userId}
						/>
					)}
				<div className="mt-sm">
					<AddOnsContext.Provider value={addOnsContextValue}>
						<AddOnsCollection isVehicleOnly userId={wizardData.userId} />
					</AddOnsContext.Provider>
				</div>
			</div>
		</SecuredPage>
	);
}

import { BookingEntity, FerryTripEntity } from '../../../../Models/Entities';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';
import { calculateBasePriceAlterBooking } from './Alteration_CalculateBasePrice';
import { getTotalPriceOfAdditionalOptions } from '../../AdditionalBookingOptionsUtils';
import { getOneWayVehicleLengthPriceDifference } from '../PriceFunctionSelectors/GetOneWayVehicleLengthPriceDifference';
import { getOneWayVehicleWeightPriceDifference } from '../PriceFunctionSelectors/GetOneWayVehicleWeightPriceDifference';
import { getOneWayTrailerLengthPriceDifference } from '../PriceFunctionSelectors/GetOneWayTrailerLengthPriceDifference';
import { getTruncatedPrice } from '../../PriceUtils';
import { AdditionalOption } from '../../../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * This function will calculate the total one way price for a booking.
 * This will include base ticket price, additional cargo length,
 * additional towOn length and additional booking options.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param priceCalculationProps: The price calculation props needed for calculating the base price.
 * @param trip: The ferry trip to find the total one way ticket price from.
 * @param bookingToEdit: The booking that is being altered.
 * @param oldOptions
 * @param afterPayment: If the calculation is being done after payment has been taken
 * @param afterAlterationFeeAndPriceIncrease
 * @param forEftpos: This will determine whether or not the prices can go into the negative values */
export const calculateTotalOneWayTicketPriceAlterBooking = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	trip: FerryTripEntity,
	bookingToEdit: BookingEntity,
	oldOptions: AdditionalOption[],
	afterPayment: boolean,
	forEftpos = false,
	afterAlterationFeeAndPriceIncrease: boolean = true,
): number => {
	let calculatedPrice = 0.0;

	if (trip === undefined) {
		return 0.0;
	}

	// Calculate the base ticket price (passengers and base cargo length price)
	const newTicketBasePrice = calculateBasePriceAlterBooking(
		departureTrip,
		priceCalculationProps,
		trip,
		bookingToEdit,
		afterPayment,
		forEftpos,
		afterAlterationFeeAndPriceIncrease,
	);

	// Check if there is a cargo or towOn associated to the booking
	const cargo = priceCalculationProps.tabSelected === 'vehicle';

	const sumOfAdditionalItems = getTotalPriceOfAdditionalOptions(
		departureTrip ? priceCalculationProps.departingTripOptions : priceCalculationProps.returningTripOptions,
		oldOptions,
		forEftpos,
	);

	calculatedPrice = newTicketBasePrice
		+ (cargo
			? getOneWayVehicleLengthPriceDifference(
				departureTrip,
				priceCalculationProps,
				trip,
				bookingToEdit,
				undefined,
				forEftpos,
			) + getOneWayVehicleWeightPriceDifference(
				departureTrip,
				priceCalculationProps,
				trip,
				bookingToEdit,
			)
			: 0)
		+ getOneWayTrailerLengthPriceDifference(
			departureTrip,
			priceCalculationProps,
			trip,
			bookingToEdit,
			undefined,
			forEftpos,
		)
		+ sumOfAdditionalItems;

	return getTruncatedPrice(calculatedPrice, true);
};

import axios from 'axios';

import { LICENSE_PLATE_API } from 'Constants';

/**
 * @returns Array of detected text from the image (each element is a line of text in the image).
 */
export const readLicensePlate = (imageBase64: string, signal?: AbortSignal) => {
	const result = axios
		.post<string[]>(`${LICENSE_PLATE_API}/read`, { ImageBase64: imageBase64 }, { signal })
		.then(x => x.data);
	return result;
};

export interface TextDetectionDto {
	detectedText: string;
	confidence: number;
}

export const readLicensePlateWithConfidence = (imageBase64: string, signal?: AbortSignal) => {
	const result = axios
		.post<TextDetectionDto[]>(
			`${LICENSE_PLATE_API}/read-confidence`,
			{
				ImageBase64: imageBase64,
			},
			{ signal },
		)
		.then(x => x.data);
	return result;
};

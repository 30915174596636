import * as React from 'react';
import { useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import useCheckInStore from 'Hooks/useCheckInStore';
import { BookingEntity } from 'Models/Entities';
import { CustomLocationState, isCheckInLocationState } from 'Models/_HumanWritten/LocationState';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import useScreenSize from 'Hooks/useScreenSize';
import { completeTransactionNoPayment } from 'Services/Api/_HumanWritten/PaymentService';
import { fetchGiftCertificate } from 'Services/Api/_HumanWritten/GiftCertificateService';
import { paymentMethodType } from 'Views/Components/RadioButton/StyledRadioButton';
import { fetchTransactionFees } from 'Util/_HumanWritten/FetchTransactionFees';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import {
	createFerryBooking,
	alterBooking,
	discardAlterations,
	removeFeeFromTransaction,
	FetchBookingsForCart,
	FetchFullBookingById,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	clearFerryBookingTransactionIdFromStorage,
	setFerryBookingTransactionIdInStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	stringNotEmpty, stringIsEmpty, isNotNullOrUndefined, isNullOrUndefined,
} from 'Util/TypeGuards';
import { useCheckInRoutes } from './useCheckInRoutes';
import {
	GetModalContentForNewBookingCheckIn,
} from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInList/CheckInListRow';
import { BookingFormMode } from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/BookingForm/BookingForm';

export function useCheckInReview() {
	const { isIpadMiniPortrait } = useScreenSize();
	const routes = useCheckInRoutes();
	const checkInStore = useCheckInStore();
	const locationState = store.routerHistory.location.state as CustomLocationState;
	const { formState } = checkInStore;

	/**
	 * If review is incomplete, we will update status of alteration and transaction to EXPIRED_RESERVATION and
	 * ABANDONED on unmounting of the component.
	 */
	const isReviewComplete = React.useRef(false);

	/**
	 * When mode is undefined, the component will navigate to check-in view. Otherwise, mode will not change during the
	 * component's lifecycle.
	 */
	const mode = locationState && isCheckInLocationState(locationState) && locationState.mode
		? locationState.mode
		: undefined;

	const applyToReturn = locationState && isCheckInLocationState(locationState) && locationState.applyToReturn
		? JSON.parse(locationState.applyToReturn) as boolean
		: false;

	const state = useLocalStore<{
		loading: boolean;
		error: boolean;
		/**
		 * Used for TripSummary.
		 */
		bookings?: BookingEntity[];
		/**
		 * Important for editing booking to show correct calculations.
		 */
		bookingToEdit?: BookingEntity | null;
	}>(() => ({ loading: false, error: false }));

	const fetchCartData = async (userId: string | null = null) => {
		if (!formState.existingTransactionId) {
			return;
		}
		try {
			const bookings = await FetchBookingsForCart(
				formState.existingTransactionId,
				userId,
			);
			runInAction(() => {
				state.bookings = bookings;
			});
		} catch (e) {
			console.error(e);
			alertToast('Something went wrong while fetching cart', 'error');
			store.routerHistory.replace(routes.base);
		}
	};

	const startTransaction = async () => {
		if (isNullOrUndefined(mode)) {
			return;
		}
		try {
			let response: AxiosResponse<any>;
			if (formState.existingTransactionId || mode === BookingFormMode.Edit) {
				//
				// Update alteration
				//
				response = await alterBooking({ ...formState, location: 'CHECK_IN' });
			} else if (mode === BookingFormMode.Add) {
				//
				// Create new alteration for new booking
				//
				response = await createFerryBooking({
					...formState,
					bookingId: undefined,
					location: 'CHECK_IN',
				});
			} else {
				throw new Error('FormState and mode are not configured correctly');
			}
			runInAction(() => {
				if (response) {
					if (response.status === 200) {
						const { transactionId, bookingId } = response.data;
						formState.existingTransactionId = transactionId;
						setFerryBookingTransactionIdInStorage(transactionId);
						formState.bookingId = bookingId;

						fetchCartData(formState.userId);
					} else {
						state.error = true;
					}
				}
			});
		} catch (e) {
			console.error(e);
			alertToast('Invalid booking', 'error');
			store.routerHistory.replace(routes.base);
		}
	};

	const start = async () => {
		await startTransaction();
		if (mode === BookingFormMode.Edit && stringNotEmpty(formState.bookingId)) {
			const bookingToEdit = await FetchFullBookingById(formState.bookingId, null);
			runInAction(() => {
				state.bookingToEdit = bookingToEdit;
			});
		}
	};

	const onCleanUp = async () => {
		if (
			!isReviewComplete.current
			&& mode === BookingFormMode.Edit
			&& isNotNullOrUndefined(formState)
			&& stringNotEmpty(formState.bookingId)
			&& stringNotEmpty(formState.existingTransactionId)
		) {
			try {
				await discardAlterations({ bookingIds: [formState.bookingId], systemExpired: false });
			} catch (e: any) {
				console.error(e);
			}
		}
		if (
			mode === BookingFormMode.Edit
			&& isNotNullOrUndefined(formState)
			&& stringNotEmpty(formState.bookingId)
			&& stringNotEmpty(formState.existingTransactionId)
		) {
			// We don't want editCartBooking when edit booking flow is not complete
			// So we remove the transactionId from formState
			runInAction(() => {
				formState.existingTransactionId = undefined;
				clearFerryBookingTransactionIdFromStorage();
			});
		}
	};

	const onBack = async () => {
		await onCleanUp();
		store.routerHistory.goBack();
	};

	React.useEffect(() => {
		// Navigate user to check-in page if no formState is available
		if (isNullOrUndefined(formState) || isNullOrUndefined(mode)) {
			store.routerHistory.replace(routes.base);
			return;
		}

		// Start component with creating a transaction for the booking
		start();

		const onNativeTabClose = (event: { preventDefault: () => void; returnValue: string; }) => {
			if (isNullOrUndefined(formState) || stringIsEmpty(formState.bookingId)) {
				return;
			}

			event.preventDefault();
			event.returnValue = '';

			// Two things:
			//   1. update alteration status from RESERVED to EXPIRED_RESERVATION
			//   2. update transaction status to ABANDONED
			discardAlterations({ bookingIds: [formState.bookingId], systemExpired: false })
				.then(() => {
					// This is a background task to clean up unwanted alteration. This is not the best approach,
					// but gives the best value given the time constraints.
				})
				.catch(e => {
					console.error(e);
				});

			return ('Are you sure you want to leave?');
		};

		// Trigger clean up when closing/refreshing native tab
		window.addEventListener('beforeunload', onNativeTabClose);

		// Trigger clean up when component unmounts
		return () => {
			window.removeEventListener('beforeunload', onNativeTabClose);
			onCleanUp();
		};
	}, []);

	const completeTransaction = async (
		existingTransactionId: string,
		bookingId: string,
		paymentMethod: paymentMethodType,
	) => {
		try {
			confirmModal(
				'Confirm booking?',
				GetModalContentForNewBookingCheckIn(formState),
				{},
			).then(async () => {
				await completeTransactionNoPayment(existingTransactionId, paymentMethod);

				await checkInStore.checkInBooking(bookingId, true, {
					refresh: true,
				});
				isReviewComplete.current = true;
				checkInStore.setFormState();
				store.routerHistory.replace(routes.base);
			}).catch(() => {
				runInAction(() => {
					state.loading = false;
				});
			});
		} catch (e) {
			console.error(e);
			runInAction(() => {
				state.loading = false;
			});
			alertToast('Cannot complete transaction', 'error');
		}
	};

	/**
	 * Complete transaction without payment.
	 * Update booking.checked status.
	 * Clean up form state.
	 * Navigate back to check-in view.
	 */
	const onConfirm = async (paymentMethod: paymentMethodType) => {
		const { existingTransactionId, bookingId } = formState;
		if (existingTransactionId && bookingId) {
			// If the admin has set `showTransactionPopup` to true in the backend, they will get a warning
			// pop-up before the transaction is completed. Otherwise the transaction will be completed without
			// confirmation form the user.
			if (whiteLabelStore?.config?.showTransactionPopUp ?? false) {
				confirmModal(
					'Has payment been taken?',
					'Have you taken payment either through eftpos or cash for this transaction?',
					{
						cancelText: 'No',
						confirmText: 'Yes',
					},
				).then(async () => {
					runInAction(() => {
						state.loading = true;
					});
					await completeTransaction(existingTransactionId, bookingId, paymentMethod);
				});
			} else {
				runInAction(() => {
					state.loading = true;
				});
				await completeTransaction(existingTransactionId, bookingId, paymentMethod);
			}
		}
	};

	const fetchGifts = async () => {
		const transaction = formState.existingTransactionId ?? '';
		try {
			const res = await fetchGiftCertificate(transaction);
			runInAction(() => {
				if (Array.isArray(res.data)) {
					formState.giftCertificateUsages = res.data;
				} else {
					formState.giftCertificateUsages = [];
				}
			});
		} catch (e) {
			console.error(e);
			runInAction(() => {
				formState.giftCertificateUsages = [];
			});
		}
	};

	const fetchFees = async () => {
		const transaction = formState.existingTransactionId ?? '';
		try {
			const res = await fetchTransactionFees(transaction);
			runInAction(() => {
				if (Array.isArray(res.data)) {
					formState.transactionFees = res.data;
				} else {
					formState.transactionFees = [];
				}
			});
		} catch (e) {
			console.error(e);
			runInAction(() => {
				formState.transactionFees = [];
			});
		}
	};

	const onClearFee = (feeId: string, transactionId?: string) => {
		removeFeeFromTransaction(transactionId ?? '', feeId)
			.then(_ => {
				fetchFees();
			})
			.catch(_ => {
				alertToast('Could not remove fee', 'error');
			});
	};

	return {
		isIpadMiniPortrait,
		state,
		mode,
		formState,
		applyToReturn,
		onConfirm,
		onBack,
		fetchGifts,
		fetchFees,
		onClearFee,
	};
}

/* eslint-disable dot-notation */
// Gets the price of a length type from a selected ferry trip
import { FerryTripEntity, PriceEntity } from '../../Models/Entities';
import { TicketsTabTrip } from '../../Services/Api/_HumanWritten/BookingWizardDataService';
import { measurementPrice } from '../../Services/CustomGql/FerryTripEntityType';
import { getMinimumMeasurementTypeForFerryTrip } from './MeasurementUtils';
import {
	isNotNullOrUndefined,
	isNullOrUndefined,
	stringIsEmpty,
	stringNotEmpty,
} from '../TypeGuards';

/**
 * This function will return price for a vehicle/towOn length ticket type from a ferry trip.
 * @param selectedFerryTrip: The ferry trip to get the length ticket price from.
 * @param selectedLengthTypeId: The length ticket type to get the price for.
 */
export const getLengthTypePriceFromFerryTrip = (
	selectedFerryTrip: FerryTripEntity | TicketsTabTrip | undefined,
	selectedLengthTypeId?: string,
) => {
	if (selectedFerryTrip === undefined || stringIsEmpty(selectedLengthTypeId)) {
		return 0;
	}
	const lengthTypeIdToUse = stringNotEmpty(selectedLengthTypeId) && selectedLengthTypeId !== ''
		? selectedLengthTypeId
		: getMinimumMeasurementTypeForFerryTrip(selectedFerryTrip)?.id;

	// If it is a ferry trip entity, get the price from the `dynamicMeasurementPrices` custom graphql
	// If there is no dynamic pricing, get the price directly from the pricing entities associated to
	// the ferry trip.
	const dynamicPricing = selectedFerryTrip['dynamicMeasurementPrices'];

	if (selectedFerryTrip instanceof FerryTripEntity) {
		return dynamicPricing
			?.find((x: measurementPrice) => x.measurementId === lengthTypeIdToUse)?.amount
			?? selectedFerryTrip?.prices
				?.find((x: PriceEntity) => x.measurementId === lengthTypeIdToUse)?.amount ?? 0;
	}

	// Else if it is a TicketsTabTrip (object), get the price from the calculated prices
	return dynamicPricing?.find((x: measurementPrice) => x.measurementId === lengthTypeIdToUse)?.amount
		?? selectedFerryTrip?.prices?.find(x => x.measurementId === lengthTypeIdToUse)?.amount
		?? 0;
};

export const getWeightTypePriceFromFerryTrip = (
	selectedFerryTrip: FerryTripEntity | TicketsTabTrip | undefined,
	selectedWeightId?: string,
) => {
	if (selectedFerryTrip === undefined || isNullOrUndefined(selectedWeightId)) {
		return 0;
	}
	const weightTypeIdToUse = isNotNullOrUndefined(selectedWeightId) && selectedWeightId !== ''
		? selectedWeightId
		: getMinimumMeasurementTypeForFerryTrip(selectedFerryTrip, 'WEIGHT')?.id;

	// If it is a ferry trip entity, get the price from the `dynamicMeasurementPrices` custom graphql
	// If there is no dynamic pricing, get the price directly from the pricing entities associated to
	// the ferry trip.
	const dynamicPricing = selectedFerryTrip['dynamicMeasurementPrices'];

	if (selectedFerryTrip instanceof FerryTripEntity) {
		return dynamicPricing
			?.find((x: measurementPrice) => x.measurementId === weightTypeIdToUse)?.amount
			?? selectedFerryTrip?.prices
				?.find((x: PriceEntity) => x.measurementId === weightTypeIdToUse)?.amount ?? 0;
	}

	// Else if it is a TicketsTabTrip (object), get the price from the calculated prices
	return dynamicPricing?.find((x: measurementPrice) => x.measurementId === weightTypeIdToUse)?.amount
		?? selectedFerryTrip?.prices?.find(x => x.measurementId === weightTypeIdToUse)?.amount
		?? 0;
};

export const getVehicleBasePriceDifference = (
	trip: FerryTripEntity | TicketsTabTrip,
	vehicleLengthId: string,
	oldVehiclePrice: number,
	vehicleWeightId: string,
	includePriceDifference: boolean = true,
) => {
	const newTicketLengthTypePrice = getLengthTypePriceFromFerryTrip(
		trip,
		vehicleLengthId,
	) + getWeightTypePriceFromFerryTrip(trip, vehicleWeightId);
	if (newTicketLengthTypePrice > oldVehiclePrice && includePriceDifference) {
		return newTicketLengthTypePrice - oldVehiclePrice + getWeightTypePriceFromFerryTrip(
			trip,
			getMinimumMeasurementTypeForFerryTrip(trip, 'WEIGHT').id,
		);
	}
	return 0;
};

export const getWeightPriceDifference = (
	trip: FerryTripEntity | TicketsTabTrip,
	oldWeightId: string,
	newWeightId: string,
) => {
	return getLengthTypePriceFromFerryTrip(trip, newWeightId) + getWeightTypePriceFromFerryTrip(trip, oldWeightId);
};

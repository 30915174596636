import React from 'react';
import {
	BookingEntity,
	EventDetailEntity,
} from 'Models/Entities';
import { observer } from 'mobx-react';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import {
	EventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import {
	eventBookingToWizardData,
} from 'Util/_HumanWritten/BookingWizard/EventBookingToWizardDataUtils';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { Link } from 'react-router-dom';
import If from 'Views/Components/If/If';
import classNames from 'classnames';
import { EventBreakdown } from './EventBreakdown';
import {
	EventBookingPricesDto,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';
import EventBookingSummaryTotal from './EventBookingSummaryTotal';

export interface EventBookingSummaryCardProps {
	wizardData?: EventBookingWizardData | null;
	onUpdateWizardData?: (newData: EventBookingWizardData) => void;
	booking: BookingEntity;
	refresh: () => void;
	tripSummaryLocation: tripSummaryLocationType;
	onClearGiftCertificate: (code: string) => void;
	/**
	 * The transaction ID will be used to check if the transaction has a credit card surcharge using an endpoint.
	 * If the endpoint returns false, then the credit card fee line will be hidden.
	 * If the endpoint returns true, or failed, the credit card fee line will be displayed.
	 */
	transactionId?: string;
	checkInReview?: boolean;
	applyCreditCardSurcharge?: boolean;
	eventDetails: EventDetailEntity;
	prices: EventBookingPricesDto | null;
	onClearFee?: (feeId: string) => void;
}

function EventBookingSummaryCard({
	wizardData,
	eventDetails,
	booking,
	tripSummaryLocation,
	transactionId,
	prices,
	onClearGiftCertificate,
	onClearFee,
}: EventBookingSummaryCardProps) {
	const afterPayment = tripSummaryLocation === 'booking-success';
	const cartView = tripSummaryLocation === 'cart' || tripSummaryLocation === 'check-in';

	if (isNullOrUndefined(booking)) {
		return <></>;
	}

	const bookingToDisplay = cartView || afterPayment
		? eventBookingToWizardData(
			booking,
		)
		: eventBookingToWizardData(
			booking,
		);

	if (isNullOrUndefined(booking)) {
		return (
			<div className="no-bookings-to-show">
				<h6>Nothing in the cart</h6>
			</div>
		);
	}

	const isCartView = cartView || afterPayment;
	const clazz = classNames('event-booking-summary-card-container', {
		'cart-view': isCartView,
		'sidebar-view': !isCartView,
	});

	return (
		<div className={clazz}>
			<div key={booking.id} className="event-card-section">
				<div className="event-booking-card-header">
					<h5>
						Booking {
							afterPayment && isNotNullOrUndefined(booking)
								? (
									<Link to={`/bookings/${booking.id}`}>
										#{booking.humanReadableId}
									</Link>
								)
								: 1
						}
					</h5>
					<If condition={
						!cartView
						&& !(!cartView
							&& (wizardData?.bookingToEditId === bookingToDisplay.bookingToEditId
								|| (bookingToDisplay.bookingToEditId === '')))
					}
					>
						<p className="line-item-price">$20.00</p>
					</If>
				</div>
				<If condition={
					afterPayment
					|| cartView
					|| (!cartView
						&& (wizardData?.bookingToEditId === bookingToDisplay.bookingToEditId
							|| (bookingToDisplay.bookingToEditId === '')))
				}
				>
					<EventBreakdown
						key="departure-breakdown"
						eventDetails={eventDetails}
						wizardData={bookingToDisplay}
						tripSummaryLocation={tripSummaryLocation}
						afterPayment={afterPayment}
						bookingToEdit={null}
						prices={prices}
					/>
				</If>
			</div>
			<EventBookingSummaryTotal
				prices={prices}
				afterPayment={afterPayment}
				cartView={cartView}
				wizardData={bookingToDisplay}
				transactionId={transactionId}
				tripSummaryLocation={tripSummaryLocation}
				onClearGiftCertificate={(code: string) => onClearGiftCertificate(code)}
				onClearFee={onClearFee}
			/>
		</div>
	);
}

export default observer(EventBookingSummaryCard);

import { FeeEntity } from '../../Models/Entities';
import { feeTypeOptions } from '../../Models/Enums';

export async function fetchTransactionFees(transactionId: string) {
	return FeeEntity.fetchAndCount<FeeEntity>({
		args: [
			[
				{
					path: 'transactionId',
					comparison: 'equal',
					value: transactionId,
				},
			],
			[
				{
					path: 'feeType',
					comparison: 'equal',
					value: feeTypeOptions.OTHER,
				},
			],
		],
	},
	`
		feeOption {
			id
			name
			key
			removable
		}
	`);
}

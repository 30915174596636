import { BookingEntity, FerryTripEntity } from '../../../../Models/Entities';
import { TicketsTabTrip } from '../../../../Services/Api/_HumanWritten/BookingWizardDataService';
import { isNotNullOrUndefined } from '../../../TypeGuards';
import { calculateBasePriceNewBooking } from '../CreationPriceCalculations/Creation_CalculateBasePrice';
import { calculateBasePriceAlterBooking } from '../AlterationPriceCalculations/Alteration_CalculateBasePrice';
import { PriceCalculationProps } from '../Helpers/PriceCalculationProps';

/**
 * This function will calculate the minimum ticket price from a list of ferry trips.
 * @param departureTrip: Whether the calculation is for the departure/return trip.
 * @param priceCalculationProps: The props needed for price calculations.
 * @param filteredFerryList: A filtered list of ferry trips to find the cheapest ticket price from.
 * @param bookingToEdit: The booking that is being edited (if in alteration mode).
 * Note: This is used in the TICKET SELECTION tab in the booking wizard.
 * It shows in the tab header to show the user the minimum ticket price for a day.
 * The minimum price is based on user selections (e.g. passengers).
 * @param afterPayment
 */
export const getMinimumFerryTicketPriceFromListOfFerryTrips = (
	departureTrip: boolean,
	priceCalculationProps: PriceCalculationProps,
	filteredFerryList: (FerryTripEntity | TicketsTabTrip)[],
	bookingToEdit: BookingEntity | null,
	afterPayment: boolean = false,
) => {
	const lowestTicketPriceForDay = filteredFerryList.length > 0 ? Math.min(...filteredFerryList.map(trip => {
		return (isNotNullOrUndefined(bookingToEdit) && isNotNullOrUndefined(bookingToEdit.id))
			/* Alteration mode */
			? calculateBasePriceAlterBooking(departureTrip, priceCalculationProps, trip, bookingToEdit, afterPayment)
			/* Create mode */
			: calculateBasePriceNewBooking(departureTrip, priceCalculationProps, trip);
	})) : 0;
	return Math.floor(lowestTicketPriceForDay);
};

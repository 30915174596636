import { calculateCartSubtotalAlterBooking } from './Alteration_CalculateCartSubtotal';
import { isNotNullOrUndefined } from '../../../TypeGuards';
import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from '../../../../Models/Entities';
import { BookingWizardCartFields } from '../../../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	tripSummaryLocationType,
} from '../../../../Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { calculateCreditCardSurcharge, getCreditCardSurcharge } from '../PriceFunctionSelectors/GetCreditCardSurcharge';
import { store } from '../../../../Models/Store';

/**
 * This function will calculate the cart total. This is the subtotal plus any credit card fees and minus any applied
 * gift certificates.
 */
export const calculateCartTotalAlterBooking = ({
	booking,
	giftCertificateUsages,
	transactionFees,
	ccSurchargeMultiplier,
	bookingToEdit,
	bookingsList,
	useRecentSummary,
	afterPayment,
	managerDiscount,
	beforeManagerOverride = false,
	includeGiftCertificateDiscounts = true,
	forEftpos = false,
	tripSummaryLocation,
}: calculateCartTotalAlterBookingParams) => {
	// Get subtotal of cart before any discounts, gift certificates or CC surcharges applied
	let runningTotal = calculateCartSubtotalAlterBooking(
		booking,
		bookingToEdit,
		useRecentSummary,
		afterPayment,
		forEftpos,
		tripSummaryLocation,
	);

	// Minus gift certificates applied to transaction (if any)
	if (includeGiftCertificateDiscounts) {
		giftCertificateUsages.forEach(usage => {
			runningTotal -= usage.usedValue;
		});
	}

	// Minus the manager discount (if any)
	if (isNotNullOrUndefined(managerDiscount) && !Number.isNaN(managerDiscount)) {
		runningTotal -= managerDiscount;
	}

	// This needs to be done before the CC surcharge is calculated
	transactionFees.forEach(fee => {
		runningTotal += fee.amount;
	});

	if (tripSummaryLocation === 'check-in' || tripSummaryLocation === 'booking-success') {
		// Calculate credit card surcharge after gift vouchers and manager discounts have been applied
		const creditCardSurcharge = tripSummaryLocation === 'check-in'
			? calculateCreditCardSurcharge(runningTotal, ccSurchargeMultiplier)
			: getCreditCardSurcharge(bookingToEdit);

		// If we want the total price AFTER manager price override then we add the credit card surcharge
		if (!beforeManagerOverride) {
			runningTotal += creditCardSurcharge;
		}
	}

	return runningTotal;
};

interface calculateCartTotalAlterBookingParams {
	/**
	 * The new booking information (includes booking wizard data and selected ferry trips).
	 */
	booking: BookingWizardCartFields;
	/**
	 * Any gift certicates the user has applied to the cart.
	 */
	giftCertificateUsages: GiftCertificateUsageEntity[];
	/**
	 * The percentage charged for credit card surcharge.
	 */
	ccSurchargeMultiplier: number;
	/**
	 * The booking that is being altered.
	 */
	bookingToEdit: BookingEntity;
	bookingsList?: BookingEntity[];
	useRecentSummary: boolean;
	/**
	 * If the calculation is being done after payment has been taken
	 */
	afterPayment: boolean;
	/**
	 * The amount of manager discount
	 */
	managerDiscount: number;
	/**
	 * If the calculation includes the manager override amount
	 */
	beforeManagerOverride?: boolean;
	/**
	 * Whether or not to include the gift certificate discounts in the price
	 */
	includeGiftCertificateDiscounts?: boolean;
	forEftpos?: boolean;
	includeCreditCardFee?: boolean;
	tripSummaryLocation: tripSummaryLocationType;
	transactionFees: FeeEntity[];
}

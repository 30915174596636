/**
 * Rectangular shape. Values are in rem.
 */
export const RegoPlateDimensions = {
	width: 20,
	height: 10,
} as const;

/**
 * Square shape. Values are in rem.
 */
export const QrCodeDimensions = {
	width: 20,
	height: 20,
} as const;

/**
 * Distance from top of screen to position the CameraFrame. Value is in rem.
 */
export const CameraFrameTopOffset = 10;

import React from 'react';
import { feeType, wizardModeOptions } from 'Models/Enums';
import { store } from 'Models/Store';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import If from 'Views/Components/If/If';
import { BookingWizardCartFields, BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { observer } from 'mobx-react';
import { useForInvoicingAsync } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { tripSummaryLocationType } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { Button } from 'Views/Components/Button/Button';
import { RenderAddServiceFeeModal } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/AddFeeModal';
import { getCartSubtotal } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartSubtotal';
import { getCartTotal } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartTotal';
import {
	BookingEntity,
	FeeEntity,
	FerryTripEntity,
	GiftCertificateUsageEntity,
} from 'Models/Entities';
import {
	filterReservedAlterations,
	sortAlterationsByDateCreated,
} from 'Util/_HumanWritten/AlterationSortingUtils';
import {
	getBaseFerryTicketPriceForFerryTrip,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetBaseFerryTicketPriceForFerryTrip';
import {
	CartCalculationLineItem,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/CartCalculationLineItem';
import AddedFeeLines from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/AddedFeeLines';
import GiftUsageLines from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/GiftUsageLines';
import CreditCardFeeLine from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/CreditCardFeeLine';
import GstLine from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/GstLine';

export interface TripBookingSummaryTotalProps {
	bookingsToDisplay: BookingWizardCartFields[];
	giftCertificateUsages: GiftCertificateUsageEntity[];
	transactionFees: FeeEntity[];
	fetchTransactionFees: (transactionId: string) => void;
	onClearFee?: (feeId: string) => void;
	bookingsList: BookingEntity[];
	bookingToEdit: BookingEntity | null;
	afterPayment: boolean;
	cartView: boolean;
	forEftpos: boolean;
	discountAmount: number;
	wizardData?: BookingWizardData;
	onClearGiftCertificate?: (code: string) => void;
	onAddManagerDiscount?: (
		bookings: BookingWizardCartFields[],
		afterPayment: boolean,
		totalFeesAppliedToBooking: number
	) => void;
	onRemoveManagerDiscount?: () => void;
	onRemoveFee?: (feeType: feeType, amount?: number, departurePercentage?: number, returnPercentage?: number) => void;
	/**
	 * The transaction ID will be used to check if the transaction has a credit card surcharge using an endpoint.
	 * If the endpoint returns false, then the credit card fee line will be hidden.
	 * If the endpoint returns true, or failed, the credit card fee line will be displayed.
	 */
	transactionId?: string;
	/**
	 * Whether or not to charge a credit card fee.
	 * When a user pays cash or has an invoiced booking we will not charge a credit card fee.
	 */
	applyCreditCardSurcharge?: boolean;
	tripSummaryLocation: tripSummaryLocationType;
}

export interface ManagerDiscountPercentage {
	totalAmount: number;
	departurePercentage: number;
	returnPercentage: number;
}

function TripBookingSummaryTotal({
	bookingsToDisplay,
	giftCertificateUsages,
	transactionFees,
	fetchTransactionFees,
	onClearFee,
	bookingsList,
	bookingToEdit,
	afterPayment,
	cartView,
	forEftpos,
	discountAmount,
	wizardData,
	onClearGiftCertificate,
	onRemoveManagerDiscount,
	onRemoveFee,
	transactionId,
	applyCreditCardSurcharge,
	tripSummaryLocation,
}: TripBookingSummaryTotalProps) {
	/**
	 * When transactionId is undefined, the response immediately returns data as undefined.
	 * So don't need to worry about render delays
	 */
	const bookingForInvoicingResponse = useForInvoicingAsync(bookingToEdit?.id);

	if (bookingForInvoicingResponse?.type === 'loading') {
		return null;
	}

	/**
	 * If response fails, we want this value to be false to display the cc fee.
	 */
	const forInvoicing = bookingForInvoicingResponse?.data === true;

	const forBulkBooking = wizardData?.bulkBookingTripIds !== undefined;

	const isCartView = cartView || afterPayment;

	const isAlteration = wizardData
		? wizardData?.wizardMode === wizardModeOptions.ALTERATION
		: bookingsList.length === 1 && bookingsList[0].alterations.length > 1;

	const totalAlterationFees = totalFeesAppliedToBooking(bookingsList, 'ALTERATION');
	const totalTrailerRemovalFees = totalFeesAppliedToBooking(bookingsList, 'CANCELLATION');
	const totalPriceIncrease = isNotNullOrUndefined(wizardData) ? calculateTicketPriceIncrease(
		wizardData,
		afterPayment,
		forEftpos,
		bookingToEdit,
		bookingsToDisplay[0].selectedTrips.departingTrip,
		bookingsToDisplay[0].selectedTrips.returningTrip,
	) : { totalAmount: 0, departurePercentage: 0, returnPercentage: 0 };

	const subtotalBookingCost: number = getCartSubtotal(
		bookingsToDisplay,
		afterPayment,
		tripSummaryLocation,
		!afterPayment,
		bookingToEdit,
		forEftpos,
	);

	const showAlterationFees = store.isManager
		&& (wizardData?.wizardMode === 'ALTERATION' || isAlteration)
		&& (totalAlterationFees > 0);

	const showTrailerRemovalFees = store.isManager
		&& (wizardData?.wizardMode === 'ALTERATION' || isAlteration)
		&& (totalTrailerRemovalFees > 0);

	const showManagerDiscount = bookingsList.length > 0
		&& discountAmount > 0
		&& (cartView || afterPayment);

	const checkIn = store?.routerHistory?.location?.pathname?.includes('check-in');
	return (
		<div className="total-booking-price">
			<CartCalculationLineItem
				keyToUse="subtotal-line-item-key"
				lineItemDisplayName={(cartView || afterPayment) ? 'Subtotal (inc. GST):' : 'TOTAL:'}
				lineItemPrice={formatPriceDisplay(
					subtotalBookingCost,
					(bookingsToDisplay[0]?.wizardData?.wizardMode === wizardModeOptions.ALTERATION
						|| wizardData?.wizardMode === wizardModeOptions.ALTERATION),
				)}
				className={`${
					cartView || afterPayment ? 'subtotal-line-item' : 'total-line-item'
				}`}
			/>
			<If condition={isCartView}>
				<AddedFeeLines
					fees={transactionFees}
					onRemoveFee={onClearFee}
				/>
				<If condition={showAlterationFees && !checkIn}>
					<CartCalculationLineItem
						keyToUse="alteration-fees-line-item"
						lineItemDisplayName="Alteration fees:"
						lineItemPrice={formatPriceDisplay(totalAlterationFees, false)}
						className="alteration-fees-line-item"
						hasRemovalButton={store.isManager}
						removalOnClick={() => {
							if (isNotNullOrUndefined(onRemoveFee)) {
								return onRemoveFee('ALTERATION');
							}
							return () => {};
						}}
					/>
				</If>
				<If condition={!wizardData?.priceIncreasesRemoved && !checkIn && totalPriceIncrease.totalAmount > 0}>
					<CartCalculationLineItem
						keyToUse="ticket-price-increase-line-item"
						lineItemDisplayName="Ticket price increase:"
						lineItemPrice={formatPriceDisplay(totalPriceIncrease.totalAmount, false)}
						className="ticket-price-increase-line-item"
						hasRemovalButton={store.isManager}
						removalOnClick={() => {
							if (isNotNullOrUndefined(onRemoveFee)) {
								return onRemoveFee(
									'OTHER',
									totalPriceIncrease.totalAmount,
									totalPriceIncrease.departurePercentage,
									totalPriceIncrease.returnPercentage,
								);
							}
							return () => { };
						}}
					/>
				</If>
				<If condition={showTrailerRemovalFees && !checkIn}>
					<CartCalculationLineItem
						keyToUse="trailer-removal-fees-line-item"
						lineItemDisplayName="Trailer removal fee:"
						lineItemPrice={formatPriceDisplay(totalTrailerRemovalFees, false)}
						className="trailer-removal-fees-line-item"
						hasRemovalButton={store.isManager}
						removalOnClick={() => {
							if (isNotNullOrUndefined(onRemoveFee)) {
								return onRemoveFee('CANCELLATION');
							}
							return () => { };
						}}
					/>
				</If>
				<If condition={showManagerDiscount}>
					<CartCalculationLineItem
						keyToUse="manager-discount-line-item"
						lineItemDisplayName="Manager discount:"
						lineItemPrice={`-${formatPriceDisplay(
							discountAmount,
							// need to set this to false so that a + sign is not applied automatically
							false,
						)}`}
						className="manager-discount-line-item"
						hasRemovalButton={store.isManager}
						removalOnClick={onRemoveManagerDiscount}
					/>
				</If>
				<GiftUsageLines
					giftCertificateUsages={giftCertificateUsages}
					onClearGiftCertificate={onClearGiftCertificate}
				/>
				<If condition={!forInvoicing && (tripSummaryLocation === 'booking-success' || tripSummaryLocation === 'check-in')}>
					<CreditCardFeeLine
						bookingsToDisplay={bookingsToDisplay}
						giftCertificateUsages={giftCertificateUsages}
						transactionFees={transactionFees}
						bookingToEdit={bookingToEdit}
						bookingsList={bookingsList}
						discountAmount={discountAmount}
						afterPayment={afterPayment}
						forEftpos={forEftpos}
						wizardData={wizardData}
						tripSummaryLocation={tripSummaryLocation}
					/>
				</If>
				<GstLine
					subtotalBookingCost={subtotalBookingCost}
					bookingsToDisplay={bookingsToDisplay}
					giftCertificateUsages={giftCertificateUsages}
					transactionFees={transactionFees}
					bookingToEdit={bookingToEdit}
					bookingsList={bookingsList}
					discountAmount={discountAmount}
					afterPayment={afterPayment}
					forEftpos={forEftpos}
					tripSummaryLocation={tripSummaryLocation}
				/>
				<CartCalculationLineItem
					keyToUse="total-line-item-key"
					lineItemDisplayName="TOTAL (inc. GST):"
					lineItemPrice={formatPriceDisplay(
						getCartTotal({
							bookingList: bookingsToDisplay,
							bookingsList: bookingsList,
							giftCertificateUsages: giftCertificateUsages,
							transactionFees,
							ccSurchargeMultiplier: forInvoicing ? 0 : store?.ccSurchargeMultiplier,
							afterPayment: afterPayment,
							useRecentSummary: !afterPayment,
							bookingToEdit: bookingToEdit,
							beforeManagerOverride: false,
							managerDiscount: discountAmount,
							forEftpos,
							tripSummaryLocation,
						}),
						isAlteration,
					)}
					className="cart-total-line-item total-cart-line"
				/>
				<If condition={store.isStaff && (tripSummaryLocation === 'cart' || tripSummaryLocation === 'check-in')}>
					<Button
						className="override-total-amount-button"
						onClick={() => {
							RenderAddServiceFeeModal(
								subtotalBookingCost,
								tripSummaryLocation,
								wizardData?.wizardMode ?? 'CREATE',
								transactionFees,
								transactionId,
							).then(async () => {
								fetchTransactionFees(transactionId ?? '');
							});
						}}
					>
						Add service fee
					</Button>
				</If>

			</If>
		</div>
	);
}

export default observer(TripBookingSummaryTotal);

function totalFeesAppliedToBooking(bookingsList: BookingEntity[], feeTypeToCalculate: feeType) {
	if (bookingsList.length > 0) {
		const departureFees = filterReservedAlterations(
			sortAlterationsByDateCreated(bookingsList[0]?.alterations),
		)[0]?.fees?.filter(x => x.feeType === feeTypeToCalculate) ?? null;

		const departureTripAlterationFees = departureFees?.reduce(
			(accumulator, current) => current.amount + accumulator, 0,
		) ?? 0;

		let returnTripAlterationFee = 0;
		if (
			bookingsList[0]?.desynced === false
			&& (bookingsList[0].returnBooking || bookingsList[0].returnBookingFor)) {
			const returnBooking = bookingsList[0].returnBooking ?? bookingsList[0].returnBookingFor;
			const returnFees = filterReservedAlterations(
				sortAlterationsByDateCreated(returnBooking.alterations),
			)[0]?.fees?.filter(x => x.feeType === feeTypeToCalculate) ?? null;

			returnTripAlterationFee = returnFees?.reduce(
				(accumulator, current) => current.amount + accumulator, 0,
			) ?? 0;
		}
		return departureTripAlterationFees + returnTripAlterationFee;
	}
	return 0;
}

function calculateTicketPriceIncrease(
	wizardData: BookingWizardData,
	afterPayment: boolean,
	forEftpos: boolean,
	bookingToEdit: BookingEntity | null,
	departingTrip?: FerryTripEntity,
	returningTrip?: FerryTripEntity,
) {
	let beforeDepartureTotal = 0;
	let afterDepartureTotal = 0;
	let beforeReturnTotal = 0;
	let afterReturnTotal = 0;
	if (isNotNullOrUndefined(departingTrip)) {
		beforeDepartureTotal += getBaseFerryTicketPriceForFerryTrip(
			true,
			wizardData,
			departingTrip,
			afterPayment,
			bookingToEdit,
			forEftpos,
			false,
		);
		afterDepartureTotal += getBaseFerryTicketPriceForFerryTrip(
			true,
			wizardData,
			departingTrip,
			afterPayment,
			bookingToEdit,
			forEftpos,
			true,
		);
	}
	if (isNotNullOrUndefined(returningTrip)) {
		beforeReturnTotal += getBaseFerryTicketPriceForFerryTrip(
			false,
			wizardData,
			returningTrip,
			afterPayment,
			bookingToEdit,
			forEftpos,
			false,
		);
		afterReturnTotal += getBaseFerryTicketPriceForFerryTrip(
			false,
			wizardData,
			returningTrip,
			afterPayment,
			bookingToEdit,
			forEftpos,
			true,
		);
	}

	const departingPriceIncrease = afterDepartureTotal - beforeDepartureTotal;
	const returningPriceIncrease = afterReturnTotal - beforeReturnTotal;
	const totalAmount = departingPriceIncrease + returningPriceIncrease;

	const departurePercentage = departingPriceIncrease / (departingPriceIncrease + returningPriceIncrease);
	const returnPercentage = returningPriceIncrease / (departingPriceIncrease + returningPriceIncrease);

	return {
		departurePercentage, returnPercentage, totalAmount,
	} as ManagerDiscountPercentage;
}

import passengerTypeStore from 'Models/PassengerTypeStore';
import { getMeasurementValueFromId, getMinimumMeasurementFromStore } from 'Util/_HumanWritten/MeasurementUtils';
import { isNotNullOrUndefined } from '../TypeGuards';
import { measurementType, measurementTypeOptions } from 'Models/Enums';
import { store } from 'Models/Store';
import { FerryTripEntity } from 'Models/Entities';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export type FerryTripAvailability = {
	vehicle: number;
	trailer: number;
	passenger: number;
	totalVehicle: number;
	totalPassengers: number;
	totalTrailers: number;
	measurementType: measurementType;
};

export interface SpaceExceeded {
	vehicleLengthExceeded: boolean;
	trailerSpaceExceeded: boolean;
}

export function getTrailerLength(wizardData: BookingWizardData): number {
	if (wizardData.trailerCheckboxSelected) {
		// When trailer checkbox is selected, ensure the return value is at least the minimum length type length
		return wizardData.trailerLengthId === ''
			? getMinimumMeasurementFromStore()?.value
			: getMeasurementValueFromId(wizardData.trailerLengthId);
	}

	return 0;
}

/**
 * Calculates whether the vehicle space and trailer space have been exceeded.
 */
export function calculateSpacesExceeded(
	remainingVehicleSpace: number,
	remainingTrailerSpace: number,
	measurementTypeOption: measurementType,
	wizardData: BookingWizardData,
	oldWizardData?: BookingWizardData | null,
): SpaceExceeded {
	// Get the length for the new wizard data vehicle and trailer length
	let newVehicleSpace = isNotNullOrUndefined(wizardData) && wizardData?.tabSelected === 'vehicle'
		? getMeasurementValueFromId(wizardData?.vehicleLengthId)
		: 0;
	let newTrailerSpace = isNotNullOrUndefined(wizardData) && wizardData?.trailerCheckboxSelected
		? getMeasurementValueFromId(wizardData?.trailerLengthId, true)
		: 0;

	// If the measurement type is weight, get the weight for the vehicle and trailer
	if (measurementTypeOption === measurementTypeOptions.WEIGHT) {
		const minimumWeightSpace = getMinimumMeasurementFromStore('WEIGHT')?.value ?? 2500;
		newVehicleSpace = getMeasurementValueFromId(wizardData.vehicleWeightId) / minimumWeightSpace;
		// Trailers are always the minimum weight type
		newTrailerSpace = minimumWeightSpace / minimumWeightSpace;
	}

	// Passenger space is not used, but keeping it commented here might be handy to resolve a future bug
	// const oldPassengerSpace = isNotNullOrUndefined(oldWizardData) ? calculateTotalPassengers(oldWizardData) : 0;

	const totalSelectedSpace = newVehicleSpace + newTrailerSpace;

	return {
		vehicleLengthExceeded: totalSelectedSpace > remainingVehicleSpace,
		trailerSpaceExceeded: newTrailerSpace > remainingTrailerSpace,
	};
}

export function calculateTotalPassengers(wizardData: BookingWizardData) {
	return passengerTypeStore.getTotalFromWizardData(wizardData);
}

// This will determine the availabilty of a ferry trip using the FerryTrip Entity
export function calculateAvailabilityFromFerryTrip(ferryTrip: FerryTripEntity): FerryTripAvailability {
	const vehicleLengthRemaining = ferryTrip.vehicleSpaceCapacity.remaining ?? 0;
	const trailerSpaceRemaining = ferryTrip.vehicleSpaceCapacity.trailerRemaining ?? 0;
	const totalPassengerSpaces = ferryTrip.ferry.passengerCapacity;
	const totalTrailerSpaces = ferryTrip.ferry.towingCapacity;

	// If a ferry has a max weight capacity assigned to it, we need to determine whether there is more weight spaces
	// available or more length spaces available.
	if (ferryTrip.ferry.weightCapacity !== 0 && ferryTrip.ferry.weightCapacity != null) {
		const minimumWeightMeasurement = store.measurements
			.filter(x => x.measurementType === 'WEIGHT')
			.sort((w1, w2) => w1.value > w2.value ? 1 : -1)[0];
		const weightSpacesRemaining = (ferryTrip.weightSpaceCapacity?.remaining ?? 0)
			/ (minimumWeightMeasurement?.value ?? 1);

		let vehicleSpacesRemaining = vehicleLengthRemaining;
		let totalVehicleSpaces = ferryTrip.ferry.vehicleCapacity;
		let measurementTypeBeingUsedToCalculate = measurementTypeOptions.LENGTH;

		if (weightSpacesRemaining < vehicleLengthRemaining) {
			vehicleSpacesRemaining = weightSpacesRemaining;
			totalVehicleSpaces = ferryTrip.ferry.weightCapacity / (minimumWeightMeasurement?.value ?? 1);
			measurementTypeBeingUsedToCalculate = measurementTypeOptions.WEIGHT;
		}

		return {
			vehicle: Math.floor(vehicleSpacesRemaining),
			passenger: Math.floor(ferryTrip.passengerSpaceCapacity.remaining ?? 0),
			trailer: Math.floor(Math.min(weightSpacesRemaining / 2, trailerSpaceRemaining)),
			totalVehicle: totalVehicleSpaces,
			totalPassengers: totalPassengerSpaces,
			totalTrailers: totalTrailerSpaces,
			measurementType: measurementTypeBeingUsedToCalculate as measurementType,
		};
	}

	// Else just return the length space capacity available
	return {
		vehicle: Math.max(Math.floor(vehicleLengthRemaining)),
		passenger: Math.max(Math.floor(ferryTrip.passengerSpaceCapacity.remaining ?? 0)),
		trailer: Math.max(Math.floor(trailerSpaceRemaining)),
		totalVehicle: ferryTrip.ferry.vehicleCapacity,
		totalPassengers: totalPassengerSpaces,
		totalTrailers: totalTrailerSpaces,
		measurementType: measurementTypeOptions.LENGTH as measurementType,
	};
}

// This will determine the availabilty of a ferry trip using the TicketTabTrip Entity
export function calculateAvailabilityFromTicketTabTrip(ferryTrip: TicketsTabTrip): FerryTripAvailability {
	const vehicleLengthRemaining = ferryTrip.vehicleSpacesAvailable ?? 0;
	const trailerSpaceRemaining = ferryTrip.trailerSpotsAvailable ?? 0;
	const totalPassengerSpaces = ferryTrip.passengerSpacesTotal ?? 0;
	const totalTrailerSpaces = ferryTrip.trailerSpotsTotal;

	// If a ferry has a max weight capacity assigned to it, we need to determine whether there is more weight spaces
	// available or more length spaces available.
	if (ferryTrip.totalWeight !== 0 && ferryTrip.totalWeight != null) {
		const minimumWeightMeasurement = store.measurements
			.filter(x => x.measurementType === 'WEIGHT')
			.sort((w1, w2) => w1.value > w2.value ? 1 : -1)[0];
		const weightSpacesRemaining = (ferryTrip.weightAvailable ?? 0) / (minimumWeightMeasurement?.value ?? 1);
		let vehicleSpacesRemaining = vehicleLengthRemaining;
		let totalVehicleSpaces = ferryTrip.vehicleSpacesTotal;
		let measurementTypeBeingUsedToCalculate = measurementTypeOptions.LENGTH;

		if (weightSpacesRemaining < vehicleLengthRemaining) {
			vehicleSpacesRemaining = weightSpacesRemaining;
			totalVehicleSpaces = ferryTrip.totalWeight / (minimumWeightMeasurement?.value ?? 1);
			measurementTypeBeingUsedToCalculate = measurementTypeOptions.WEIGHT;
		}

		return {
			vehicle: Math.floor(vehicleSpacesRemaining),
			passenger: Math.floor(ferryTrip.passengerSpacesAvailable ?? 0),
			trailer: Math.floor(Math.min(weightSpacesRemaining / 2, trailerSpaceRemaining)),
			totalVehicle: totalVehicleSpaces,
			totalPassengers: totalPassengerSpaces,
			totalTrailers: totalTrailerSpaces,
			measurementType: measurementTypeBeingUsedToCalculate as measurementType,
		};
	}

	// Else just return the length space capacity available
	return {
		vehicle: Math.floor(vehicleLengthRemaining),
		passenger: Math.floor(ferryTrip.passengerSpacesAvailable ?? 0),
		trailer: Math.floor(trailerSpaceRemaining),
		totalVehicle: ferryTrip.vehicleSpacesTotal,
		totalPassengers: totalPassengerSpaces,
		totalTrailers: totalTrailerSpaces,
		measurementType: measurementTypeOptions.LENGTH as measurementType,
	};
}

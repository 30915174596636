import { FerryTripEntity } from 'Models/Entities';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../TypeGuards';
import { getWeightTypePriceFromFerryTrip } from '../../VehicleTrailerTicketTypeUtils';
import { getMeasurementValueFromId } from '../../MeasurementUtils';

export const FindVehicleWeightDifferencePrice = (
	oldFerryTrip: FerryTripEntity,
	newFerryTrip: FerryTripEntity,
	newWeightId?: string,
	oldWeightId?: string,
	oldPricePaid?: number,
) => {
	const newWeightNewFerryTripPrice = isNotNullOrUndefined(newWeightId)
		? getWeightTypePriceFromFerryTrip(newFerryTrip, newWeightId)
		: 0;
	const oldWeightNewFerryTripPrice = isNotNullOrUndefined(oldWeightId)
		? getWeightTypePriceFromFerryTrip(newFerryTrip, oldWeightId)
		: 0;

	// Added towOn (can't add a cargo... once a cargo booking, always a cargo booking)
	if (isNullOrUndefined(oldWeightId) || oldPricePaid == null) {
		return newWeightNewFerryTripPrice;
	}

	// Removed towOn (can't remove a cargo... once a cargo booking, always a cargo booking)
	if (isNullOrUndefined(newWeightId)) {
		return -(oldWeightNewFerryTripPrice ?? 0);
	}

	// UNCHANGED FERRY TRIP - UNCHANGED LENGTH
	// Regardless if there is a price change due to dynamic pricing
	// we don't want the user to be charges that price difference
	// as they haven't made any changes to ferry trip tickets or lengths
	if (oldFerryTrip?.id === newFerryTrip?.id
		&& newWeightId === oldWeightId) {
		return 0;
	}

	// UNCHANGED FERRY TRIP - CHANGED WEIGHT
	// Need to find the difference between the old price paid for
	// the cargo weight and the new price for the new weight
	if (oldFerryTrip?.id === newFerryTrip?.id
		&& newWeightId !== oldWeightId) {
		return newWeightNewFerryTripPrice - (oldWeightNewFerryTripPrice ?? 0);
	}

	// CHANGED FERRY TRIP - UNCHANGED WEIGHT
	// This price difference is in the base ticket price
	// as we don't want users to know exactly what the price
	// increase is on a new ferry ticket
	if (oldFerryTrip?.id !== newFerryTrip?.id
		&& newWeightId === oldWeightId) {
		return 0;
	}

	// CHANGED FERRY TRIP - INCREASED WEIGHT
	// When the weight is increased the line item will only show the price
	// difference between the price of the new weight on the new ferry trip
	// and the price of the old weight on the new ferry trip
	// Note: The price difference between the old weight old price and the
	// old weight new price will be in the base ticket price and therefore
	// we don't want to double up on the same price
	if (oldFerryTrip?.id !== newFerryTrip?.id
		&& isNotNullOrUndefined(newWeightId)
		&& isNotNullOrUndefined(oldWeightId)
		&& getMeasurementValueFromId(newWeightId) > getMeasurementValueFromId(oldWeightId)) {
		if (newWeightNewFerryTripPrice <= oldWeightNewFerryTripPrice) {
			return 0;
		}
		return newWeightNewFerryTripPrice - oldWeightNewFerryTripPrice;
	}

	// CHANGED FERRY TRIP - DECREASED WEIGHT
	// Calculations the price difference between the old weight price on new ferry trip
	// and the new weight price on new ferry trip to determine how much of a refund the user will get
	if (oldFerryTrip?.id !== newFerryTrip?.id
		&& isNotNullOrUndefined(newWeightId)
		&& isNotNullOrUndefined(oldWeightId)
		&& getMeasurementValueFromId(newWeightId) < getMeasurementValueFromId(oldWeightId)) {
		return newWeightNewFerryTripPrice - oldWeightNewFerryTripPrice;
	}
	return 0;
};

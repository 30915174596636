import { getTruncatedPrice } from '../../PriceUtils';
import { BookingEntity } from '../../../../Models/Entities';
import { isNullOrUndefined } from '../../../TypeGuards';
import { getMostRecentBookedOrReservedAlteration } from '../../AlterationSortingUtils';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';

/**
 * This function will get the credit card surcharge from the booking to edit.
 * @param bookingToEdit - The booking to get the credit card surcharge from.
 */
export const getCreditCardSurcharge = (bookingToEdit: BookingEntity | null): number => {
	if (isNullOrUndefined(bookingToEdit)) {
		return 0;
	}

	const creditCardSurcharge = getMostRecentBookedOrReservedAlteration(bookingToEdit.alterations)?.transaction.feess
		?.find(x => x.feeType === 'CREDIT_CARD_SURCHARGE')?.amount ?? 0;

	return getTruncatedPrice(Math.abs(creditCardSurcharge), true);
};

export const calculateCreditCardSurcharge = (totalCartPrice: number, ccSurchargeMultiplier: number): number => {
	if (totalCartPrice < 0) {
		return 0;
	}

	return getTruncatedPrice(Math.abs(totalCartPrice * ccSurchargeMultiplier), true);
};

import React from 'react';
import { BookingWizardCartFields, BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import useStore from 'Hooks/useStore';
import { useEffect, useState } from 'react';
import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from 'Models/Entities';
import { Button } from 'Views/Components/Button/Button';
import TripBookingSummaryCard, { tripSummaryLocationType } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { updatePreviousFerryBookingPriceInStore } from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { feeType, wizardModeOptions } from 'Models/Enums';
import { fetchGiftCertificateUsagesByTransactionId } from 'Util/_HumanWritten/FetchGiftCertificates';
import { removeGiftCertificateFromTransaction } from 'Services/Api/_HumanWritten/GiftCertificateService';
import alertToast from 'Util/ToastifyUtils';
import If from 'Views/Components/If/If';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { Link } from 'react-router-dom';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { getMostRecentBookedAlteration } from 'Util/_HumanWritten/AlterationSortingUtils';
import { fetchTransactionFees } from 'Util/_HumanWritten/FetchTransactionFees';
import { getCartSubtotal } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartSubtotal';
import { getCartTotal } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartTotal';
import {
	RenderOverrideTotalModal,
} from 'Views/Components/_HumanWritten/Bookings/BookingDetailedView/Modals/OverrideTotalModal';
import {
	FetchBookingsForCartAsync,
	removeFee,
	removeFeeFromTransaction,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	getFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	addManagerDiscount,
	getTotalManagerDiscountFromTransaction, removeManagerDiscount,
} from 'Services/Api/_HumanWritten/ManagerBookingDiscountService';
import {
	ferryBookingToWizardData,
} from 'Util/_HumanWritten/BookingWizard/FerryBookingToWizardDataUtils';
import { FerryPromoGiftCard } from './FerryPromoGiftCard';

export interface CartSummaryProps {
	wizardData: BookingWizardData;
	selectedTrips: SelectedTrips;
	onUpdateWizardData: (newData: BookingWizardData) => void;
	setSelectedTrips: (trips: SelectedTrips) => void;
	onAddNewBooking: (data: BookingWizardData) => void;
	setRefresh: (refresh: boolean) => void;
	refresh: boolean;
	bookingToEdit: BookingEntity | null;
	tripSummaryLocation: tripSummaryLocationType;
	setTabIsLoading?: (isLoading: boolean) => void;
}

export function CartSummary({
	wizardData,
	selectedTrips,
	onUpdateWizardData,
	setSelectedTrips,
	onAddNewBooking,
	setRefresh,
	refresh,
	bookingToEdit = null,
	tripSummaryLocation,
	setTabIsLoading,
}: CartSummaryProps) {
	const store = useStore();
	const [discountAmount, setDiscountAmount] = useState<number>(0);
	const [bookingList, setBookingList] = useState<BookingEntity[]>([]);
	const [giftCertificateUsages, setGiftCertificateUsages] = useState<GiftCertificateUsageEntity[]>([]);
	const [transactionFees, setTransactionFees] = useState<FeeEntity[]>([]);

	const transactionId = getFerryBookingTransactionIdFromStorage();

	const response = FetchBookingsForCartAsync(
		transactionId,
		wizardData.userId,
	);

	const onClearGiftCertificate = (code: string) => {
		removeGiftCertificateFromTransaction(transactionId ?? '', code, false)
			.then(_ => {
				alertToast('Removed gift certificate', 'success');
				fetchGiftCertificates();
			})
			.catch(_ => {
				alertToast('Could not remove gift certificate', 'error');
			});
	};
	const fetchGiftCertificates = () => {
		if (isNotNullOrUndefined(transactionId)) {
			fetchGiftCertificateUsagesByTransactionId(transactionId ?? '')
				.then(usages => {
					setGiftCertificateUsages(usages.data ?? []);
				})
				.catch(_ => {
					setGiftCertificateUsages([]);
				});
		}
		setGiftCertificateUsages([]);
	};

	const fetchFeesOnTransaction = async () => {
		if (isNotNullOrUndefined(transactionId)) {
			fetchTransactionFees(transactionId ?? '')
				.then(x => {
					setTransactionFees(x.data ?? []);
				})
				.catch(_ => {
					setTransactionFees([]);
				});
			fetchGiftCertificates();
		} else {
			setTransactionFees([]);
		}
	};
	const onClearFee = (feeId: string) => {
		removeFeeFromTransaction(transactionId ?? '', feeId)
			.then(_ => {
				fetchFeesOnTransaction();
			})
			.catch(_ => {
				alertToast('Could not remove fee', 'error');
			});
	};

	const onRemoveManagerDiscount = async () => {
		if (isNotNullOrUndefined(transactionId)) {
			try {
				const newData = { ...wizardData };
				await removeManagerDiscount({
					transactionId: transactionId,
					discountedAmount: 0,
				});
				const amount = await fetchTotalManagerDiscounts();
				setDiscountAmount(amount);
				fetchGiftCertificates();
				newData.priceIncreasesRemoved = false;
				onUpdateWizardData(newData);
				response.refresh();
			} catch {
				alertToast('Could not remove manager discount from transaction', 'error');
			}
		}
	};

	const onRemoveFee = async (
		feeToRemove: feeType,
		discountedAmount: number = 0,
		departingPercentage?: number,
		returningPercentage?: number,
	) => {
		if (isNotNullOrUndefined(transactionId)) {
			try {
				const newData = { ...wizardData };
				if (feeToRemove === 'OTHER') {
					try {
						await addManagerDiscount({
							transactionId,
							discountedAmount,
							departingPercentage,
							returningPercentage,
						});
						const appliedManagerDiscounts = await fetchTotalManagerDiscounts();
						setDiscountAmount(appliedManagerDiscounts);
						fetchGiftCertificates();
						newData.priceIncreasesRemoved = true;
					} catch {
						alertToast('Could not add manager discount to transaction', 'error');
					}
				} else {
					await removeFee(transactionId, feeToRemove);
					if (feeToRemove === 'CANCELLATION') {
						newData.cancellationFeesRemoved = true;
					}
					if (feeToRemove === 'ALTERATION') {
						newData.alterationFeesRemoved = true;
					}
				}
				onUpdateWizardData(newData);
				response.refresh();
			} catch {
				alertToast('Could not remove trailer removal fee from alteration', 'error');
			}
		}
	};
	const fetchTotalManagerDiscounts = async () => {
		let price = 0;
		if (isNotNullOrUndefined(transactionId)) {
			price = await getTotalManagerDiscountFromTransaction(transactionId);
		} else if (isNotNullOrUndefined(bookingToEdit) && discountAmount === 0) {
			const departureManagerDiscounts = getMostRecentBookedAlteration(
				bookingToEdit.alterations,
			)?.managerBookingDiscounts;
			departureManagerDiscounts.forEach(x => {
				price += x.discountAmount;
			});
			if (!bookingToEdit.desynced && isNotNullOrUndefined(bookingToEdit.returnBooking)) {
				const returnManagerDiscounts = getMostRecentBookedAlteration(
					bookingToEdit.returnBooking.alterations,
				)?.managerBookingDiscounts;
				returnManagerDiscounts.forEach(x => {
					price += x.discountAmount;
				});
			}
		}
		return price;
	};

	useEffect(() => {
		if (!(response.type === 'loading' || response.type === 'error')) {
			if (setTabIsLoading) {
				setTabIsLoading(true);
			}
			// Calculate the previous booking prices here for the cart
			const previousBookings = (response.data?.length ?? 0) > 0 ? ferryBookingToWizardData(
				response.data,
				undefined,
				undefined,
				wizardData.wizardMode === 'ALTERATION',
				(wizardData.departureTrip === undefined || wizardData.returningTicketId !== ''),
			) : [];

			if (isNotNullOrUndefined(transactionId)) {
				updatePreviousFerryBookingPriceInStore(
					previousBookings,
					store,
					0,
					giftCertificateUsages,
					transactionFees,
					tripSummaryLocation,
					discountAmount,
					bookingToEdit,
				);
			}

			// set the current booking price for the cart to 0 (As the current one is now in the previous bookings)
			store.setCartPriceDifference(0);

			// Important to save the cost of each booking, because user might decide to edit a booking from the cart,
			// which will change the total cart price
			for (const x of previousBookings) {
				store.updateBookingPrice(
					x.wizardData.bookingToEdit,
					getCartSubtotal([x], false, tripSummaryLocation, undefined, bookingToEdit),
				);
			}
			if (setTabIsLoading) {
				setTabIsLoading(false);
			}
		}
	}, [response.data, response.type, store, discountAmount, giftCertificateUsages, transactionFees]);

	useEffect(() => {
		if (response.type === 'data') {
			setBookingList(response.data);
			if (setTabIsLoading) {
				setTabIsLoading(false);
			}
		}
	}, [setBookingList, response.data, response.type]);

	useEffect(() => {
		fetchGiftCertificates();
		fetchFeesOnTransaction();
		if (store.isManager) {
			fetchTotalManagerDiscounts()
				.then(x => {
					setDiscountAmount(x);
					return addManagerDiscount({
						discountedAmount: x,
						transactionId: transactionId ?? '',
					});
				})
				.catch(x => {
					setDiscountAmount(0);
					console.error('Error when applying manager discount', x);
				});
		}
	}, []);

	useEffect(() => {
		setRefresh(!refresh);
	}, [giftCertificateUsages, transactionFees, discountAmount]);

	if (response.type === 'loading' || response.type === 'error') {
		return <LottieSpinner />;
	}

	let stylingToUse = 'booking-wizard__tab-header cart-summary-heading';
	if (wizardData.wizardMode === wizardModeOptions.ALTERATION) {
		stylingToUse = 'booking-wizard__tab-header cart-summary-heading-alteration';
	}

	return (
		<div className="cart-summary-container">
			<h2 className={stylingToUse}>Review your cart
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION}>
					<Link to={`/bookings/${wizardData?.bookingToEdit ?? ''}`}>
						<Icon name="cross" classname="icon" />
					</Link>
				</If>
			</h2>
			<div className="pre-cart-items">
				<div className={`add-another-booking-section ${
					wizardData.wizardMode === wizardModeOptions.ALTERATION
					|| wizardData.bulkBookingTripIds !== undefined
						? 'hide'
						: ''
				}`}
				>
					<Button
						className="add-another-booking-button icon-add icon-right hide-underline"
						onClick={() => {
							onAddNewBooking(wizardData);
						}}
					>
						Add another booking
					</Button>
				</div>
				<FerryPromoGiftCard
					transactionId={transactionId ?? ''}
					onAddGiftCertificate={() => {
						fetchGiftCertificates();
					}}
				/>
			</div>
			<TripBookingSummaryCard
				wizardData={wizardData}
				transactionId={transactionId ?? undefined}
				onUpdateWizardData={onUpdateWizardData}
				selectedTrips={selectedTrips}
				setSelectedTrips={setSelectedTrips}
				bookings={bookingList}
				refresh={response.refresh}
				bookingToEdit={wizardData.wizardMode === 'ALTERATION' ? bookingToEdit : null}
				giftCertificateUsages={giftCertificateUsages}
				transactionFees={transactionFees}
				fetchTransactionFees={fetchFeesOnTransaction}
				fetchGiftCertificates={fetchGiftCertificates}
				onClearFee={feeId => onClearFee(feeId)}
				discountAmount={discountAmount}
				onClearGiftCertificate={code => onClearGiftCertificate(code)}
				onAddManagerDiscount={async (
					bookingsToDisplay: BookingWizardCartFields[],
					afterPayment: boolean,
					totalFeesAppliedToBooking: number,
				) => {
					if (isNotNullOrUndefined(transactionId) && store.isManager) {
						try {
							const newDiscount = await RenderOverrideTotalModal(
								transactionId,
								getCartTotal({
									bookingList: bookingsToDisplay,
									giftCertificateUsages,
									transactionFees,
									ccSurchargeMultiplier: 0,
									afterPayment,
									useRecentSummary: !afterPayment,
									bookingToEdit,
									beforeManagerOverride: true,
									managerDiscount: 0,
									includeGiftCertificateDiscounts: false,
									tripSummaryLocation,
								}),
								totalFeesAppliedToBooking,
							);
							if (newDiscount != null) {
								setDiscountAmount(newDiscount);
								fetchTotalManagerDiscounts().then(x => {
									setDiscountAmount(x);
								});
							}
							fetchGiftCertificates();
						} catch {
							alertToast('Could not apply manager discount to transaction', 'error');
						}
					}
				}}
				onRemoveManagerDiscount={async () => { onRemoveManagerDiscount(); }}
				onRemoveFee={async (
					fee,
					amount,
					departurePercentage,
					returnPercentage,
				) => { onRemoveFee(fee, amount, departurePercentage, returnPercentage); }}
				tripSummaryLocation="cart"
				removePreProcessedBooking={() => {}}
			/>
		</div>
	);
}

import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { BookingEntity, FerryTripEntity } from 'Models/Entities';
import { FindVehicleTrailerDifferencePrice } from 'Util/_HumanWritten/PriceCalculations/Helpers/FindLengthPriceDifference';
import {
	filterReservedAndBookedAlterations,
	getMostRecentBookedAlteration,
	getMostRecentBookedAlterationWithFerryTrip,
	sortAlterationsByDateCreated,
} from 'Util/_HumanWritten/AlterationSortingUtils';
import { getOldFerryBookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

/**
 * This function calculates the price difference between the old and new cargo price for the selected length.
 * @param trip: The newest ferry trip that the user has selected (may be the same as the original booking).
 * @param bookingToEdit: The booking that is being altered.
 * @param selectedVehicleLengthId
 * @param optionId: The length type option from the dropdown menu on the cargo tab
 * @param forEftpos: This will determine whether or not the prices can go into the negative values */
export const calculateOneWayVehicleLengthPriceAlterBooking = (
	trip: FerryTripEntity,
	bookingToEdit: BookingEntity,
	selectedVehicleLengthId?: string,
	optionId?: string,
	forEftpos = false,
): number => {
	if (isNullOrUndefined(selectedVehicleLengthId) || selectedVehicleLengthId === '') {
		return 0;
	}
	const oldWizardData = getOldFerryBookingWizardData();
	const sortedList = filterReservedAndBookedAlterations(sortAlterationsByDateCreated(bookingToEdit?.alterations));

	const lengthToCalculate = optionId === '' || optionId === undefined ? selectedVehicleLengthId : optionId;

	let price = 0;
	const mostRecentBookedAlteration = getMostRecentBookedAlteration(sortedList);
	const mostRecentBookedAlterationWithFerryTrip = getMostRecentBookedAlterationWithFerryTrip(sortedList);

	// In booking wizard (where old booking wizard data exists)
	if (isNotNullOrUndefined(oldWizardData)) {
		price = FindVehicleTrailerDifferencePrice(
			mostRecentBookedAlterationWithFerryTrip.ferryTrip,
			trip,
			lengthToCalculate,
			mostRecentBookedAlteration.getCargo()?.selectedLengthId,
			mostRecentBookedAlteration.getCargoPrice(),
			mostRecentBookedAlteration.getCargo()?.selectedWeightId,
		);
	}

	// If the calculations are being done on the booking success page or the check-in page
	// (where old booking wizard data doesn't exist).
	if (isNullOrUndefined(oldWizardData)) {
		const oldFerryTripAlteration = sortedList
			.find(x => x.ferryTripId !== null && x.id !== mostRecentBookedAlteration.id);

		price = FindVehicleTrailerDifferencePrice(
			oldFerryTripAlteration?.ferryTrip ?? trip,
			trip,
			sortedList[0].getCargo()?.selectedLengthId, // new cargo length
			sortedList[1].getCargo()?.selectedLengthId, // old cargo length
			sortedList[1].getCargoPrice(), // old cargo price paid
			sortedList[1].getCargo()?.selectedWeightId,
		);
	}

	if (forEftpos && price < 0) {
		return 0;
	}

	return price ?? 0;
};

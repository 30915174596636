import { useMemo } from 'react';

import { QrCodeDimensions, RegoPlateDimensions } from '../Constants';
import { FrameDimensions } from '../Types/frameDimensions';
import { ScannerMode, ScannerModeMap } from '../Types/scannerMode';

/**
 * The frame dimensions of the camera window.
 */
export const useCameraFrame = (mode: ScannerMode) => {
	return useMemo<FrameDimensions>(() => {
		if (mode === ScannerModeMap.QrCode) {
			return QrCodeDimensions;
		}
		return RegoPlateDimensions;
	}, [mode]);
};

import * as React from 'react';
import { showModal } from './Base/BaseModalContents';
import { DualActionModalContent, DualActionModalContentProps } from './Base/DualActionModalContent';

export type ReturnTripModalContentsProps = Pick<DualActionModalContentProps, 'onCancel' | 'onConfirm'>;

export function AlterReturnTripModalContents({
	onCancel,
	onConfirm,
}: ReturnTripModalContentsProps) {
	return (
		<DualActionModalContent
			title="We noticed you have a returning trip..."
			body="Do you wish to make changes to those trip details too?"
			className="alter-return-trip-modal"
			onCancel={onCancel}
			onConfirm={onConfirm}
			cancelContent="No, just this"
			confirmContent="Edit both trips"
		/>
	);
}
export function CancelReturnTripModalContents({
	onCancel,
	onConfirm,
}: ReturnTripModalContentsProps) {
	return (
		<DualActionModalContent
			title="We noticed you have a returning trip..."
			body="Do you want to cancel that booking too?"
			className="cancel-return-trip-modal"
			onCancel={onCancel}
			onConfirm={onConfirm}
			cancelContent="No, just this"
			confirmContent="Cancel both"
			warningColour
		/>
	);
}

export function showAlterReturnTripModal(props: ReturnTripModalContentsProps) {
	showModal({
		key: 'alter-return-trip',
		content: <AlterReturnTripModalContents {...props} />,
	});
}

export function showCancelReturnTripModal(props: ReturnTripModalContentsProps) {
	showModal({
		key: 'cancel-return-trip',
		content: <CancelReturnTripModalContents {...props} />,
	});
}

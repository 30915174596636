import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from 'Models/Entities';
import { IStore, store as staticStore } from 'Models/Store';
import {
	clearEventBookingTransactionIdFromStorage,
	clearFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { clearStorageTransactionPrice, setTransactionPriceInStorage } from '../CartPriceSessionStorage';
import { getCartSubtotal } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartSubtotal';
import { getCartTotal } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartTotal';
import { getCreditCardSurcharge } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCreditCardSurcharge';
import {
	BookingWizardCartFields,
	clearBookingWizardData,
	clearOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import {
	clearEventBookingWizardData,
	clearOldEventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';

/**
 * The method to be set as the 'onSuccess' method of the booking wizard.
 * Clears the booking wizard data in the local storage and the cart prices in the store, then redirects to the
 * ferry schedule page.
 * @param store: The application's store
 * @param transactionId
 * @param alteration
 * @param returnTrip
 * @param userId
 * @param stopNavigation
 */
export function onWizardSuccess(
	store: IStore,
	transactionId: string,
	alteration: boolean,
	returnTrip: boolean,
	eventBooking: boolean,
	userId?: string,
	stopNavigation?: boolean,
) {
	/**
	 * Clear the local storage of booking wizard data
	 * This should be fine as all payments should have been successful and all
	 * relevant Bookings should now be in the database.
	 */

	store.setCartPriceDifference(0);
	store.setCartPriceWithoutSurcharge(0);
	store.setCartPriceWithSurcharge(0);
	store.clearBookingPrices();

	if (eventBooking) {
		clearEventBookingWizardData();
		clearOldEventBookingWizardData();
		clearEventBookingTransactionIdFromStorage();
		clearStorageTransactionPrice();
	} else {
		clearBookingWizardData();
		clearOldBookingWizardData();
		clearFerryBookingTransactionIdFromStorage();
		clearStorageTransactionPrice();
	}

	if (stopNavigation) {
		return;
	}

	// eslint-disable-next-line max-len
	const baseURL = eventBooking ? '/event-booking-success' : '/booking-success';
	const destination = `${baseURL}?id=${transactionId}&ui=${userId}&alteration=${alteration}&returnTrip=${returnTrip}`;
	store.routerHistory.push(destination);
}

/**
 * Removes the booking wizard data and booking ids stored in local storage.
 */
export function clearAllFerryBookingWizardDataFromStorage() {
	clearBookingWizardData();
	clearFerryBookingTransactionIdFromStorage();
	clearOldBookingWizardData();
}

/**
 * Removes the event booking wizard data and booking ids stored in local storage.
 */
export function clearAllEventBookingWizardDataFromStorage() {
	clearEventBookingWizardData();
	clearEventBookingTransactionIdFromStorage();
	clearOldEventBookingWizardData();
}

export function clearFerryBookingWizard() {
	clearBookingWizardData();
	clearFerryBookingTransactionIdFromStorage();
	clearOldBookingWizardData();
	setTransactionPriceInStorage(0);
	clearStorageTransactionPrice();

	// Clean up store
	staticStore.clearBookingPrices();
	staticStore.setCartPriceWithSurcharge(0);
	staticStore.setCartPriceWithoutSurcharge(0);
	staticStore.setCartPriceDifference(0);
}

export function clearEventBookingWizard() {
	clearEventBookingWizardData();
	clearEventBookingTransactionIdFromStorage();
	clearOldEventBookingWizardData();
	setTransactionPriceInStorage(0);
	clearStorageTransactionPrice();

	// Clean up store
	staticStore.clearBookingPrices();
	staticStore.setCartPriceWithSurcharge(0);
	staticStore.setCartPriceWithoutSurcharge(0);
	staticStore.setCartPriceDifference(0);
}

export function updateCurrentBookingPriceInStore(
	data: BookingWizardCartFields,
	store: IStore,
	bookingToEdit: BookingEntity,
) {
	if (store.routerHistory.location.pathname === '/booking-wizard/cart') {
		// No need to update the current price as it is accounted for in the previous price.
		store.setCartPriceDifference(0);
	} else {
		const newPrice = getCartSubtotal([data], false, 'sidebar', true, bookingToEdit);
		store.setCartPriceDifference(newPrice, data.wizardData.bookingToEdit);
	}
}

/**
 * Save the total price based on all bookings in the cart.
 */
export function updatePreviousBookingPriceInStore(
	store: IStore,
	total: number,
) {
	// subtotal price is displayed while not in cart page
	store.setCartPriceWithoutSurcharge(total);
	store.setCartPriceWithSurcharge(total);
}

export function updatePreviousFerryBookingPriceInStore(
	bookings: BookingWizardCartFields[],
	store: IStore,
	ccSurchargeMultiplier: number,
	giftCertificateUsages: GiftCertificateUsageEntity[],
	transactionFees: FeeEntity[],
	tripSummaryLocation: tripSummaryLocationType,
	discountAmount: number = 0,
	bookingToEdit: BookingEntity | null = null,
) {
	// price excludes credit card surcharge
	const total = getCartTotal({
		bookingList: bookings,
		giftCertificateUsages,
		transactionFees,
		ccSurchargeMultiplier: 0,
		afterPayment: false,
		useRecentSummary: true,
		bookingToEdit,
		beforeManagerOverride: false,
		managerDiscount: discountAmount,
		tripSummaryLocation,
	});

	updatePreviousBookingPriceInStore(store, total);
}

import { observer } from 'mobx-react';
import React from 'react';

import useCheckInStore from 'Hooks/useCheckInStore';
import { compareFnByNumberAsc } from 'Util/_HumanWritten/SortUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import InputCounterStateful from 'Views/Components/_HumanWritten/PassengerAmount/InputCounterStateful';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import BookingFormGroupHeader from '../BookingFormGroupHeader';
import { BookingFormEditSectionProps } from './BookingFormEdit';

function BookingFormEditAddons({
	groupHeaders,
	options,
	resetContinue,
}: BookingFormEditSectionProps) {
	const checkInStore = useCheckInStore();
	return (
		<BookingFormGroupHeader
			title="Add ons"
			config={{
				model: groupHeaders.addOns,
				modelProperty: 'show',
			}}
		>
			{options.addOnSections.map(addOnSection => {
				const addOns = addOnSection
					.additionalBookingOptions
					.filter(x => {
						if ((x.isvehicleonly && !groupHeaders.vehicles.show)
							|| (!x.isvehicleonly && groupHeaders.vehicles.show)
						) {
							// Hide 'Vehicle access permit' from check-in flow
							// Hide vehicle only add-ons when vehicle group is unchecked
							// Hide passenger add-ons when vehicle group is checked
							return false;
						}
						return true;
					})
					.sort(compareFnByNumberAsc(x => x.order));

				if (addOns.length === 0) {
					// Hide sections that have nothing to show
					return null;
				}

				return (
					<div key={addOnSection.id}>
						<div className="booking-form__group--label">
							{addOnSection.name}
						</div>
						{addOns.map(addOn => (
							<InputCounterStateful
								key={addOn.id}
								disabled={!addOn.removable || addOn.name === 'Vehicle access permit'}
								model={
									checkInStore.formState
										.departingOptions
										.find(o => o.optionId === addOn.id)
									?? {}
								}
								modelProperty="amount"
								title={addOn.name}
								onAfterChange={() => {
									resetContinue();
									if (checkInStore.formState.returningOptions !== []
											&& isNotNullOrUndefined(checkInStore.formState.returningOptions)) {
										// eslint-disable-next-line max-len
										const option = checkInStore.formState.returningOptions.find(o => o.optionId === addOn.id);
										if (isNotNullOrUndefined(option) && ((addOn.isSingular && option.amount === 0)
											|| !addOn.isSingular)) {
											confirmModal(
												'Apply to the return trip?',
												// eslint-disable-next-line max-len
												'Would you like this additional option to apply to the return trip too?',
											).then(() => {
												option.amount = checkInStore.formState
													.departingOptions
													.find(o => o.optionId === addOn.id)?.amount ?? 0;
											});
										}
									}
								}}
								maxCount={addOn.isSingular ? 1 : undefined}
							/>
						))}
					</div>
				);
			})}
		</BookingFormGroupHeader>
	);
}

export default observer(BookingFormEditAddons);

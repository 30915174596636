import * as React from 'react';
import { FeeEntity, FeeOptionEntity } from 'Models/Entities';
import { observer } from 'mobx-react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import { action, observable } from 'mobx';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { wizardModes } from 'Models/Enums';
import { GetFilteredFees } from 'Util/_HumanWritten/FeeOptionsUtils';
import { addFeeToTransaction } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';

export interface AddFeeModalProps {
	bookingSubtotal: number,
	addFeeModel: {
		listOfFees: {
			id: string,
			name: string,
			description: string,
			amount: number,
			isPercentage: boolean,
		}[],
		selectedFeeId: string,
		selectedFeeAmount: number | undefined,
	};
}

export const ModalContent = observer(({
	addFeeModel,
	bookingSubtotal,
}: AddFeeModalProps) => {
	return (
		<div className="add-service-fee-modal-content">
			<p className="modal-description">Select a fee to add to the booking.</p>
			<Combobox
				className="fee-options-combobox"
				model={addFeeModel}
				modelProperty="selectedFeeId"
				label="Fee type"
				placeholder="Select fee"
				options={addFeeModel.listOfFees.map(x => {
					return {
						display: x.name,
						description: x.description,
						value: x.id,
					};
				})}
				searchable
				isRequired
				onAfterChange={() => {
					const feeToApply = addFeeModel.listOfFees
						.find(x => x.id === addFeeModel.selectedFeeId);
					if (isNotNullOrUndefined(feeToApply)) {
						if (feeToApply.isPercentage) {
							addFeeModel.selectedFeeAmount = Math.round(((Math.abs(bookingSubtotal) * feeToApply.amount) / 100) * 100) / 100;
						} else {
							addFeeModel.selectedFeeAmount = Math.round(Math.abs(feeToApply.amount) * 100) / 100;
						}
					}
				}}
				inputProps={{ upward: false }}
			/>
			<TextField
				id="add-fee-modal-amount"
				className="amount-textfield"
				label="Fee amount ($)"
				model={addFeeModel}
				modelProperty="selectedFeeAmount"
				placeholder="..."
				isReadOnly
			/>
		</div>
	);
});

export const RenderAddServiceFeeModal = async (
	bookingSubtotal: number,
	tripSummaryLocation: tripSummaryLocationType,
	wizardMode: wizardModes,
	transactionFees: FeeEntity[],
	transactionId?: string,
) => {
	const fees: FeeOptionEntity[] = await FeeOptionEntity.fetch();
	const addFeeModel = observable({
		listOfFees: GetFilteredFees(fees, tripSummaryLocation, wizardMode, transactionFees)
			?.map(x => {
				return {
					id: x.id,
					name: x.name,
					description: x.description,
					amount: x.feeAmount,
					isPercentage: !x.fixedAmountFee,
				};
			}),
		selectedFeeId: '',
		selectedFeeAmount: undefined,
	});

	const ObservableModalContent = observer(() => {
		return (
			<ModalContent
				addFeeModel={addFeeModel}
				bookingSubtotal={bookingSubtotal}
			/>
		);
	});

	const confirmed = await modalWithCustomInput(
		'Add service fee',
		<ObservableModalContent />,
		{
			cancelText: 'Cancel',
			confirmText: 'Confirm',
			actionClassName: 'add-service-fee-modal',
			resolveOnCancel: false,
			onConfirm: action(() => {
				return true;
			}),
		},
	);

	if (!confirmed) {
		return;
	}

	if (isNotNullOrUndefined(transactionId)) {
		await addFeeToTransaction(transactionId, addFeeModel.selectedFeeId);
	}
};

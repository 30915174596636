import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Flex } from 'Views/Components/Flex/Flex';
import { Text } from 'Views/Components/Text/Text';
import If from 'Views/Components/If/If';
import {
	transformRego,
	transformVehicleSpace,
	transformTrailerSpace,
	transformTrip,
	transformVehicleMakeModel,
} from '../CheckInUtils';
import { CheckInListRowDetail } from './CheckInListRowDetail';
import { BookingEntity } from 'Models/Entities';
import { CheckInMoveButton } from '../CheckInButtons/CheckInMoveButton';
import useCheckInStore from 'Hooks/useCheckInStore';
import { checkInMove } from 'Services/Api/_HumanWritten/CheckInService';
import { store } from 'Models/Store';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import alertToast from 'Util/ToastifyUtils';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import CheckInListRowPassengers from './CheckInListRowPassengers';
import {
	showCheckInMoveConfirmationModal,
} from 'Views/Components/_HumanWritten/Modal/CheckInMoveConfirmationModalContents';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

export interface CheckInSearchListRowProps {
	booking: BookingEntity;
}

function CheckInSearchListRow({ booking }: CheckInSearchListRowProps) {
	const checkInStore = useCheckInStore();
	const routes = useCheckInRoutes();

	const showVehicles = booking.hasVehicle();

	const onClick = () => {
		showCheckInMoveConfirmationModal({
			onConfirm: async () => {
				try {
					await checkInMove(booking.id, checkInStore.ferryTripId);
					await checkInStore.checkInBooking(booking.id, true);
					store.routerHistory.replace(routes.base);
					store.modal.hide();
				} catch (error) {
					console.error(error);
					alertToast(
						'Something went wrong',
						'error',
						undefined,
						{ autoClose: 2000 },
					);
				}
			},
		});
	};

	return (
		<Flex className={classNames('check-in-list__row')}>
			<Flex direction="col" flex={1} gap="xxxs" className="p-sm">
				<Flex gap="xxs">
					<Text weight="600" size="sm" wordBreak="break-all">
						<span className="mr-xxs fullname">
							{booking.getFullName()}
						</span>
						<If condition={booking.firstTimeOnRoute}>
							<span className="badge badge--new text-word-break-keep-all">
								NEW
							</span>
						</If>
						<If condition={isNotNullOrUndefined(booking?.user?.userDisplayName)}>
							<span className="badge badge--primary text-word-break-keep-all">
								{booking?.user?.userDisplayName?.displayName}
							</span>
						</If>
					</Text>
				</Flex>
				<Text size="sm" color="gray">
					<If condition={showVehicles}>
						<span>{transformRego(booking)}</span>
						<span className="mr-xxs ml-xxs">|</span>
						<span className="vehicle-make-model">{transformVehicleMakeModel(booking)}</span>
					</If>
				</Text>
				<Flex direction="col">
					<Text color="gray" className="booking-human-id">
						#{booking.humanReadableId}
					</Text>
					<Text color="gray" className="trip-date-time">
						{transformTrip(booking)}
					</Text>
				</Flex>
				<Flex gap="sm" rowGap="none" flexWrap="wrap" className="icons-no-height">
					<If condition={showVehicles}>
						<CheckInListRowDetail
							label={<div className={whiteLabelStore.vehicleIconDarkClassName} />}
							value={transformVehicleSpace(booking) ?? 0}
							className="row-detail-vehicle"
						/>
						<If condition={whiteLabelStore.config.trailersEnabled}>
							<CheckInListRowDetail
								label={<Icon name="chevron-trailer" classname="m-none" />}
								value={transformTrailerSpace(booking) ?? 0}
								className="row-detail-trailer"
							/>
						</If>
					</If>
					<Flex gap="sm">
						<CheckInListRowPassengers booking={booking} />
					</Flex>
				</Flex>
			</Flex>
			<Flex direction="col" justify="center" className="mr-sm">
				<CheckInMoveButton
					id={booking.id}
					className="check-in-list__row__btn"
					iconOnly
					onClick={onClick}
				/>
			</Flex>
		</Flex>
	);
}

export default observer(CheckInSearchListRow);

import * as React from 'react';
import { getFrontendNavLinks } from '../../FrontendNavLinks';
import Navigation, { Orientation } from './Navigation';
import { RouteComponentProps } from 'react-router-dom';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import useStore from 'Hooks/useStore';
import { useEffect, useState } from 'react';
import { ILink } from 'Views/Components/Navigation/Navigation';
import { observer } from 'mobx-react';

export interface NavigationWrapperProps extends RouteComponentProps {
	className?: string;
}

function NavigationWrapper(props: NavigationWrapperProps) {
	const store = useStore();
	const { isMobile, isIpad } = useDeviceDetect();
	const [links, setLinks] = useState<ILink[][]>([[]]);

	const {
		match,
		location,
		history,
		staticContext,
		className,
	} = props;

	useEffect(() => {
		setLinks(getFrontendNavLinks(props, isIpad, store.cartPrice ?? 0));
	}, [isIpad, props, store?.cartPrice]);

	return (
		<Navigation
			className={className}
			linkGroups={links}
			orientation={Orientation.HORIZONTAL}
			match={match}
			location={location}
			history={history}
			staticContext={staticContext}
			alwaysExpanded
			isMobile={isIpad}
		/>
	);
}

export default observer(NavigationWrapper);

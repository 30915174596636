import {
	calculateCartSubtotalNewBooking,
} from 'Util/_HumanWritten/PriceCalculations/CreationPriceCalculations/Creation_CalculateCartSubtotal';
import {
	calculateCreditCardSurcharge,
	getCreditCardSurcharge,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCreditCardSurcharge';
import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from 'Models/Entities';
import { BookingWizardCartFields } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { isNotNullOrUndefined } from '../../../TypeGuards';

/**
 * This function will calculate the cart total. This is the subtotal plus any credit card fees and minus any applied
 * gift certificates.
 */
export const calculateCartTotalNewBooking = ({
	bookingList,
	bookingsList,
	giftCertificateUsages,
	transactionFees,
	ccSurchargeMultiplier,
	booking = null,
	beforeManagerOverride = false,
	includeCreditCardFee = true,
	tripSummaryLocation,
}: calculateCartTotalNewBookingParams) => {
	let runningTotal = calculateCartSubtotalNewBooking(
		bookingList,
		booking,
	);

	giftCertificateUsages.forEach(usage => {
		runningTotal -= usage.usedValue;
	});

	transactionFees?.forEach(fee => {
		runningTotal += fee.amount;
	});

	if (includeCreditCardFee && (tripSummaryLocation === 'check-in' || tripSummaryLocation === 'booking-success')) {
		let creditCardSurcharge = isNotNullOrUndefined(bookingsList) ? getCreditCardSurcharge(bookingsList[0]) : 0;

		if (tripSummaryLocation === 'check-in') {
			creditCardSurcharge = calculateCreditCardSurcharge(runningTotal, ccSurchargeMultiplier);
		}

		if (!beforeManagerOverride) {
			runningTotal += creditCardSurcharge;
		}
	}

	return runningTotal;
};

interface calculateCartTotalNewBookingParams {
	/**
	 * A list of bookings in the form of cart wizard data (includes booking wizard data and selected ferry trips).
	 */
	bookingList: BookingWizardCartFields[],
	bookingsList?: BookingEntity[],
	/**
	 * Any gift certicates the user has applied to the cart.
	 */
	giftCertificateUsages: GiftCertificateUsageEntity[],
	transactionFees: FeeEntity[];
	/**
	 * The percentage charged for credit card surcharge.
	 */
	ccSurchargeMultiplier: number,
	/**
	 * The booking that will be used to show the trip cards when there is no wizard data.
	 */
	booking: BookingEntity | null;
	/**
	 * If the calculation includes the manager override amount
	 */
	beforeManagerOverride: boolean;
	includeCreditCardFee?: boolean;
	tripSummaryLocation: tripSummaryLocationType;
}

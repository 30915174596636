/* eslint-disable dot-notation */
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import {
	BookingWizardData,
	clearBookingWizardData,
	clearOldBookingWizardData,
	getOldFerryBookingWizardData,
	saveOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { PassengerModal } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/PassengerModal';
import {
	alterBooking,
	createFerryBooking,
	createBulkBooking,
	dataToFerryTripBookingDto,
	discardAlterations,
	editCartBulkBooking,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	clearFerryBookingTransactionIdFromStorage,
	getFerryBookingTransactionIdFromStorage,
	setFerryBookingTransactionIdInStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import If from 'Views/Components/If/If';
import alertToast from 'Util/ToastifyUtils';
import { wizardModeOptions } from 'Models/Enums';
import { BookingEntity, FerryTripEntity } from 'Models/Entities';
import {
	WeightModal,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/WeightModal';
import {
	BulkBookingSpaceModal,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/BulkBookingSpaceModal';
import { useHistory } from 'react-router';
import {
	OverbookingAllowanceModal,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/OverbookingAllowanceModal';
import { isNotNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import useStore from 'Hooks/useStore';
import { showRestartWizardModal } from 'Views/Components/_HumanWritten/Modal/RestartWizardModal/RestartWizardModal';
import { TrailerModal } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/TrailerModal';
import { alertModal } from 'Views/Components/Modal/ModalUtils';
import { Colors } from 'Views/Components/Button/Button';
import { VehicleModal } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/VehicleModal';
import {
	isSameFerryBookingWizardData,
} from 'Util/_HumanWritten/BookingWizard/FerryBookingToWizardDataUtils';

export enum ReservationErrorType {
	OVERBOOKINGALLOWANCE = 'OverbookingAllowance',
	CARGO = 'Cargo',
	TOW_ON = 'TowOn',
	PASSENGER = 'Passenger',
	WEIGHT = 'Weight',
	OTHER = 'Other',
	NONE = 'None',
	LOADING = 'Loading',
	DISABLED_TRIP = 'DisabledFerryTrip',
	CLOSED = 'ClosedFerryTrip',
}

export interface ReservationErrorInformation {
	reservationError: ReservationErrorType;
	InvalidTrips: string[];
}

export interface ReservationTabProps {
	wizardData: BookingWizardData;
	bulkFerryTrips: FerryTripEntity[] | null;
	onUpdateWizardData: (newData: BookingWizardData) => void;
	navigateToNextStep: () => void;
	navigateToPreviousStep: () => void;
	navigateToTickets: () => void;
	onCompleteBookingAttempt: (attemptFailed: boolean) => void;
	bookingToEdit: BookingEntity | null;
}

export function ReservationTab({
	wizardData,
	bulkFerryTrips,
	onUpdateWizardData,
	navigateToNextStep,
	navigateToPreviousStep,
	navigateToTickets,
	onCompleteBookingAttempt,
}: ReservationTabProps) {
	const history = useHistory();
	const store = useStore();
	const [shownToast, setShownToast] = useState(false);
	const [errors, setErrors] = useState<ReservationErrorInformation>({
		reservationError: ReservationErrorType.LOADING,
		InvalidTrips: [],
	});

	const createReservations = async (
		data: BookingWizardData,
		bypassSpaceCheck: boolean = false,
	): Promise<ReservationErrorInformation> => {
		if (!data) {
			return {
				reservationError: ReservationErrorType.OTHER,
				InvalidTrips: [],
			};
		}

		if (stringNotEmpty(data.userId)) {
			let response: AxiosResponse<string> | null;
			if (data.bulkBookingTripIds !== undefined) {
				if (getFerryBookingTransactionIdFromStorage() === null) {
					response = await createBulkBooking(dataToFerryTripBookingDto(data, bypassSpaceCheck));
				} else {
					response = await editCartBulkBooking(dataToFerryTripBookingDto(data, bypassSpaceCheck));
				}
			} else if (data.bookingToEdit === undefined || data.bookingToEdit === '') {
				// Create new booking
				response = await createFerryBooking(dataToFerryTripBookingDto(data, bypassSpaceCheck));
			} else if (data.wizardMode === wizardModeOptions.CREATE) {
				// Edit booking in cart (booking is still in RESERVED state)
				response = await alterBooking(dataToFerryTripBookingDto(data, bypassSpaceCheck));
			} else {
				// Alter booking (booking is in BOOKED state)
				response = await alterBooking(dataToFerryTripBookingDto(data, bypassSpaceCheck));
			}

			switch (response.status) {
				// Status will be 200 if the booking was successfully created
				case 200:
					if (response.data !== null) {
						if (data.wizardMode !== wizardModeOptions.ALTERATION) {
							clearOldBookingWizardData();
						}

						if (response.data !== '') {
							// The endpoint will return the same transaction id when adding a second booking, so we can
							// safely just set the transaction id in session storage
							setFerryBookingTransactionIdInStorage(response.data['transactionId']);
							const newData = { ...data };

							if (data.bulkBookingTripIds === undefined) {
								newData.bookingToEdit = response.data['bookingId'];
							} else {
								newData.bulkBookingBookingIds = response.data['bookingIds'];
							}

							if (newData.wizardMode !== 'ALTERATION') {
								saveOldBookingWizardData(newData);
							}
							onUpdateWizardData(newData);
						}
						return {
							reservationError: ReservationErrorType.NONE,
							InvalidTrips: [],
						};
					}

					// This should never be reached but the failsafe is important. This will be reached if we get a
					// 200 but there is no data in the response. The method we hit will never do this and so we should
					// always hit the return within the response.data !== '' check
					return {
						reservationError: ReservationErrorType.OTHER,
						InvalidTrips: [],
					};
				// Status will be 400 (BAD_REQUEST) if the input was malformed in any way. This shouldn't happen
				// when booking through the wizard but the endpoint can return this so we need to account for
				// this possibility
				case 400:
					return {
						reservationError: ReservationErrorType.OTHER,
						InvalidTrips: [],
					};
				// Status will be 408 (REQUEST_TIMEOUT) if we were unable to acquire the redis lock to perform
				// the space reservation checks. In the endpoint our attempts to get the lock would have `time`d
				// out, and completed without performing the checks or creating the booking
				case 408:
					return {
						reservationError: ReservationErrorType.OTHER,
						InvalidTrips: [],
					};
				// Status will be 409 (CONFLICT) in two scenarios. These are when the space reservation checks
				// fail. We differentiate these based on the error
				// message which is returned because there wasn't a different error code which made sense to use
				// for either of these scenarios
				case 409:
					// The create booking endpoint will always return this data structure for 409 errors
					// eslint-disable-next-line no-case-declarations
					const errorResponse = response.data as unknown as {error: string, invalidTrips: string[]};

					if (errorResponse.error === 'Cargo') {
						return {
							reservationError: ReservationErrorType.CARGO,
							InvalidTrips: errorResponse.invalidTrips,
						};
					}
					if (errorResponse.error === 'TowOn') {
						return {
							reservationError: ReservationErrorType.TOW_ON,
							InvalidTrips: errorResponse.invalidTrips,
						};
					}
					if (errorResponse.error === 'OverbookingAllowance') {
						return {
							reservationError: ReservationErrorType.OVERBOOKINGALLOWANCE,
							// We only need the invalid trip list when we are trying to overbook a trip during bulk
							// booking. We need it there so that we can show the user what trips they would be
							// overbooking for
							InvalidTrips: wizardData.bulkBookingTripIds !== undefined ? errorResponse.invalidTrips : [],
						};
					}
					if (errorResponse.error === 'Passenger') {
						return {
							reservationError: ReservationErrorType.PASSENGER,
							InvalidTrips: errorResponse.invalidTrips,
						};
					}
					if (errorResponse.error === 'Weight') {
						return {
							reservationError: ReservationErrorType.WEIGHT,
							InvalidTrips: errorResponse.invalidTrips,
						};
					}
					if (errorResponse.error === 'Closed') {
						return {
							reservationError: ReservationErrorType.CLOSED,
							InvalidTrips: errorResponse.invalidTrips,
						};
					}
					if (errorResponse.error === ReservationErrorType.DISABLED_TRIP) {
						return {
							reservationError: ReservationErrorType.DISABLED_TRIP,
							InvalidTrips: [],
						};
					}
					if (errorResponse.error === 'Other' && wizardData.bulkBookingTripIds !== undefined) {
						return {
							reservationError: ReservationErrorType.OTHER,
							InvalidTrips: errorResponse.invalidTrips,
						};
					}
			}
			// If we get here, we are dealing with a duplicate. this scenario will be hit by going back and then
			// moving forward through the wizard again. We need the user to be able to progress in this scenario
			// so we can just have the component progress to the next step without any additional handling
			return {
				reservationError: ReservationErrorType.NONE,
				InvalidTrips: [],
			};
		}

		// We shouldn't ever get here because the status code cases will sort everything out for us. We do need
		// to have a fallback for the compiler to not get cranky, and if we get here then we didn't get a 200,
		// which means that the entity wasn't created/updated.
		return {
			reservationError: ReservationErrorType.OTHER,
			InvalidTrips: [],
		};
		// Only data is a dependency as adding setReservationErrors, setReservationErrorsAndGoToNextStep caused
		// multiple versions of the same booking to be made and reserved.
	};

	useEffect(() => {
		const oldWizardData = getOldFerryBookingWizardData();
		let isDuplicateAlteration = false;
		if (oldWizardData !== null && oldWizardData.wizardMode === 'ALTERATION') {
			isDuplicateAlteration = isSameFerryBookingWizardData(wizardData, oldWizardData);
		}

		if (!isDuplicateAlteration) {
			createReservations(wizardData).then(result => {
				const failed = result.reservationError !== ReservationErrorType.NONE;
				onCompleteBookingAttempt(failed);

				setErrors(result);
			});
		} else if (isDuplicateAlteration) {
			alertToast('No changes made to booking', 'error');
			navigateToPreviousStep();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (errors?.reservationError === ReservationErrorType.NONE) {
		navigateToNextStep();
	}

	if (!shownToast && errors?.reservationError === ReservationErrorType.DISABLED_TRIP) {
		showRestartWizardModal();
		return <></>;
	}

	if (
		!shownToast
		&& errors?.reservationError === ReservationErrorType.OTHER
		&& wizardData.bulkBookingTripIds === undefined
	) {
		alertToast('Could not create booking. Please contact staff.', 'error', '', {
			autoClose: 3000,
		});
		setShownToast(true);
		navigateToPreviousStep();
	}

	const clickCross = async () => {
		if (wizardData.bulkBookingTripIds !== undefined) {
			await clickCrossBulkBooking();
			return;
		}
		if (errors?.reservationError === ReservationErrorType.OVERBOOKINGALLOWANCE) {
			navigateToTickets();
		} else {
			navigateToPreviousStep();
		}
	};

	const clickCrossBulkBooking = async () => {
		if (isNotNullOrUndefined(wizardData.bulkBookingBookingIds)) {
			await discardAlterations({ bookingIds: wizardData.bulkBookingBookingIds, systemExpired: false });
		}
		clearFerryBookingTransactionIdFromStorage();
		clearBookingWizardData();
		clearOldBookingWizardData();
		store.clearBookingPrices();
		store.setCartPriceWithSurcharge(0);
		store.setCartPriceWithoutSurcharge(0);
		store.setCartPriceDifference(0);
		history.push('/ferry-schedule');
	};

	const clickSelect = async () => {
		const newData = { ...wizardData };
		if (errors?.reservationError === ReservationErrorType.OVERBOOKINGALLOWANCE) {
			setErrors({
				reservationError: ReservationErrorType.LOADING,
				InvalidTrips: [],
			});
			createReservations(newData, true).then(result => {
				const failed = (result.reservationError !== ReservationErrorType.NONE);
				onCompleteBookingAttempt(failed);

				setErrors(result);
			});
		} else {
			errors?.InvalidTrips.forEach(tripId => {
				if (newData.departureTicketId === tripId) {
					newData.departureTicketId = '';
				}
				if (newData.returningTicketId === tripId) {
					newData.returningTicketId = '';
				}
			});
			onUpdateWizardData(newData);
			navigateToTickets();
		}
	};

	const clickOk = async (originalBookingTicketIsClosed: boolean) => {
		const newData = { ...wizardData };

		// If the invalid tripId is the original booking tripId (that means they are
		// likely editing a booking that is on a trip that has already closed for modifications).
		if (!originalBookingTicketIsClosed) {
			errors?.InvalidTrips.forEach(tripId => {
				if (newData.departureTicketId === tripId) {
					newData.departureTicketId = '';
				}
				if (newData.returningTicketId === tripId) {
					newData.returningTicketId = '';
				}
			});
			onUpdateWizardData(newData);
			navigateToTickets();
		} else {
			navigateToPreviousStep();
			history.push(`/bookings/${newData.bookingToEdit}`);
		}
	};

	if (errors.reservationError === ReservationErrorType.CLOSED) {
		const oldBookingWizardData = getOldFerryBookingWizardData();

		let message = 'A ferry trip you have selected is no longer available for '
			+ 'online bookings. Please choose another trip time or contact staff.';
		let originalDepartureTicketIsClosed = false;
		let originalReturnTicketIsClosed = false;
		let originalBookingTicketIsClosed = false;

		if (isNotNullOrUndefined(oldBookingWizardData)) {
			originalDepartureTicketIsClosed = errors.InvalidTrips.includes(oldBookingWizardData?.departureTicketId);
			originalReturnTicketIsClosed = errors.InvalidTrips.includes(oldBookingWizardData?.returningTicketId);
			if (originalDepartureTicketIsClosed || originalReturnTicketIsClosed) {
				originalBookingTicketIsClosed = true;
				// eslint-disable-next-line max-len
				message = `Your booking can not be edited because the ferry trip associated to the ${originalDepartureTicketIsClosed
					? 'departing'
					: 'returning'} booking is closed for modifications.`;
			}
		}

		alertModal(
			'Ferry trip closed',
			message,
			{
				cancelText: 'Ok',
				buttonColour: Colors.Black,
			},
		).then(async () => {
			await clickOk(originalBookingTicketIsClosed);
		}).catch(async () => {
			await clickOk(originalBookingTicketIsClosed);
		});
	}
	return (
		<>
			<OverbookingAllowanceModal
				showModal={errors?.reservationError === ReservationErrorType.OVERBOOKINGALLOWANCE}
				onClickCross={clickCross}
				onClickSelect={clickSelect}
				// If we are not in bulk booking mode, invalid trips will be an empty array and bulkFerryTrips will be
				// null, so the modal will show in its normal configuration
				bulkFerryTrips={bulkFerryTrips?.filter(x => errors.InvalidTrips.includes(x.id)) ?? null}
			/>
			<VehicleModal
				showModal={errors?.reservationError === ReservationErrorType.CARGO}
				onClickCross={clickCross}
				onClickSelect={clickSelect}
			/>
			<TrailerModal
				showModal={errors?.reservationError === ReservationErrorType.TOW_ON}
				onClickCross={clickCross}
				onClickSelect={clickSelect}
			/>
			<WeightModal
				showModal={errors?.reservationError === ReservationErrorType.WEIGHT}
				onClickCross={clickCross}
				onClickSelect={clickSelect}
			/>
			<PassengerModal
				showModal={errors?.reservationError === ReservationErrorType.PASSENGER}
				onClickCross={clickCross}
				onClickSelect={clickSelect}
			/>
			<BulkBookingSpaceModal
				showModal={
					errors?.reservationError === ReservationErrorType.OTHER
					&& wizardData.bulkBookingTripIds !== undefined
					&& errors.InvalidTrips.length > 0
				}
				onClickSelect={clickCrossBulkBooking}
				bulkFerryTrips={bulkFerryTrips?.filter(x => errors.InvalidTrips.includes(x.id)) ?? null}
				bulkBookingBookingIds={wizardData.bulkBookingBookingIds ?? null}
			/>
			<If condition={errors.reservationError === ReservationErrorType.LOADING}>
				<LottieSpinner />
			</If>
		</>

	);
}

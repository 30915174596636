import useStore from 'Hooks/useStore';
import { useEffect, useState } from 'react';
import React from 'react';
import {
	BookingEntity,
	EventDetailEntity, FeeEntity,
} from 'Models/Entities';
import {
	getEventBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { EventBookingWizardData } from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import EventBookingSummaryCard from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/Cart/EventBookingSummaryCard';
import {
	EventBookingPricesDto,
	FetchEventBookingsForCartAsync, GetPrices,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';
import {
	EventPromoGiftCard,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/Cart/EventPromoGiftCard';
import {
	updatePreviousBookingPriceInStore,
} from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import {
	removeGiftCertificateFromTransaction,
} from 'Services/Api/_HumanWritten/GiftCertificateService';
import { setTransactionPriceInStorage } from 'Util/_HumanWritten/CartPriceSessionStorage';
import {
	removeFeeFromTransaction,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import alertToast from 'Util/ToastifyUtils';

export interface EventCartSummaryProps {
	wizardData: EventBookingWizardData;
	onUpdateWizardData: (newData: EventBookingWizardData) => void;
	setRefresh: (refresh: boolean) => void;
	refresh: boolean;
	eventDetails: EventDetailEntity;
}

export function EventCartSummary({
	wizardData,
	onUpdateWizardData,
	setRefresh,
	refresh,
	eventDetails,
}: EventCartSummaryProps) {
	const store = useStore();
	const [booking, setBooking] = useState<BookingEntity | null>(null);
	const [prices, setPrices] = useState<EventBookingPricesDto | null>(null);
	const [transactionFees, setTransactionFees] = useState<FeeEntity[]>([]);

	const transactionId = getEventBookingTransactionIdFromStorage();

	const response = FetchEventBookingsForCartAsync(transactionId);

	const handleClearGiftCertificate = (code: string) => {
		if (isNotNullOrUndefined(transactionId)) {
			removeGiftCertificateFromTransaction(transactionId, code, true).then(() => {
				getPricesForCart();
			});
		}
	};

	const onClearFee = (feeId: string) => {
		removeFeeFromTransaction(transactionId ?? '', feeId)
			.then(_ => {
				getPricesForCart();
			})
			.catch(_ => {
				alertToast('Could not remove fee', 'error');
			});
	};

	const getPricesForCart = () => {
		if (isNotNullOrUndefined(transactionId) && booking !== null) {
			GetPrices(transactionId).then(x => {
				setPrices(x);
				setTransactionPriceInStorage(x.totalPrice);
			});
		}
	};

	// Get the prices for the cart on the initial load, only if prices are null
	useEffect(() => {
		if (prices === null) {
			getPricesForCart();
		}
	}, [booking]);

	useEffect(() => {
		if (!(response.type === 'loading' || response.type === 'error')) {
			if (isNotNullOrUndefined(transactionId)) {
				updatePreviousBookingPriceInStore(
					store,
					prices?.totalPrice ?? 0,
				);
			}

			// set the current booking price for the cart to 0 (As the current one is now in the previous bookings)
			store.setCartPriceDifference(0);
		}
	}, [response.data, response.type, store]);

	useEffect(() => {
		if (response.type === 'data' && response.data !== null) {
			setBooking(response.data);
		}
	}, [setBooking, response.data, response.type]);

	if (response.type === 'loading' || response.type === 'error') {
		return <LottieSpinner />;
	}

	if (isNullOrUndefined(booking)) {
		return <></>;
	}

	return (
		<div className="event-cart-summary-container">
			<h2 className="event-booking-wizard__tab-header cart-summary-heading">Review your cart</h2>
			<div className="pre-cart-items">
				<EventPromoGiftCard
					transactionId={transactionId ?? ''}
					onAddGiftCertificate={() => {
						if (isNotNullOrUndefined(transactionId)) {
							getPricesForCart();
						}
					}}
				/>
			</div>
			<EventBookingSummaryCard
				wizardData={wizardData}
				eventDetails={eventDetails}
				transactionId={transactionId ?? undefined}
				onUpdateWizardData={onUpdateWizardData}
				booking={booking}
				refresh={response.refresh}
				onClearGiftCertificate={code => handleClearGiftCertificate(code)}
				onClearFee={onClearFee}
				tripSummaryLocation="cart"
				prices={prices}
			/>
		</div>
	);
}

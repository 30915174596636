import { runInAction } from 'mobx';
import moment from 'moment';
import { isCardNumberLength, isCardNumberInitial } from 'Validators/Functions/HumanWritten/CardNumber';
import { isCvcNumber } from 'Validators/Functions/HumanWritten/CvcNumber';
import { PaymentDetails } from './PaymentTab';
import { WizardErrors } from '../../BookingWizardData';

const LAST_PAYMENT_ATTEMPT_KEY = 'last-payment-attempt-datetime';

/**
 * Returns stored date time of last valid attempt of user pressing the 'Pay now' button.
 */
export function getLastPaymentAttempt(): Date | null {
	const isoString = sessionStorage.getItem(LAST_PAYMENT_ATTEMPT_KEY);
	if (isoString) {
		// Convert the ISO string to a Date object
		return new Date(isoString);
	}
	return null;
}

export function setLastPaymentAttempt(now = new Date()) {
	// Convert the datetime to ISO string format
	const isoString = now.toISOString();

	// Set the ISO string as the value of a key in local storage
	sessionStorage.setItem(LAST_PAYMENT_ATTEMPT_KEY, isoString);
}

export function arePaymentDetailsValid(
	details: PaymentDetails,
	errors: WizardErrors<PaymentDetails>,
): boolean {
	const newErrors: { [x: string]: string | undefined } = {};

	// Process card holder name
	if (details.cardholderName === '') {
		newErrors.cardholderName = 'Required';
	}

	// Process card number
	if (details.cardNo === '') {
		newErrors.cardNo = 'Required';
	} else if (!isCardNumberLength(details.cardNo.split(' ').join(''))) {
		newErrors.cardNo = 'Must be 16 digits';
	} else if (!isCardNumberInitial(details.cardNo)) {
		newErrors.cardNo = 'Card number must start with a 4 or a 5';
	}

	// Process expiry date
	const trimmedExpiry = details.expiryDate.replace(/ /g, '');
	// Making a new moment object with this format will put it to the start of this month
	const expiry = moment(trimmedExpiry, 'MM/YY');
	if (trimmedExpiry === '') {
		newErrors.expiryDate = 'Required';
	} else if (!expiry.isValid()) {
		newErrors.expiryDate = 'Invalid date';
	} else {
		// We need to add 1 month to the date object since an expiry date of this month is still valid, so doing
		// this will set the date to the start of next month
		expiry.add(1, 'month');

		if (expiry.isBefore(moment())) {
			newErrors.expiryDate = 'Invalid expiry date';
		}
	}

	// Process cvc number
	if (details.cvc === '') {
		newErrors.cvc = 'Required';
	} else if (!isCvcNumber(details.cvc)) {
		newErrors.cvc = 'Must be 3 digits';
	}

	runInAction(() => {
		for (const error of Object.keys(newErrors)) {
			errors[error] = newErrors[error];
		}
	});

	return Object.keys(newErrors).length === 0;
}

import { calculateCartTotalAlterBooking } from '../AlterationPriceCalculations/Alteration_CalculateCartTotal';
import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from '../../../../Models/Entities';
import { isNullOrUndefined } from '../../../TypeGuards';
import { calculateCartTotalNewBooking } from '../CreationPriceCalculations/Creation_CalculateCartTotal';
import { BookingWizardCartFields } from '../../../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	tripSummaryLocationType,
} from '../../../../Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';

/**
 * This function will calculate the cart total. This is the
 * subtotal plus any credit card fees and minus any applied gift certificates.
 * @param bookingList: A list of bookings in the form of cart wizard data
 * (includes booking wizard data and selected ferry trips).
 * @param giftCertificateUsages: Any gift certicates the user has applied to the cart.
 * @param transactionFees: The transaction fees applied to the transaction.
 * @param ccSurchargeMultiplier: The percentage charged for credit card surcharge.
 * @param afterPayment: If the calculation is being done after payment has been taken
 * @param useRecentSummary: Whether not to use the most recent summary or the previous summary.
 * @param tripSummaryLocation: The location that the calculations are happening (e.g. cart or booking success page)
 * @param bookingToEdit: The booking that will be used to show the trip cards when there is no wizard data.
 * @param beforeManagerOverride: Whether or not we want the calculation from before the manager overrides the cost
 * @param managerDiscount: The amount of manager discount
 * @param includeGiftCertificateDiscounts Whether or not to include the gift certificate discounts in the price
 * @param forEftpos: This will determine whether or not the prices can go into the negative values
 * @param includeCreditCardFee: Whether or not to include the credit card fees in the total calculation.
 */
export function getCartTotal({
	bookingList,
	giftCertificateUsages,
	transactionFees,
	ccSurchargeMultiplier,
	afterPayment,
	useRecentSummary,
	tripSummaryLocation,
	bookingToEdit = null,
	bookingsList,
	beforeManagerOverride = false,
	managerDiscount = 0,
	includeGiftCertificateDiscounts = true,
	forEftpos = false,
	includeCreditCardFee = true,
}: {
	bookingsList?: BookingEntity[];
	bookingList: BookingWizardCartFields[];
	giftCertificateUsages: GiftCertificateUsageEntity[];
	transactionFees: FeeEntity[],
	ccSurchargeMultiplier: number;
	afterPayment: boolean;
	useRecentSummary?: boolean;
	tripSummaryLocation: tripSummaryLocationType;
	bookingToEdit: BookingEntity | null;
	beforeManagerOverride?: boolean;
	managerDiscount?: number;
	includeGiftCertificateDiscounts?: boolean;
	forEftpos?: boolean;
	includeCreditCardFee?: boolean;
}) {
	if (bookingList.length > 0) {
		/* Create mode */
		if (isNullOrUndefined(bookingToEdit) || isNullOrUndefined(bookingToEdit?.id)) {
			return calculateCartTotalNewBooking({
				bookingList,
				giftCertificateUsages,
				transactionFees,
				ccSurchargeMultiplier,
				bookingsList,
				booking: bookingToEdit,
				beforeManagerOverride,
				includeCreditCardFee,
				tripSummaryLocation,
			});
		}
		/* Alteration mode */
		return calculateCartTotalAlterBooking({
			booking: bookingList[0],
			giftCertificateUsages,
			transactionFees,
			ccSurchargeMultiplier,
			bookingToEdit,
			bookingsList,
			useRecentSummary: useRecentSummary ?? true,
			afterPayment,
			beforeManagerOverride,
			managerDiscount,
			includeGiftCertificateDiscounts,
			forEftpos,
			includeCreditCardFee,
			tripSummaryLocation,
		});
	}
	return 0;
}

import React from 'react';
import {
	TicketSelectionTab,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/TicketSelectionTab';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { RouteEntity } from 'Models/Entities';
import { BookingEntity } from 'Models/Entities';

export interface TicketsTabProps {
	wizardData: BookingWizardData;
	onUpdateWizardData: (data: BookingWizardData) => void;
	route: RouteEntity | null;
	selectedTrips: SelectedTrips;
	bookingToEdit: BookingEntity | null;
}

export function TicketsTab({
	wizardData,
	onUpdateWizardData,
	route,
	selectedTrips,
	bookingToEdit = null,
}: TicketsTabProps) {
	return (
		<TicketSelectionTab
			wizardData={wizardData}
			saveChanges={onUpdateWizardData}
			route={route}
			selectedTrips={selectedTrips}
			bookingToEdit={wizardData.wizardMode === 'ALTERATION' ? bookingToEdit : null}
		/>
	);
}

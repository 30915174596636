import React, { useEffect } from 'react';
import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from 'Models/Entities';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import useStore from 'Hooks/useStore';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { TripBreakdown } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBreakdown';
import alertToast from 'Util/ToastifyUtils';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { feeType, wizardModeOptions } from 'Models/Enums';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { useHistory } from 'react-router';
import { updatePreviousFerryBookingPriceInStore } from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { setTransactionPriceInStorage } from 'Util/_HumanWritten/CartPriceSessionStorage';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import TripBookingSummaryTotal from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryTotal';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { fetchTripsForWizard } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardHelpers';
import { ferryBookingToWizardData } from 'Util/_HumanWritten/BookingWizard/FerryBookingToWizardDataUtils';
import { removeBookingFromCart } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { clearFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { action } from 'mobx';
import {
	BookingWizardCartFields,
	BookingWizardData, clearBookingWizardData, clearOldBookingWizardData,
	getDefaultWizardData,
	saveOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	getCartSubtotal,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartSubtotal';
import {
	getCartTotal,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartTotal';

export type tripSummaryLocationType = 'cart' | 'sidebar' | 'booking-success' | 'check-in';

export interface TripBookingSummaryCardProps {
	wizardData?: BookingWizardData;
	selectedTrips?: SelectedTrips;
	onUpdateWizardData?: (newData: BookingWizardData) => void;
	setSelectedTrips?: (trips: SelectedTrips) => void;
	bookings?: BookingEntity[];
	refresh: () => void;
	bookingToEdit: BookingEntity | null;
	/**
	 * The location that the trip summary is being rendered
	 */
	tripSummaryLocation: tripSummaryLocationType;
	giftCertificateUsages: GiftCertificateUsageEntity[];
	onClearGiftCertificate?: (code: string) => void;
	fetchGiftCertificates?: () => void;
	transactionFees: FeeEntity[];
	fetchTransactionFees: (transactionId: string) => void;
	onClearFee?: (feeId: string) => void;
	preProcessedCartFields?: BookingWizardCartFields[];
	removePreProcessedBooking: (tripId: string) => void;
	onAddManagerDiscount?: (
		bookings: BookingWizardCartFields[],
		afterPayment: boolean,
		totalFeesAppliedToBooking: number
	) => void,
	onRemoveManagerDiscount?: () => void,
	onRemoveFee?: (feeType: feeType, amount?: number, departurePercentage?: number, returnPercentage?: number) => void;
	discountAmount?: number;
	/**
	 * Looks like Check-In is the only component that uses this prop...
	 */
	alterReturn?: boolean;
	/**
	 * The transaction ID will be used to check if the transaction has a credit card surcharge using an endpoint.
	 * If the endpoint returns false, then the credit card fee line will be hidden.
	 * If the endpoint returns true, or failed, the credit card fee line will be displayed.
	 */
	transactionId?: string;
	checkInReview?: boolean;
	applyCreditCardSurcharge?: boolean;
}

function TripBookingSummaryCard({
	wizardData,
	selectedTrips,
	onUpdateWizardData,
	setSelectedTrips,
	bookings,
	refresh,
	bookingToEdit = null,
	tripSummaryLocation,
	giftCertificateUsages,
	fetchGiftCertificates,
	onClearGiftCertificate,
	transactionFees,
	fetchTransactionFees,
	onClearFee,
	preProcessedCartFields,
	removePreProcessedBooking,
	onAddManagerDiscount,
	onRemoveManagerDiscount,
	onRemoveFee,
	discountAmount = 0,
	alterReturn = false,
	transactionId,
	checkInReview = false,
	applyCreditCardSurcharge = true,
}: TripBookingSummaryCardProps) {
	const store = useStore();
	const history = useHistory();
	const { isIpad } = useDeviceDetect();
	const forEftpos = tripSummaryLocation === 'check-in';

	const afterPayment = tripSummaryLocation === 'booking-success';
	const cartView = tripSummaryLocation === 'cart' || tripSummaryLocation === 'check-in';
	const bookingsList = bookings ?? [];
	const isAlteration = wizardData
		? wizardData?.wizardMode === wizardModeOptions.ALTERATION
		: bookingsList.length === 1 && bookingsList[0].alterations.length > 1;

	let bookingsToDisplay: BookingWizardCartFields[];
	if (preProcessedCartFields !== undefined) {
		bookingsToDisplay = preProcessedCartFields;
	} else {
		// If it is cartView only show the bookings that are stored in the local storage
		// Else if it is not cartView (sideBarSummary) then show the already existing bookings and append the current
		// in progress booking to the list of bookings to render in the side bar
		bookingsToDisplay = cartView || afterPayment
			? ferryBookingToWizardData(
				bookingsList,
				undefined,
				undefined,
				isAlteration,
				tripSummaryLocation === 'check-in'
					? alterReturn
					: (
						(
							isNotNullOrUndefined(wizardData)
							&& (wizardData?.departureTrip === undefined
								|| wizardData.returningTicketId !== '')
						) || (
							bookingToEdit?.desynced === false
							&& (
								isNotNullOrUndefined(bookingToEdit.returnBooking)
								|| isNotNullOrUndefined(bookingToEdit.returnBookingFor)
							) && isNullOrUndefined(wizardData)
						)
						|| !isAlteration
					),
			)
			: ferryBookingToWizardData(
				bookingsList,
				wizardData,
				selectedTrips,
				isAlteration,
				(
					isNotNullOrUndefined(wizardData)
					&& (
						wizardData?.departureTrip === undefined
						|| wizardData.returningTicketId !== ''
					)
				)
					|| (bookingToEdit?.desynced === false && isNullOrUndefined(wizardData))
					|| !isAlteration
					|| alterReturn,
			);
	}

	useEffect(() => {
		if (isNotNullOrUndefined(transactionId)) {
			fetchTransactionFees(transactionId);
		}
	}, []);

	const onRemoveBooking = async (bookingId: string): Promise<boolean> => {
		store.removeBookingPrice(bookingId);

		if (bookingId === '') {
			// This is the current booking that only exists in the wizard
			if (onUpdateWizardData) {
				onUpdateWizardData(getDefaultWizardData());
			}
			return true;
		}

		if (bookingsToDisplay.length === 1) {
			clearFerryBookingTransactionIdFromStorage();
			clearOldBookingWizardData();
			store.setCartPriceDifference(0);
			store.setCartPriceWithoutSurcharge(0);
			store.setCartPriceWithSurcharge(0);
			store.clearBookingPrices();
		} else {
			updatePreviousFerryBookingPriceInStore(
				bookingsToDisplay,
				store,
				0,
				giftCertificateUsages,
				transactionFees,
				tripSummaryLocation,
				discountAmount,
				bookingToEdit,
			);
		}

		// Remove a reserved booking
		const result = await removeBookingFromCart(bookingId);
		if (result.status !== 200) {
			alertToast(
				'There was an error removing this booking.',
				'error',
				'Failed to remove booking',
			);
			return false;
		}

		if (fetchGiftCertificates) {
			fetchGiftCertificates();
		}

		alertToast(
			'The booking has been removed from your cart.',
			'success',
			'Booking removed',
		);
		// If the user deletes the most recent booking, the booking wizard data
		// will be replaced with the next booking in the list of bookings
		// The deleted booking is still in the list, so we add another index
		// to avoid pre-filling wizard data with the deleted booking
		if (wizardData?.bookingToEdit === bookingId && bookingsList.length > 1 && onUpdateWizardData) {
			const bookingsToFilter = bookingsList.filter(booking => booking.id !== bookingId);
			const convertedBookings = ferryBookingToWizardData(bookingsToFilter);

			if (setSelectedTrips) {
				setSelectedTrips({
					departingTrip: undefined,
					returningTrip: undefined,
				});
			}
			onUpdateWizardData({ ...convertedBookings[convertedBookings.length - 1].wizardData });
		}
		return true;
	};

	useEffect(() => {
		if (!isNotNullOrUndefined(selectedTrips)
			&& isNotNullOrUndefined(wizardData)
			&& isNotNullOrUndefined(setSelectedTrips)
		) {
			fetchTripsForWizard(
				wizardData?.departureTicketId,
				wizardData?.returningTicketId,
				selectedTrips ?? null,
				(trips: SelectedTrips) => {
					setSelectedTrips(trips);
				},
				wizardData.userId,
			);
		}
	}, [wizardData?.userId]);

	const onEdit = action((booking: BookingWizardCartFields) => {
		if (setSelectedTrips) {
			setSelectedTrips(booking.selectedTrips);
		}

		if (onUpdateWizardData) {
			saveOldBookingWizardData(booking.wizardData);
			onUpdateWizardData(booking.wizardData);
		}
	});

	const listOfBookings = bookingsToDisplay.map((booking, count) => {
		const id = count;
		return (
			<div key={id} className="trip-card-section">
				<div className="booking-card-header">
					<h5>
						{wizardData?.wizardMode === wizardModeOptions.ALTERATION ? 'Update' : 'Booking'} {
							afterPayment && bookingsList && bookingsList.length > 0
								? (
									<Link to={`/bookings/${bookingsList[count].id}`}>
										#{bookingsList[count].humanReadableId}
									</Link>
								)
								: count + 1
						}
					</h5>
					<If condition={!checkInReview
						&& ((!afterPayment && cartView)
						|| (!cartView && (
							wizardData?.bookingToEdit === booking.wizardData.bookingToEdit
							|| (booking.wizardData.bookingToEdit === '' && (count + 1 === bookings?.length)))))}
					>
						<If condition={!isAlteration}>
							<ButtonGroup>
								<If condition={
									preProcessedCartFields === undefined && wizardData?.bulkBookingTripIds === undefined
								}
								>
									<Button
										className="edit-button"
										colors={Colors.Secondary}
										display={Display.Text}
										onClick={() => {
											onEdit(booking);
											store.routerHistory.push(isIpad
												? '/booking-wizard/search'
												: '/booking-wizard/tickets');
										}}
									>
										Edit
									</Button>
								</If>
								<Button
									className="remove-button"
									colors={Colors.Red}
									display={Display.Text}
									onClick={() => {
										if (preProcessedCartFields !== undefined) {
											if (preProcessedCartFields.length <= 1) {
												clearBookingWizardData();
												history.push('/ferry-schedule');
											} else {
												removePreProcessedBooking(booking.wizardData.departureTicketId);
											}

											return;
										}

										confirmModal(
											'Remove ferry ticket?',
											'This action will release your booking and can\'t be undone.',
											{
												cancelText: 'Back',
												confirmText: 'Confirm',
											},
										).then(async () => {
											// Unselect the selected ferry trips for the active booking
											if (booking.wizardData.departureTicketId !== '') {
												booking.selectedTrips.departingTrip = undefined;
												booking.wizardData.departureTicketId = '';
											}
											if (booking.wizardData.returningTicketId !== '') {
												booking.selectedTrips.returningTrip = undefined;
												booking.wizardData.returningTicketId = '';
											}
											if (isNotNullOrUndefined(wizardData)
													&& isNotNullOrUndefined(wizardData?.bulkBookingTripIds)
													&& isNotNullOrUndefined(wizardData?.bulkBookingBookingIds)
											) {
												const newData: BookingWizardData = { ...wizardData };
												const associatedBookingEntity = bookingsList
													.find(x => x.id === booking.wizardData.bookingToEdit);
												newData.bulkBookingTripIds = wizardData?.bulkBookingTripIds
													// eslint-disable-next-line max-len
													.filter(x => x !== associatedBookingEntity?.bookingSummaries.recentSummary.ferryTrip.id);
												newData.bulkBookingBookingIds = wizardData?.bulkBookingBookingIds
													.filter(x => x !== booking.wizardData.bookingToEdit);
												if (!!onUpdateWizardData) {
													onUpdateWizardData(newData);
												}
											}

											// TODO: Change this to set the booking to another status (after model change)
											// Set the booking to `Expired Reservation` in the database
											const success = await onRemoveBooking(booking.wizardData.bookingToEdit);

											if (success) {
												refresh();
											}

											// If there are no more bookings in the cart, take user back to ticket selection
											// check length is one because this will happen before a rerender
											if (bookings?.length === 1
												&& isNotNullOrUndefined(wizardData?.bulkBookingTripIds)
											) {
												clearFerryBookingTransactionIdFromStorage();
												clearBookingWizardData();
												clearOldBookingWizardData();
												store.clearBookingPrices();
												store.setCartPriceWithSurcharge(0);
												store.setCartPriceWithoutSurcharge(0);
												store.setCartPriceDifference(0);
												history.push('/ferry-schedule');
												return;
											}

											if (
												bookings?.length === 1
												&& !!wizardData
												&& onUpdateWizardData
											) {
												// Clear selected ticket IDs
												const newData = { ...wizardData };
												newData.departureTicketId = '';
												newData.returningTicketId = '';
												// Need to remove bookingToEdit because there will be no bookings in the cart
												newData.bookingToEdit = '';
												onUpdateWizardData(newData);
												store.routerHistory.push('/booking-wizard/tickets');
											} else if (!cartView) {
												store.routerHistory.push('/booking-wizard/cart');
											}
										}).catch(() => {});
									}}
								>
									Remove
								</Button>
							</ButtonGroup>
						</If>
					</If>
					<If condition={
						!cartView
						&& !(!cartView
							&& (wizardData?.bookingToEdit === booking.wizardData.bookingToEdit
								|| (booking.wizardData.bookingToEdit === '' && (count + 1 === bookings?.length))))
					}
					>
						<p className="line-item-price">
							{formatPriceDisplay(
								getCartSubtotal(
									[booking],
									afterPayment,
									tripSummaryLocation,
									!afterPayment,
									bookingToEdit,
									forEftpos,
								),
								(
									bookingsToDisplay[0]?.wizardData?.wizardMode === wizardModeOptions.ALTERATION
										|| wizardData?.wizardMode === wizardModeOptions.ALTERATION
								),
							)}
						</p>
					</If>
				</div>
				<If condition={
					afterPayment
					|| cartView
					|| (!cartView
						&& (wizardData?.bookingToEdit === booking.wizardData.bookingToEdit
							|| (booking.wizardData.bookingToEdit === '' && (count + 1 === bookings?.length))))
				}
				>
					<TripBreakdown
						key="departure-breakdown"
						cartData={booking}
						wizardData={wizardData}
						departureTrip
						bookingToEdit={isAlteration ? bookingToEdit : null}
						tripSummaryLocation={tripSummaryLocation}
						afterPayment={afterPayment}
					/>
					<If
						condition={
							booking.wizardData.returningTicketId !== ''
							|| (
								booking.wizardData.tripType === 'return'
								&& (
									wizardData?.returningTicketId !== ''
									&& wizardData?.returningTicketId !== undefined
								)
							)
						}
					>
						<If condition={afterPayment}>
							<div className="booking-card-header">
								<h5>
									{wizardData?.wizardMode === wizardModeOptions.ALTERATION ? 'Update' : 'Booking'} {
										afterPayment && bookingsList && bookingsList.length > 0
											? (
												<Link to={`/bookings/${bookingsList[count].returnBooking?.id}`}>
													#{bookingsList[count].returnBooking?.humanReadableId}
												</Link>
											)
											: count + 1
									}
								</h5>
							</div>
						</If>
						<TripBreakdown
							key="return-breakdown"
							cartData={booking}
							wizardData={wizardData}
							departureTrip={false}
							bookingToEdit={isAlteration ? bookingToEdit : null}
							tripSummaryLocation={tripSummaryLocation}
							afterPayment={afterPayment}
						/>
					</If>
				</If>
			</div>
		);
	});

	if (cartView) {
		setTransactionPriceInStorage(getCartTotal({
			bookingList: bookingsToDisplay,
			giftCertificateUsages: giftCertificateUsages,
			transactionFees,
			ccSurchargeMultiplier: 0,
			afterPayment: afterPayment,
			useRecentSummary: !afterPayment,
			bookingToEdit: bookingToEdit,
			beforeManagerOverride: false,
			managerDiscount: discountAmount,
			forEftpos,
			tripSummaryLocation,
		}));
	}

	if (listOfBookings.length === 0) {
		return (
			<div className="no-bookings-to-show">
				<h6>Nothing in the cart</h6>
			</div>
		);
	}

	const isCartView = cartView || afterPayment;
	const clazz = classNames('trip-booking-summary-card-container', {
		'cart-view': isCartView,
		'sidebar-view': !isCartView,
	});

	return (
		<div className={clazz}>
			{listOfBookings}
			<TripBookingSummaryTotal
				bookingsToDisplay={bookingsToDisplay}
				giftCertificateUsages={giftCertificateUsages}
				transactionFees={transactionFees}
				fetchTransactionFees={fetchTransactionFees}
				bookingsList={bookingsList}
				bookingToEdit={bookingToEdit}
				afterPayment={afterPayment}
				cartView={cartView}
				forEftpos={forEftpos}
				discountAmount={discountAmount}
				wizardData={wizardData}
				onClearGiftCertificate={onClearGiftCertificate}
				onClearFee={onClearFee}
				onAddManagerDiscount={onAddManagerDiscount}
				onRemoveManagerDiscount={onRemoveManagerDiscount}
				onRemoveFee={onRemoveFee}
				transactionId={transactionId}
				applyCreditCardSurcharge={applyCreditCardSurcharge}
				tripSummaryLocation={tripSummaryLocation}
			/>
		</div>
	);
}

export default observer(TripBookingSummaryCard);

import { CheckInBookingOverviewDto } from '../../FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { ScannerResultState } from './scannerResultState';

export const ScannerActionMap = {
	StartScanner: 'start-scanner-with-bookings',
	ToggleMode: 'toggle-mode',
	ScanQrCode: 'scan-qr-code',
	ScanCargoId: 'scan-cargo-id',
	ResetImage: 'reset-image',
	CleanUp: 'clean-up',
	ToggleDevMode: 'toggle-dev',
} as const;

type StartScannerEvent = { action: typeof ScannerActionMap.StartScanner; bookings: CheckInBookingOverviewDto[] };
type ToggleModeEvent = { action: typeof ScannerActionMap.ToggleMode };
type ScanQrCodeEvent = {
	action: typeof ScannerActionMap.ScanQrCode;
	canvas: HTMLCanvasElement;
	onSuccess?: (result: string) => void;
	onError?: () => void;
	abortSignal?: AbortSignal;
};
type ScanCargoIdEvent = {
	action: typeof ScannerActionMap.ScanCargoId;
	canvas: HTMLCanvasElement;
	onSuccess?: (result: ScannerResultState) => void;
	onError?: () => void;
	abortSignal?: AbortSignal;
};
type ResetImageEvent = { action: typeof ScannerActionMap.ResetImage };
type CleanUpEvent = { action: typeof ScannerActionMap.CleanUp };
type ToggleDevModeEvent = { action: typeof ScannerActionMap.ToggleDevMode };

export type ScannerActionEvent =
	| StartScannerEvent
	| ToggleModeEvent
	| ScanQrCodeEvent
	| ScanCargoIdEvent
	| ResetImageEvent
	| CleanUpEvent
	| ToggleDevModeEvent;

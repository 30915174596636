import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { BookingWizardData,	WizardErrors } from '../../BookingWizardData';
import { arePaymentDetailsValid } from './PaymentTabUtils';
import { PostPaymentRender } from '../PostPayment/PostPaymentRender';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { SkipPayment } from './SkipPayment';
import { Flex } from 'Views/Components/Flex/Flex';
import { Text } from 'Views/Components/Text/Text';
import MasterCardLogo from 'images/logo-mastercard.svg';
import VisaLogo from 'images/logo-visa.svg';
import { store } from 'Models/Store';
import {
	getEventBookingTransactionIdFromStorage,
	getFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import usePaymentCard from 'Util/_HumanWritten/Payment/UsePaymentInputs';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { getTransactionPrice } from 'Util/_HumanWritten/CartPriceSessionStorage';
import { usePaymentTab } from 'Hooks/usePaymentTab';
import {
	resetReservationTimer,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { alertModal } from '../../../../Modal/ModalUtils';
import { getPaymentModalContents, getPaymentModalTitle } from './PaymentTabModalUtils';
import {
	clearEventBookingWizard,
	clearFerryBookingWizard,
} from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { LottieSpinner } from '../../../Lottie/LottieSpinner';
import If from '../../../../If/If';
import { ErrorCard } from '../../../ErrorCard/ErrorCard';
import { TextField } from '../../../../TextBox/TextBox';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';

export const PAYMENT_FORM_ID = 'payment_form';

// We are going to need two different modals as there are two different potential failure states
// that we need to account for. Timeout will handle if we do not get a response from Stripe with the
// allotted time and error will handle all other reasons for failure. Error really shouldn't come up
// in most standard use cases but it is a potential scenario which we need to ensure we have handled
export enum PaymentError {
	TIMEOUT,
	ERROR,
	NO_PAYMENT_ERROR,
	NO_ERROR,
}

export interface PaymentDetails {
	cardholderName: string,
	cardNo: string,
	expiryDate: string,
	cvc: string,
}

export interface PaymentTabProps {
	paymentCompletedUrl: string;
	timeoutExpiryUrl: string;
	nameToPrefill: string;
	errorCode: string | null;
	onCompletePayment: () => void;
	onPageLoaded: () => void;
	bulkBooking: boolean;
	returnTrip: boolean;
	userId?: string;
	isAlteration: boolean;
	bookingToEditId?: string;
	eventBooking: boolean;
}

function PaymentTab({
	paymentCompletedUrl,
	timeoutExpiryUrl,
	nameToPrefill,
	errorCode,
	onCompletePayment,
	onPageLoaded,
	bulkBooking,
	returnTrip,
	userId,
	isAlteration,
	bookingToEditId,
	eventBooking,
}: PaymentTabProps) {
	const history = useHistory();
	const transactionId = eventBooking
		? getEventBookingTransactionIdFromStorage()
		: getFerryBookingTransactionIdFromStorage();

	const [submittedPayment, setSubmittedPayment] = useState<boolean>(false);
	const paymentInfo = useLocalStore<PaymentDetails>(() => ({
		cardholderName: nameToPrefill,
		cardNo: '',
		expiryDate: '',
		cvc: '',
	}));
	// Need to cast the hook to the correct type to get the types correct
	const { getCardNumberProps, getExpiryDateProps } = usePaymentCard();
	const paymentInfoErrors = useLocalStore<WizardErrors<PaymentDetails>>(() => ({}));
	const { isMobile, isIpad } = useDeviceDetect();
	store.setCartPriceWithSurcharge(getTransactionPrice() ?? 0);

	const {
		transactionDetails,
		accessCodeError,
	} = usePaymentTab({
		transactionId,
		paymentCompletedUrl,
		bookingForAlteration: isAlteration,
		eventBooking,
		errorCode,
		bulkBooking,
		returnTrip,
		userId,
	});

	const errorText = getErrorText(errorCode);
	useEffect(() => {
		if (errorText !== null && transactionId !== null) {
			resetReservationTimer(transactionId).catch(err => {});
		}
	}, [errorText, transactionId]);

	if (accessCodeError !== PaymentError.NO_ERROR) {
		// If a new error case is introduced in the future, it will need to be accounted for in here. If it is not then
		// the modal for that case would be empty, with just the button to return to the cart
		alertModal(
			getPaymentModalTitle(accessCodeError),
			getPaymentModalContents(accessCodeError),
			{
				cancelText: `Return to ${accessCodeError === PaymentError.NO_PAYMENT_ERROR ? 'Booking' : 'Payment'}`,
				shouldCloseOnEsc: false,
				shouldCloseOnOverlayClick: false,
			},
		).then(() => {
			let urlToNavigateTo = timeoutExpiryUrl;
			if (accessCodeError === PaymentError.NO_PAYMENT_ERROR) {
				const bookingId = bookingToEditId ?? '';
				if (!eventBooking) {
					urlToNavigateTo = bookingToEditId !== '' && isAlteration
						? `/bookings/${bookingId}`
						: '/bookings';
					clearFerryBookingWizard();
				} else {
					urlToNavigateTo = '/upcoming-events';
					clearEventBookingWizard();
				}
			}
			history.push(urlToNavigateTo);
		});

		return <></>;
	}

	if (transactionDetails == null) {
		return <LottieSpinner />;
	}

	onPageLoaded();

	const resetError = (key: keyof PaymentDetails) => {
		runInAction(() => {
			delete paymentInfoErrors[key];
		});
	};

	const onCvcChange = () => {
		const currentValue = paymentInfo.cvc;
		const maxLength = 3;
		if (currentValue.length > maxLength) {
			runInAction(() => {
				paymentInfo.cvc = currentValue.substring(0, maxLength);
			});
		}
	};

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		if (!arePaymentDetailsValid(paymentInfo, paymentInfoErrors) || submittedPayment) {
			e.preventDefault();
		} else {
			onCompletePayment();
			setSubmittedPayment(true);
		}
	};

	// The previous checks will handle the scenario before our request has completed and what happens if that request
	// fails. If we reach this return statement then that means that the
	return (
		<>
			<form
				method="POST"
				action={transactionDetails.formActionUrl}
				id={PAYMENT_FORM_ID}
				className={classNames('mb-xxxl', { 'payment-tab__completed-payment': submittedPayment })}
				onSubmit={onSubmit}
			>
				{/*
					Eway determines the fields which are sent in the form based on the names of fields. Eway says
					that we need these first two, but the second two for expiry month and year comes from us using
					a single field for the full expiry date. Eway wants individual fields for them both so these
					hidden fields will have the correct values while still allowing us to use the format from the
					designs
				*/}
				<input type="hidden" name="EWAY_ACCESSCODE" value={transactionDetails.paymentIntentId} />
				<input type="hidden" name="EWAY_PAYMENTTYPE" value="Credit Card" />
				<input type="hidden" name="EWAY_CARDNAME" value={paymentInfo.cardholderName} />
				<input
					type="hidden"
					name="EWAY_CARDNUMBER"
					value={paymentInfo.cardNo.split(' ').join('')}
				/>
				<input type="hidden" name="EWAY_CARDCVN" value={paymentInfo.cvc} />
				<input
					type="hidden"
					name="EWAY_CARDEXPIRYMONTH"
					value={paymentInfo.expiryDate.split('/')[0]?.trim() ?? ''}
				/>
				<input
					type="hidden"
					name="EWAY_CARDEXPIRYYEAR"
					value={paymentInfo.expiryDate.split('/')[1]?.trim() ?? ''}
				/>
				{/*
					The hidden inputs out here are the fields which the form will actually look at for submitting the
					form. I did it like that because I wanted to show the post payment render on this page when the
					form is submitted, and if we removed the actual form fields to do that then the form wouldn't submit
				*/}
				<If condition={submittedPayment}>
					<PostPaymentRender />
				</If>
				<If condition={!submittedPayment}>
					<div className="payment-tab__wrap">
						<Flex justify="space-between" align="center" className="booking-wizard__tab-header">
							<Text
								size="xl"
								weight="700"
								className={classNames({ 'mb-sm': isIpad })}
								lineHeight={1}
							>
								Payment details
							</Text>
							<If condition={isAlteration}>
								<Text size="xl" weight="700" className="mb-sm" lineHeight={1}>
									<Link
										to={`/bookings/${bookingToEditId ?? ''}`}
										className="cancel-alteration-cross"
									>
										<Icon name="cross" classname="icon" />
									</Link>
								</Text>
							</If>
						</Flex>
						<Flex direction="col" gap="sm" className="p-sm payment-tab__body">
							<If condition={errorText !== null}>
								<ErrorCard
									title="Transaction could not be processed."
									subtitle={`${errorText}` ?? ''}
								/>
							</If>
							<Flex direction="col" gap="xxxs">
								<Text size="xs" lineHeight={1}>Total amount</Text>
								<Text size="xl" weight="700">
									{displayAsAud(store.cartPriceWithSurcharge)}
								</Text>
							</Flex>
							<Flex direction="col" gap="xs" className="payment-tab">
								<TextField
									id="EWAY_CARDNAME"
									model={paymentInfo}
									modelProperty="cardholderName"
									label="Cardholder name"
									placeholder="Cardholder name"
									errors={paymentInfoErrors.cardholderName}
									onAfterChange={() => {
										resetError('cardholderName');
									}}
								/>
								<Flex direction="col">
									<TextField
										id="EWAY_CARDNUMBER"
										model={paymentInfo}
										modelProperty="cardNo"
										label="Card number"
										placeholder="---- ---- ---- ----"
										errors={paymentInfoErrors.cardNo}
										inputProps={{
											inputMode: 'numeric',
											// This will overwrite the onChange method on the inner text field so we
											// have to perform the model binding manually
											...getCardNumberProps({
												onChange: action((e: ChangeEvent<HTMLInputElement>) => {
													if (e.target.value.length > 16 + 3) {
														return;
													}
													paymentInfo.cardNo = e.target.value;
													resetError('cardNo');
												}),
											}),
											placeholder: '---- ---- ---- ----',
										}}
									/>
									<Flex gap="xxs">
										<img alt="mastercard-logo" src={MasterCardLogo} />
										<img alt="visa-logo" src={VisaLogo} />
									</Flex>
								</Flex>
								<Flex gap="sm">
									<TextField
										id="EWAY_EXPIRYDATE"
										className={`${isMobile ? 'mobile-payment__width' : ''}`}
										model={paymentInfo}
										modelProperty="expiryDate"
										label="Expiry date"
										placeholder="MM/YY"
										errors={paymentInfoErrors.expiryDate}
										inputProps={{
											inputMode: 'numeric',
											...getExpiryDateProps({
												onChange: action((e: ChangeEvent<HTMLInputElement>) => {
													paymentInfo.expiryDate = e.target.value;
													resetError('expiryDate');
												}),
											}),
										}}
									/>
									<If condition={!isMobile}>
										<TextField
											id="EWAY_CARDCVN"
											model={paymentInfo}
											modelProperty="cvc"
											label="CVC"
											placeholder="XXX"
											errors={paymentInfoErrors.cvc}
											onAfterChange={() => {
												onCvcChange();
												resetError('cvc');
											}}
											inputProps={{
												inputMode: 'numeric',
											}}
										/>
									</If>
								</Flex>
								<If condition={isMobile}>
									<Flex direction="col">
										<TextField
											id="EWAY_CARDCVN"
											className="mobile-payment__width"
											model={paymentInfo}
											modelProperty="cvc"
											label="CVC"
											placeholder="XXX"
											errors={paymentInfoErrors.cvc}
											onAfterChange={() => {
												onCvcChange();
												resetError('cvc');
											}}
											inputProps={{
												inputMode: 'numeric',
											}}
										/>
									</Flex>
								</If>
								<SkipPayment
									transactionId={transactionId}
									bookingForAlteration={isAlteration}
									userId={userId ?? store.userId}
									eventBooking={eventBooking}
								/>
							</Flex>
						</Flex>
					</div>
				</If>
			</form>
		</>
	);
}

export default observer(PaymentTab);

function getErrorText(errorCode: string | null): string | null {
	if (errorCode === null) {
		return null;
	}

	switch (errorCode) {
		default:
			return 'No payment has been made. Please re-enter your details and try again.';
	}
}

import useDeviceDetect from 'Hooks/useDeviceDetect';
import { useHistory } from 'react-router';
import React, { useCallback, useEffect, useState } from 'react';
import {
	EventBookingWizard,
	EventBookingWizardTab,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizard';
import {
	useEventBookingWizardWatch,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/useEventBookingWizardWatch';
import {
	EventBookingWizardData,
	saveEventBookingWizardDataToLocalStorage,
	WizardErrors,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { SidebarStatus, VisibilityStatus } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizard';
import useStore from 'Hooks/useStore';
import { EventTicketTab } from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/Tickets/EventTicketsTab';
import { EventDetailEntity } from 'Models/Entities';
import { checkIfInvalidName } from 'Util/StringUtils';
import { IsValidPhone } from 'Validators/Functions/HumanWritten/Phone';
import { isEmail } from 'Validators/Functions/Email';
import {
	EventsReservationTab,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/Reservation/EventsReservationTab';
import {
	getEventBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	canNavigateToEventsCartStep, canNavigateToEventsPaymentStep, canNavigateToEventsPostPaymentStep,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';
import { EventCartTab } from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/Cart/EventCartTab';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { PAYMENT_FORM_ID } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Payment/PaymentTab';
import { TermsAndConditions } from '../FerryTripBookingWizard/WizardSteps/TermsAndConditions/TermsAndConditions';
import { PaymentTabWrap } from '../FerryTripBookingWizard/WizardSteps/Payment/PaymentTabWrap';
import { PostPaymentTab } from '../FerryTripBookingWizard/WizardSteps/PostPayment/PostPaymentTab';
import { onWizardSuccess } from '../../../../Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { CustomLocationState } from '../../../../Models/_HumanWritten/LocationState';
import EventCustomerTab from './WizardSteps/Customer/EventCustomerTab';
import { EventDetailedView } from '../Events/EventDetailedView/EventDetailedView';

type EventBookingWizardWrapInnerProps = {
	eventDetails: EventDetailEntity;
	bookingWizardData: EventBookingWizardData;
	setBookingWizardData: React.Dispatch<React.SetStateAction<EventBookingWizardData | undefined>>;
};

export function EventBookingWizardWrapInner({
	eventDetails,
	bookingWizardData,
	setBookingWizardData,
}: EventBookingWizardWrapInnerProps) {
	const baseUrl = '/event-booking-wizard';
	const { isIpad, isMobile } = useDeviceDetect();
	const history = useHistory();
	const store = useStore();
	const { isStaff } = store;

	const [
		bookingWizardErrors,
		setBookingWizardErrors,
	] = useState<WizardErrors<EventBookingWizardData>>({} as WizardErrors<EventBookingWizardData>);
	const [bookingCreationFailed, setBookingCreationFailed] = useState<boolean>(false);
	// by storing this in a state variable, it will not be updated in the future when the user clicks the button to
	// accept the t and cs
	/**
	 * True when user has entered their payment details and pressed 'Pay now'.
	 */
	const [paymentDetailsEntered, setPaymentDetailsEntered] = useState<boolean>(false);
	const [pageLoaded, setPageLoaded] = useState<boolean>(false);
	const [acceptedTandCsStatic] = useState(bookingWizardData.acceptedTsAndCs);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [disableContinueButton, setDisableContinueButton] = useState<boolean>(false);
	const [tabIsLoading, setTabIsLoading] = useState<boolean>(false);

	useEventBookingWizardWatch({ bookingWizardData, paymentDetailsEntered });

	const onUpdateWizardData = (wizardData: EventBookingWizardData) => {
		saveEventBookingWizardDataToLocalStorage(wizardData);
		setBookingWizardData(wizardData);
	};

	const updateDataCallback = useCallback((wizardData: EventBookingWizardData) => {
		onUpdateWizardData(wizardData);
	}, []);

	const tabs: EventBookingWizardTab[] = [
		{
			name: 'Details',
			displayName: 'Details',
			path: 'details',
			tabTitle: 'Details',
			visibility: VisibilityStatus.VISIBLE,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: () => true,
			component: <EventDetailedView eventId={eventDetails.id} showButtons={false} />,
			authorize: false,
			showTAndCsButton: false,
		},
		{
			name: 'Customer',
			displayName: 'Customer',
			path: 'customer',
			tabTitle: 'Customer details',
			visibility: !isStaff
				? VisibilityStatus.EXCLUDED
				: VisibilityStatus.VISIBLE,
			sidebarStatus: SidebarStatus.CART_SUMMARY,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<EventBookingWizardData> = {};
				if (canNavigateToEventsCartStep(history) || canNavigateToEventsPaymentStep(history)) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
					return true;
				}

				if (bookingWizardData.userId === '') {
					newErrors.userId = 'A customer must be selected';
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}
				return Object.keys(newErrors).length === 0;
			},
			component: (
				<EventCustomerTab
					wizardData={bookingWizardData}
					onUpdateData={updateDataCallback}
					errors={bookingWizardErrors}
					locationState={history.location.state as CustomLocationState}
					onUpdateErrors={setBookingWizardErrors}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
		},
		{
			name: 'Tickets',
			displayName: 'Tickets',
			path: 'tickets',
			tabTitle: 'Tickets',
			visibility: VisibilityStatus.VISIBLE,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<EventBookingWizardData> = {};

				if (
					canNavigateToEventsCartStep(history)
					|| canNavigateToEventsPaymentStep(history)
					|| canNavigateToEventsPostPaymentStep(history)
				) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
					return true;
				}

				// Check if at least one passenger ticket has been selected
				if (bookingWizardData.selectedTickets.length === 0) {
					newErrors.selectedTickets = 'Minimum of one ticket required.';
				}

				// Check if valid first name
				if (bookingWizardData.firstName === '') {
					newErrors.firstName = 'Required field';
				} else if (checkIfInvalidName(bookingWizardData.firstName)) {
					newErrors.firstName = 'No spaces. A-Z and \'-\' only.';
				}

				// Check if valid last name
				if (bookingWizardData.lastName === '') {
					newErrors.lastName = 'Required field';
				} else if (checkIfInvalidName(bookingWizardData.lastName)) {
					newErrors.lastName = 'No spaces. A-Z and \'-\' only.';
				}

				// Check if valid phone number
				if (bookingWizardData.phone === '') {
					newErrors.phone = 'Required field';
				} else if (!IsValidPhone(bookingWizardData.phone)) {
					newErrors.phone = 'Invalid phone number';
				}

				// Check if valid email address
				if (bookingWizardData.email === '') {
					newErrors.email = 'Required field';
				} else if (!isEmail(bookingWizardData.email)) {
					newErrors.email = 'Invalid email address';
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}
				return Object.keys(newErrors).length === 0;
			},
			component: <EventTicketTab
				wizardData={bookingWizardData}
				onUpdateWizardData={updateDataCallback}
				eventDetails={eventDetails}
				errors={bookingWizardErrors}
			/>,
			authorize: false,
			showTAndCsButton: false,
		},
		{
			name: 'Reservation',
			displayName: 'Reservation',
			path: 'reservation',
			// this hidden step will be between Tickets and Cart. We don't tell the user that this step exists so we
			// don't want a document title for it. Instead we will keep the same title from the previous step as it will
			// appear to the user that they are on the same page and waiting to progress to the cart.
			tabTitle: 'Tickets',
			visibility: VisibilityStatus.HIDDEN,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<EventBookingWizardData> = {};

				if (canNavigateToEventsCartStep(history) || canNavigateToEventsPaymentStep(history)) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
						return true;
					}
				}

				// To get to cart step, there must exist a transactionId. If not, it means this reservation step has
				// been skipped. Returning false will then allow this step to be executed.
				return !bookingCreationFailed && getEventBookingTransactionIdFromStorage() !== null;
			},
			component: (
				<EventsReservationTab
					wizardData={bookingWizardData}
					onUpdateWizardData={updateDataCallback}
					navigateToNextStep={() => {
						//
						// Important to use history.replace so that when the user clicks back on the browser, they will
						// be navigated to previous visible tab.
						//
						history.replace(`${baseUrl}/cart`);
					}}
					navigateToPreviousStep={() => {
						// Set our previous step url like this because regardless of whether the user is staff, we will
						// navigate to vehicle tab if the booking is a vehicle booking. That allows us to safely
						// override whatever we set first
						const previousStepUrl = isStaff ? 'tickets' : 'tickets';
						history.push(`${baseUrl}/${previousStepUrl}`);
					}}
					navigateToTickets={() => {
						history.push(`${baseUrl}/tickets`);
					}}
					onCompleteBookingAttempt={setBookingCreationFailed}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
		},
		{
			name: 'Cart',
			displayName: 'Cart',
			path: 'cart',
			tabTitle: 'Cart',
			visibility: VisibilityStatus.VISIBLE,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};

				if (canNavigateToEventsCartStep(history) || canNavigateToEventsPaymentStep(history)) {
					if (updateErrorState) {
						setBookingWizardErrors(newErrors);
					}
				}

				// If the user clicks through too fast then there can be problems related to the transaction id not
				// being present when the user tries to get the ts and cs. To solve this we can ensure that the user
				// can't progress unless the transaction id is set. This will only happen if the user is clicking
				// through the site really fast
				return getEventBookingTransactionIdFromStorage() !== null;
			},
			component: <EventCartTab
				eventDetails={eventDetails}
				wizardData={bookingWizardData}
				onUpdateWizardData={updateDataCallback}
				setRefresh={setRefresh}
				refresh={refresh}
			/>,
			authorize: true,
			className: 'cart-step',
			showTAndCsButton: false,
		},
		{
			name: 'Ts-and-Cs',
			displayName: 'Ts-and-Cs',
			path: 't-and-cs',
			tabTitle: 'Terms and Conditions',
			visibility: VisibilityStatus.HIDDEN,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				const newErrors: WizardErrors<BookingWizardData> = {};

				if (!bookingWizardData.acceptedTsAndCs) {
					newErrors.acceptedTsAndCs = 'Must accept terms and conditions to continue';
				}

				if (updateErrorState) {
					setBookingWizardErrors(newErrors);
				}
				return Object.keys(newErrors).length === 0;
			},
			component: (
				<TermsAndConditions
					acceptedTsandCs={acceptedTandCsStatic}
					navigateToNextStep={() => {
						history.push(`${baseUrl}/payment`);
					}}
					isMobile={isMobile}
					termsAndConditionsType="event"
					isAlteration={false}
					bookingToEditId={undefined}
					eventDetails={eventDetails}
				/>
			),
			authorize: true,
			className: 'cart-step',
			showTAndCsButton: true,
		},
		{
			name: 'Payment',
			displayName: 'Payment',
			path: 'payment',
			tabTitle: 'Payment',
			className: 'payment-step',
			visibility: VisibilityStatus.VISIBLE,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				if (updateErrorState) {
					setBookingWizardErrors({});
				}
				return true;
			},
			component: (
				<PaymentTabWrap
					timeoutExpiryUrl={`${baseUrl}/cart`}
					paymentCompletedUrl={`${baseUrl}/post-payment`}
					nameToPrefill={store.userData !== undefined && !isStaff
						? `${store.userData.firstName} ${store.userData.lastName}`
						: ''}
					onCompletePayment={() => {
						setPaymentDetailsEntered(true);
					}}
					onPageLoaded={() => {
						setPageLoaded(true);
					}}
					bulkBooking={false}
					returnTrip={false}
					userId={bookingWizardData.userId}
					isAlteration={false}
					bookingToEditId={bookingWizardData.bookingToEditId}
					eventBooking
					setTabIsLoading={setTabIsLoading}
					setDisableContinueButton={setDisableContinueButton}
					disableContinueButton={disableContinueButton}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
			//
			// When on the PaymentTab, we want to use the continue button to trigger the
			// payment form. We do this by setting the formId of the button and set type
			// to 'submit'.
			//
			continueBtnProps: {
				form: PAYMENT_FORM_ID,
				type: 'submit',
			},
			continueBtnText: 'Pay now',
			continueBtnLoadingText: 'Processing...',
			hideBottomBar: !pageLoaded,
		},
		{
			name: 'Post Payment',
			displayName: 'Post Payment',
			path: 'post-payment',
			tabTitle: 'Payment',
			visibility: VisibilityStatus.HIDDEN,
			sidebarStatus: SidebarStatus.NO_SIDEBAR,
			validateStep: updateErrorState => {
				if (updateErrorState) {
					setBookingWizardErrors({});
				}
				return true;
			},
			component: (
				<PostPaymentTab
					onSuccess={(transactionId: string) => {
						onWizardSuccess(
							store,
							transactionId,
							false,
							false,
							true,
							bookingWizardData.userId,
						);
					}}
					onPaymentFailed={errorCode => {
						history.push(`${baseUrl}/payment?ErrorCode=${errorCode}`);
					}}
				/>
			),
			authorize: true,
			showTAndCsButton: false,
			contentClass: 'post-payment-step-content',
			hideBottomBar: true,
		},
	];

	return (
		<EventBookingWizard
			wizardData={bookingWizardData}
			onUpdateWizardData={updateDataCallback}
			tabs={tabs}
			baseUrl={baseUrl}
			isIpad={isIpad}
			isMobile={isMobile}
			disableBackAndContinueButtons={paymentDetailsEntered}
			setDisableContinueButton={setDisableContinueButton}
			disableContinueButton={disableContinueButton}
			tabIsLoading={tabIsLoading}
		/>
	);
}

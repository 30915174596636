import React from 'react';
import * as uuid from 'uuid';
import { wizardModeOptions } from 'Models/Enums';
import { tripSummaryLocationType } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { getPriceOfOption } from 'Util/_HumanWritten/AdditionalBookingOptionsUtils';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { getMeasurementLabelFromId, getMinimumMeasurementTypeForFerryTrip } from 'Util/_HumanWritten/MeasurementUtils';
import { TripBreakdownDescription } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBreakdownDescription';
import If from 'Views/Components/If/If';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import {
	filterReservedAndBookedAlterations,
	sortAlterationsByDateCreated,
} from 'Util/_HumanWritten/AlterationSortingUtils';
import { BookingEntity } from 'Models/Entities';
import {
	isNotNullOrUndefined,
	isNullOrUndefined,
	stringIsEmpty,
	stringNotEmpty,
} from 'Util/TypeGuards';
import {
	AdditionalOption,
	BookingWizardCartFields, BookingWizardData,
	getOldFerryBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	getBaseFerryTicketPriceForFerryTrip,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetBaseFerryTicketPriceForFerryTrip';
import {
	getOneWayVehicleLengthPriceDifference,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetOneWayVehicleLengthPriceDifference';
import {
	getOneWayVehicleWeightPriceDifference,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetOneWayVehicleWeightPriceDifference';
import {
	getOneWayTrailerLengthPriceDifference,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetOneWayTrailerLengthPriceDifference';
import {
	getTotalOneWayTicketPrice,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetTotalOneWayTicketPrice';

export interface TripBreakdownProps {
	cartData: BookingWizardCartFields;
	wizardData?: BookingWizardData;
	departureTrip: boolean;
	bookingToEdit: BookingEntity | null;
	tripSummaryLocation: tripSummaryLocationType;
	afterPayment: boolean;
}

export function TripBreakdown({
	cartData,
	departureTrip,
	bookingToEdit = null,
	tripSummaryLocation,
	afterPayment,
}: TripBreakdownProps) {
	const oldWizardData = getOldFerryBookingWizardData();
	const trip = departureTrip
		? cartData.selectedTrips.departingTrip
		: cartData.selectedTrips.returningTrip;
	// Need to use old trailer length if the user has removed the trailer from the alteration
	// eslint-disable-next-line no-nested-ternary
	let trailerLengthIdToUse = null;
	if (cartData.wizardData.towOnTypeId !== 'NO_TRAILER' && cartData?.wizardData.wizardMode === 'CREATE') {
		trailerLengthIdToUse = cartData.wizardData.trailerLengthId;
	} else if (cartData?.wizardData.wizardMode === 'ALTERATION') {
		// eslint-disable-next-line no-nested-ternary
		trailerLengthIdToUse = cartData.wizardData.trailerLengthId !== ''
			? cartData.wizardData.trailerLengthId
			: (oldWizardData?.trailerLengthId !== '' ? oldWizardData?.trailerLengthId : null);
	}
	if ((tripSummaryLocation === 'booking-success' || tripSummaryLocation === 'check-in')
		&& isNullOrUndefined(cartData.wizardData.trailerLengthId)) {
		let sortedBookingAlterations = [];
		if (isNotNullOrUndefined(bookingToEdit)) {
			sortedBookingAlterations = filterReservedAndBookedAlterations(
				sortAlterationsByDateCreated(bookingToEdit.alterations),
			);
			trailerLengthIdToUse = sortedBookingAlterations[1]?.getTowOn()?.selectedLengthId ?? null;
		}
	}

	const additionalBookingOptions = departureTrip
		? cartData.wizardData.departingTripOptions
		: cartData.wizardData.returningTripOptions;
	const summaries = departureTrip ? bookingToEdit?.bookingSummaries : bookingToEdit?.returnBooking?.bookingSummaries;
	const summary = (tripSummaryLocation === 'booking-success'
		|| tripSummaryLocation === 'check-in') && !!summaries?.previousSummary
		? summaries.previousSummary
		: summaries?.recentSummary;
	const oldAdditionalBookingOptions = summary?.additionalBookingOptions
		.map(item => {
			return {
				optionId: item.option?.id ?? '',
				amount: item.quantity ?? 0,
				optionPrice: item.option?.staticPrice ?? 0,
				optionName: item.option?.name ?? '',
				gstExempt: item.option?.excludeGST,
			};
		}).filter(x => x.amount !== 0) as AdditionalOption[] ?? [];

	const forEftpos = tripSummaryLocation === 'check-in';

	if (isNullOrUndefined(trip) || stringIsEmpty(trip.id)) {
		return <LottieSpinner />;
	}

	return (
		<div key={uuid.v4()} className={`${departureTrip ? 'departing' : 'return'} trip-card`}>
			<p className="route-name">
				{trip?.getFormattedTripRoute()}
			</p>
			<div className="trip-details-overview">
				<div className="trip-details-breakdown">
					<p>
						{trip?.getTripTimeFormatted()}
					</p>
					<p>
						{trip?.getFormattedTripDateLong(true)}
					</p>
					<p className="booked-trip-passenger-details">
						<TripBreakdownDescription wizardData={cartData.wizardData} />
					</p>
				</div>
				<p className="base-total-cost">
					{formatPriceDisplay(
						getBaseFerryTicketPriceForFerryTrip(
							departureTrip,
							cartData.wizardData,
							trip,
							afterPayment,
							bookingToEdit,
							forEftpos,
							true,
						),
						cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION,
					)}
				</p>
			</div>
			<div className="additional-items">
				{cartData.wizardData.vehicleLengthId !== ''
					&& (
						<>
							<div className="additional-item-section vehicle-length">
								<p className="vehicle-length-extra-cost-label">
									{whiteLabelStore.config.vehicleLengthEnabled && (
										`Additional ${whiteLabelStore.config.vehicleLabel.toLowerCase()} size (${
											getMeasurementLabelFromId(cartData.wizardData.vehicleLengthId)
										})`
									)}
									{!whiteLabelStore.config.vehicleLengthEnabled && (
										`Additional ${whiteLabelStore.config.vehicleLabel.toLowerCase()} size`
									)}
								</p>
								<p className="vehicle-length-extra-cost-price">
									{formatPriceDisplay(getOneWayVehicleLengthPriceDifference(
										departureTrip,
										cartData.wizardData,
										trip,
										bookingToEdit,
										undefined,
										forEftpos,
									), cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION)}
								</p>
							</div>
							<If condition={getMinimumMeasurementTypeForFerryTrip(
								trip,
								'WEIGHT',
							).id !== cartData?.wizardData?.vehicleWeightId}
							>
								<div className="additional-item-section vehicle-weight">
									<p className="vehicle-length-extra-cost-label">
										{whiteLabelStore.config.vehicleLengthEnabled && (
											`Additional ${whiteLabelStore.config.vehicleLabel.toLowerCase()} weight (${
												getMeasurementLabelFromId(cartData.wizardData.vehicleWeightId)
											})`
										)}
										{!whiteLabelStore.config.vehicleLengthEnabled && (
											`Additional ${whiteLabelStore.config.vehicleLabel.toLowerCase()} weight`
										)}
									</p>
									<p className="extra-cost-price">
										{formatPriceDisplay(getOneWayVehicleWeightPriceDifference(
											departureTrip,
											cartData.wizardData,
											trip,
											bookingToEdit,
										), cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION)}
									</p>
								</div>
							</If>
						</>
					)}
				{(stringNotEmpty(trailerLengthIdToUse) && (cartData.wizardData.towOnTypeId !== 'NO_TRAILER'
						|| (cartData.wizardData?.wizardMode === 'ALTERATION'
							&& oldWizardData?.towOnTypeId !== 'NO_TRAILER')
						|| (isNotNullOrUndefined(trailerLengthIdToUse) && (tripSummaryLocation === 'booking-success'
							|| tripSummaryLocation === 'check-in'))))
					&& (
						<div className="additional-item-section trailer">
							<p className="extra-cost-label">
								{`Additional trailer (${getMeasurementLabelFromId(trailerLengthIdToUse)})`}
							</p>
							<p className="extra-cost-price">
								{formatPriceDisplay(getOneWayTrailerLengthPriceDifference(
									departureTrip,
									cartData.wizardData,
									trip,
									bookingToEdit,
									undefined,
									forEftpos,
								), cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION)}
							</p>
						</div>
					)}
				{additionalBookingOptions.length > 0 && additionalBookingOptions.map(additionalOption => {
					// If it doesn't exist find will give us undefined, we want null because that is what
					// getPriceOfOption is expecting in this scenario
					const associatedOldOption = oldAdditionalBookingOptions
						.find(x => x.optionId === additionalOption.optionId)
						?? null;

					// as we filter out old options with an amount of 0, this will remove any that don't exist
					// or had a previous value of 0. We can safely remove options with a previous value of 0 because
					// they don't impact the price at all
					if (associatedOldOption === null && additionalOption.amount === 0) {
						return <></>;
					}

					const additionalOptionPrice = getPriceOfOption(
						additionalOption,
						associatedOldOption,
						forEftpos,
					);

					return (
						<div key={additionalOption.optionId} className="additional-item-section additional-option">
							<p className="extra-cost-label">
								{`${additionalOption.amount}x ${additionalOption.optionName}`}
							</p>
							<p className="extra-cost-price">
								{formatPriceDisplay(
									additionalOptionPrice,
									cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION,
								)}
							</p>
						</div>
					);
				})}
			</div>
			<div className="direction-total-cost">
				<p>{departureTrip ? 'Departing total' : 'Returning total'}</p>
				<p>{formatPriceDisplay(getTotalOneWayTicketPrice(
					departureTrip,
					cartData.wizardData,
					trip,
					oldAdditionalBookingOptions ?? null,
					afterPayment,
					bookingToEdit,
					forEftpos,
					!cartData?.wizardData?.priceIncreasesRemoved,
				), cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION)}
				</p>
			</div>
		</div>
	);
}

import React from 'react';
import Axios from 'axios';
import useAsync from 'Hooks/useAsync';
import { fetchPreviousVehicles } from 'Util/_HumanWritten/VehicleMakeAndModelUtils';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { SERVER_URL } from 'Constants';
import alertToast from 'Util/ToastifyUtils';
import { Button, Colors } from 'Views/Components/Button/Button';
import { CargoEntity } from 'Models/Entities';
import { observer } from 'mobx-react';
import { whiteLabelStore } from 'Models/WhiteLabelStore';

export interface LatestVehiclesProps {
	userId: string;
	refresh?: () => void;
	onSelect?: (cargoInfo?: CargoEntity) => void;
	update?: (vehicles: CargoEntity[]) => void;
	previousVehicleList?: CargoEntity[];
}

/**
 * This component will display a user's top 3 most recent vehicles used (unique by rego).
 *
 * The list is will asynchronously fetch the vehicles to be displayed.
 *
 * A loading lottie is displayed while loading. Returns null if error occurs or no vehicles to display.
 */
function LatestVehiclesList({
	userId,
	refresh,
	onSelect,
	update,
	previousVehicleList,
}: LatestVehiclesProps) {
	const [vehicles, setVehicles] = React.useState<CargoEntity[]>(previousVehicleList ?? []);
	const response = useAsync(() => {
		if (previousVehicleList !== undefined) {
			return new Promise<CargoEntity[]>(resolve => resolve([]));
		}
		return fetchPreviousVehicles(userId);
	}, [userId]);
	const setParentVehicles = (x: CargoEntity[]) => {
		if (update) {
			update(x);
		}
	};

	React.useEffect(() => {
		if (!response.data || previousVehicleList !== undefined) {
			if (previousVehicleList !== undefined) {
				setParentVehicles(previousVehicleList);
			}

			return;
		}
		setVehicles(response.data);
		setParentVehicles(response.data);
	}, [response.data]);

	if (response.type === 'loading') {
		return <LottieSpinner />;
	}

	if (response.type === 'error') {
		return null;
	}

	if (vehicles.length === 0) {
		return null;
	}

	const onRemove = async (vehicleId?: string | null) => {
		if (vehicleId !== undefined && vehicleId !== null) {
			const result = await Axios.post(
				`${SERVER_URL}/api/entity/CargoEntity/disable/${vehicleId}`,
			);
			if (result.status !== 200) {
				return alertToast(
					'There was an error removing this vehicle.',
					'error',
					'Failed to delete vehicle',
				);
			}

			// Remove vehicle from list
			setVehicles(currentVehicles => {
				const newVehicles = currentVehicles.filter(x => x.id !== vehicleId);
				setParentVehicles(newVehicles);
				return newVehicles;
			});
			if (refresh) {
				refresh();
			}
			return alertToast(
				'The vehicle has been removed from your account.',
				'success',
				'Vehicle deleted',
			);
		}
	};

	return (
		<div className="previous-vehicle-section">
			<h6 className="previous-vehicle-header">
				<span>
					Or select a previous {whiteLabelStore.config.vehicleLabel.toLowerCase()}
				</span>
			</h6>
			<div className="bottom-border" />
			{vehicles.map(x => {
				return (
					<div className="previous-vehicle-option" key={x.id}>
						<div className="previous-vehicle-info-section">
							<p className="previous-vehicle-type">
								{x.cargoType.getFormattedVehicleMakeModel()}
							</p>
							<p className="previous-vehicle-rego">
								{whiteLabelStore.config.driverDetailsRegoLabel}: {x.cargoIdentification}
							</p>
						</div>
						<div className="previous-vehicle-actions">
							<Button
								className="previous-vehicle-delete-button btn--text"
								colors={Colors.Secondary}
								onClick={() => {
									if (onSelect) {
										onSelect(x);
									}
								}}
							>
								Select
							</Button>
							<Button
								className="previous-vehicle-delete-button btn--text"
								colors={Colors.Red}
								onClick={() => onRemove(x.id)}
							>
								Remove
							</Button>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default observer(LatestVehiclesList);

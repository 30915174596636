import React from 'react';
import { store } from 'Models/Store';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from 'Models/Entities';
import { observer } from 'mobx-react';
import { getCartTotal } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartTotal';
import {
	getGST,
	getGstExemptPrice,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetGST';
import {
	getCreditCardSurcharge,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCreditCardSurcharge';
import { BookingWizardCartFields } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import {
	CartCalculationLineItem,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/CartCalculationLineItem';

export interface GstLineProps {
	subtotalBookingCost: number;
	bookingsToDisplay: BookingWizardCartFields[];
	bookingsList: BookingEntity[];
	giftCertificateUsages: GiftCertificateUsageEntity[];
	transactionFees: FeeEntity[];
	bookingToEdit: BookingEntity | null;
	discountAmount: number;
	afterPayment: boolean;
	forEftpos: boolean;
	includeCreditCardFee?: boolean;
	tripSummaryLocation: tripSummaryLocationType;
}

function GstLine({
	subtotalBookingCost,
	bookingsToDisplay,
	bookingsList,
	giftCertificateUsages,
	transactionFees,
	bookingToEdit,
	discountAmount,
	afterPayment,
	forEftpos,
	includeCreditCardFee,
	tripSummaryLocation,
}: GstLineProps) {
	const gstExemptAmount: number = getGstExemptPrice(bookingsToDisplay);

	let bookingCost = subtotalBookingCost;

	// Add the transaction fees to the booking subtotal to find GST
	transactionFees.forEach(x => {
		bookingCost += x.amount;
	});

	if (includeCreditCardFee) {
		bookingCost += getCreditCardSurcharge(bookingsList[0]);
	}

	const gstPrice = getGST(bookingCost, gstExemptAmount, store.gstMultiplier);

	return (
		<CartCalculationLineItem
			keyToUse="gst-line-item"
			className="gst-line-item"
			lineItemDisplayName="GST:"
			lineItemPrice={formatPriceDisplay(
				gstPrice,
				// We don't want to show a sign here. the alteration prop determines whether we
				// show a sign so setting it to false regardless will hide the sign
				false,
				true,
			)}
		/>
	);
}

export default observer(GstLine);

import { DisplayType } from '../../../../../Models/Enums';
import { store } from '../../../../../../../Models/Store';
import { DatePicker } from '../../../../../DatePicker/DatePicker';
import * as React from 'react';
import { TripDetailsModel } from './TripDetails';
import CalendarDisabledSvg from '../../../../../../../images/calender-disabled.svg';
import CalendarSvg from '../../../../../../../images/calender.svg';
import moment from 'moment/moment';
import { isNotNullOrUndefined } from '../../../../../../../Util/TypeGuards';
import LineDisabledSvg from '../../../../../../../images/Line 313-disabled.svg';
import LineSvg from '../../../../../../../images/Line 313.svg';

export interface DateRangePickerProps {
	model: TripDetailsModel;
	onDatesChanged: (startDate: Date, departureDate: boolean, updateOtherDate: boolean) => void;
	changeBothDates: boolean;
	departureTrip: boolean | undefined;
	className?: string;
}

export function DateRangePicker({
	model,
	onDatesChanged,
	changeBothDates,
	departureTrip,
	className,
}: DateRangePickerProps) {
	return (
		<div className="date-range-picker__container">
			<DatePicker
				model={model}
				modelProperty="startDate"
				mode="single"
				className="date-picker__general trip-details__date-picker"
				displayType={DisplayType.BLOCK}
				minDate="today"
				flatpickrOptions={{
					position: 'auto',
					minDate: store.isStaff ? '' : 'today',
					monthSelectorType: 'static',
					allowInput: false,
					locale: {
						firstDayOfWeek: 1,
						weekdays: {
							shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
							longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
						},
					},
					disableMobile: true,
				}}
				flatpickrProps={{
					value: model.startDate,
					onChange: (dates, currentDateString, self, data) => {
						const [date] = dates;
						const newDate = new Date(date);
						const endDate = new Date(model.startDate);

						onDatesChanged(date, true, (endDate < newDate && changeBothDates));
					},
					render: (props, ref) => {
						return (
							<button
								className="unstyled-button trip-details-calendar-row"
								ref={ref as any}
							>
								<CustomDateRangeFilter
									model={model}
									disabled={departureTrip === false}
									departureDate
								/>
							</button>
						);
					},
				}}
			/>
			<DatePicker
				model={model}
				modelProperty="endDate"
				mode="single"
				className="date-picker__general trip-details__date-picker"
				displayType={DisplayType.BLOCK}
				minDate="today"
				flatpickrOptions={{
					position: 'auto',
					minDate: store.isStaff ? '' : 'today',
					monthSelectorType: 'static',
					allowInput: false,
					locale: {
						firstDayOfWeek: 1,
						weekdays: {
							shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
							longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
						},
					},
					disableMobile: true,
				}}
				flatpickrProps={{
					value: model.endDate,
					onChange: (dates, currentDateString, self, data) => {
						const [date] = dates;
						const newDate = new Date(date);
						const startDate = new Date(model.startDate);

						onDatesChanged(date, false, (newDate < startDate && changeBothDates));
					},
					render: (props, ref) => {
						return (
							<button
								className="unstyled-button trip-details-calendar-row"
								ref={ref as any}
								disabled={departureTrip === true || model.tripTypeTabSelected === 'one way'}
							>
								<CustomDateRangeFilter
									model={model}
									disabled={departureTrip === true || model.tripTypeTabSelected === 'one way'}
									departureDate={false}
								/>
							</button>
						);
					},
				}}
			/>
		</div>
	);
}

interface ICustomDateRangeFilterProps {
	model: TripDetailsModel;
	disabled: boolean;
	departureDate: boolean;
}

function CustomDateRangeFilter({ model, disabled, departureDate }: ICustomDateRangeFilterProps) {
	const classPrefix = departureDate ? 'departure' : 'return';

	return (
		<div className="custom-date-selector__container show">
			<p className="date-range-input__label">
				{departureDate ? 'Depart' : 'Return'}
			</p>

			<div className="date-range-input__input">
				<img
					className="date-calendar-icon"
					src={disabled ? CalendarDisabledSvg : CalendarSvg}
					alt={`${classPrefix}-calendar-icon`}
				/>
				<p className={`date__label ${disabled ? 'disabled' : ''}`}>
					{moment(departureDate ? model.startDate : model.endDate)
						.format('DD MMM YYYY')}
				</p>
			</div>
		</div>
	);

	// return (
	// 	<div className="trip-details__custom-date-picker">
	// 		<p className={`date-range-input__label ${disabled ? 'disabled' : ''}`}>
	// 			{departureDate ? 'Depart' : 'Return'}
	// 		</p>
	//
	// 		<div className={`date-range-input__input ${disabled ? 'disabled' : ''}`}>
	// 			<img
	// 				className="date-calendar-icon"
	// 				src={disabled ? CalendarDisabledSvg : CalendarSvg}
	// 				alt={`${classPrefix}-calendar-icon`}
	// 			/>
	// 			<p>
	// 				{moment(departureDate ? model.startDate : model.endDate)
	// 					.format('DD MMM YYYY')}
	// 			</p>
	// 		</div>
	// 	</div>
	// );
}

import React from 'react';
import { observer } from 'mobx-react';
import { wizardModeOptions } from 'Models/Enums';
import { store } from 'Models/Store';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { CartCalculationLineItem } from './CartCalculationLineItem';
import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from 'Models/Entities';
import { getCartTotal } from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCartTotal';
import {
	calculateCreditCardSurcharge,
	getCreditCardSurcharge,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetCreditCardSurcharge';
import { BookingWizardCartFields, BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { tripSummaryLocationType } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { isNullOrUndefined } from 'Util/TypeGuards';

export interface CreditCardFeeLineProps {
	bookingsToDisplay: BookingWizardCartFields[];
	giftCertificateUsages: GiftCertificateUsageEntity[];
	transactionFees: FeeEntity[];
	bookingToEdit: BookingEntity | null;
	bookingsList: BookingEntity[];
	discountAmount: number;
	afterPayment: boolean;
	forEftpos: boolean;
	wizardData?: BookingWizardData;
	tripSummaryLocation: tripSummaryLocationType;
}

function CreditCardFeeLine({
	bookingsToDisplay,
	bookingsList,
	giftCertificateUsages,
	transactionFees,
	bookingToEdit,
	discountAmount,
	afterPayment,
	forEftpos,
	wizardData,
	tripSummaryLocation,
}: CreditCardFeeLineProps) {
	let ccPrice = 0;

	if (tripSummaryLocation === 'check-in') {
		const cartTotal = getCartTotal({
			bookingList: bookingsToDisplay,
			giftCertificateUsages,
			transactionFees,
			ccSurchargeMultiplier: store.ccSurchargeMultiplier,
			afterPayment,
			useRecentSummary: !afterPayment,
			bookingToEdit,
			beforeManagerOverride: false,
			managerDiscount: discountAmount,
			forEftpos,
			tripSummaryLocation,
		});

		if (cartTotal > 0) {
			const result = getCartTotal({
				bookingList: bookingsToDisplay,
				giftCertificateUsages,
				transactionFees,
				ccSurchargeMultiplier: store.ccSurchargeMultiplier,
				afterPayment,
				useRecentSummary: !afterPayment,
				bookingToEdit,
				// difference from cartTotal and this calc is beforeManagerOverride
				beforeManagerOverride: true,
				managerDiscount: discountAmount,
				forEftpos,
				tripSummaryLocation,
			});
			ccPrice = calculateCreditCardSurcharge(result, store.ccSurchargeMultiplier);
		}
	} else {
		ccPrice = getCreditCardSurcharge(bookingsList[0]);
	}

	if (isNullOrUndefined(ccPrice) || ccPrice <= 0) {
		return <></>;
	}

	let isAlteration = false;

	if (bookingsToDisplay[0]?.wizardData?.wizardMode === wizardModeOptions.ALTERATION) {
		isAlteration = true;
	} else if (wizardData?.wizardMode === wizardModeOptions.ALTERATION) {
		isAlteration = true;
	}

	const displayPrice = formatPriceDisplay(ccPrice, isAlteration, true, true);

	return (
		<CartCalculationLineItem
			keyToUse="credit-card-surcharge-line-item"
			className="credit-card-surcharge-line-item"
			lineItemDisplayName="Credit Card Surcharge:"
			lineItemPrice={displayPrice}
		/>
	);
}

export default observer(CreditCardFeeLine);

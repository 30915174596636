import axios from 'axios';
import useAsync from 'Hooks/useAsync';
import { buildUrl } from 'Util/FetchUtils';
import { TRANSACTION_ENTITY_URL } from 'Constants';

export interface PaymentTabPricesDto {
	totalPrice: number,
	creditCardSurcharge: number,
}
export function hasCreditCardFee(transactionId?: string) {
	if (!transactionId) {
		return;
	}
	const url = buildUrl(`${TRANSACTION_ENTITY_URL}/has-credit-card-fee`, { id: transactionId });
	const result = axios.get(url).then(x => x.data);
	return result;
}

export function useHasCreditCardFeeAsync(transactionId?: string) {
	return useAsync<boolean>(async () => hasCreditCardFee(transactionId), [transactionId]);
}

export async function getPaymentTabTotalAmount(isEventBooking: boolean, transactionId?: string):
	Promise<PaymentTabPricesDto | null> {
	if (transactionId == null) {
		return null;
	}

	const url = buildUrl(`${TRANSACTION_ENTITY_URL}/total-price`, {
		id: transactionId,
		isEventBooking: isEventBooking ? 'true' : 'false',
	});

	const result = await axios.get(url);
	return result.data as PaymentTabPricesDto;
}

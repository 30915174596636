import React from 'react';

interface CameraFrameProps {
	width: number;
	height: number;
	topOffset: number;
}

/**
 * Adds a white border around the given rectangular dimensions and
 * overlays a dark transparent background outside of that border.
 */
export default function CameraFrame({ width, height, topOffset }: CameraFrameProps) {
	return (
		<div
			className="camera-frame"
			style={{
				height: `${height}rem`,
				width: `${width}rem`,
				top: `${topOffset}rem`,
			}}
		>
			<div className="corner-tl" />
			<div className="corner-tr" />
			<div className="corner-bl" />
			<div className="corner-br" />
		</div>
	);
}
